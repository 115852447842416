import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ btn_centered: _ctx.centered, btn_outlined: _ctx.outlined })
  }, [
    _createVNode(_component_q_btn, _mergeProps({
      label: _ctx.text,
      style: _ctx.style,
      class: ['btn', _ctx.classNames, _ctx.outlined ? 'btn_outlined' : undefined],
      href: _ctx.href,
      target: _ctx.target
    }, _ctx.$attrs, { onClick: _ctx.handleClick }), null, 16, ["label", "style", "class", "href", "target", "onClick"])
  ], 2))
}