import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "balances__page" }
const _hoisted_2 = { class: "balances-wrapper" }
const _hoisted_3 = { class: "balances__title" }
const _hoisted_4 = { class: "balances__list" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "balances__label col-6" }
const _hoisted_7 = { class: "balances__label col-6" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "balances__number" }
const _hoisted_11 = { style: {"word-break":"break-all"} }
const _hoisted_12 = { class: "balances__label" }
const _hoisted_13 = { class: "col-6 additional-text" }
const _hoisted_14 = { class: "balances__number" }
const _hoisted_15 = { style: {"word-break":"break-all"} }
const _hoisted_16 = { class: "balances__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_CreatePayoutDialog = _resolveComponent("CreatePayoutDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.$t('pages.payouts.title')), 1),
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('pages.payouts.access')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('pages.payouts.hold')), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedBalances, (balances, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "balances__row",
              key: index
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h6", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getFormattedValue(balances[0].amount)), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.currencies[balances[0].currency]), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("h6", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.getFormattedValue(balances[1].amount)), 1),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.currencies[balances[1].currency]), 1)
                  ])
                ])
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", {
          style: {"margin-top":"16px"},
          class: _normalizeClass(["row", { 'justify-center': _ctx.$q.screen.width < 744 }])
        }, [
          _createVNode(_component_q_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCreatePayoutDialog = true)),
            outline: "",
            "no-caps": "",
            disable: _ctx.isPayoutDisabled,
            label: _ctx.$t('pages.payouts.action'),
            color: "primary",
            style: {"width":"100%","max-width":"250px","border-color":"#EDB94A","padding-top":"12px","padding-bottom":"12px"},
            class: "balances__btn"
          }, null, 8, ["disable", "label"])
        ], 2)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_q_table, {
          dark: "",
          dense: "",
          rows: _ctx.rows,
          columns: _ctx.createTableColumns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          "binary-state-sort": "",
          class: "table-with-min-height-row"
        }, {
          "body-cell-status": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass([_ctx.getBadgeClassName(props), "balances__badge"])
                }, _toDisplayString(_ctx.payoutStatuses[props.row.status]), 3)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
      ])
    ]),
    _createVNode(_component_CreatePayoutDialog, {
      open: _ctx.showCreatePayoutDialog,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCreatePayoutDialog = false)),
      onRefresh: _ctx.refresh,
      maxUsdAmount: _ctx.getMaxUsdAmount
    }, null, 8, ["open", "onRefresh", "maxUsdAmount"])
  ], 64))
}