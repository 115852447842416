
import Button from '@/components/sharable/Button.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MediaHeroSection',
  props: ['onRequest'],
  components: {
    Button,
  },
})
