
import Header from '../components/header/Header.vue'
import Footer from '../components/footer/Footer.vue'
import { defineComponent } from 'vue'
import axios from 'axios'
import { mapMutations, mapState } from 'vuex'
import Navigation from '../components/navigation/Navigation.vue'
import TitleDivider from '../components/sharable/TitleDivider.vue'

export default defineComponent({
  name: 'DashboardView',
  components: { Footer, Header, Navigation, TitleDivider },
  data: function() {
    return {
      miniState: false,
      drawer: false,
    }
  },
  computed: {
    ...mapState('authModule', ['user']),
    isApproved(): boolean {
      return this.user?.data?.isApproved
    },
  },
  mounted() {
    axios.get('/api/profile').catch(err => {
      if (err.response.status === 401 || err.response.status === 403) {
        this.logout()
      }
    })
  },
  methods: {
    ...mapMutations('authModule', ['logout']),
    changeMiniState() {
      this.miniState = !this.miniState
    },
    openDrawer() {
      this.drawer = !this.drawer
    },
  },
})
