
import { defineComponent } from 'vue'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getTransactionsColumns } from '@/pages/payments/config/columns'
import { Currency } from '@/enums/Currency'
import { RewardType } from '@/enums/RewardType'
import { TransactionReason } from '@/enums/TransactionReason'
import { formatValue } from '@/utils'

export default defineComponent({
  name: 'Transactions',
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('datetime'),
      currencies: Currency,
      rewardTypes: RewardType,
      transactionReasons: TransactionReason,
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/transactions/')
    },
    getFormattedValue(amount: number | string) {
      return formatValue(amount, 3)
    },
  },
  computed: {
    createColumns() {
      return getTransactionsColumns()
    },
  },
})
