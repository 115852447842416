import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md language__switcher" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_select, {
        class: "transparent-background",
        modelValue: $setup.locale,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (($setup.locale) = $event)),
          $options.switchLanguage
        ],
        options: $setup.localeOptions,
        style: {"width":"45px bg-color: transparent"},
        behavior: "menu",
        "no-error-icon": "",
        "hide-hint": "",
        name: "LanguageSwitcher",
        "hide-bottom-space": "",
        borderless: "",
        dense: ""
      }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
    ])
  ]))
}