
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserInfo',
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
})
