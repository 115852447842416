
import { defineComponent } from 'vue'
import LoginDialog from '../components/dialogs/LoginDialog.vue'
import SignUpDialog from '../components/dialogs/SignUpDialog.vue'
import Header from '../components/main-header/MainHeader.vue'
import Footer from '../components/footer/Footer.vue'
import Button from '../components/sharable/Button.vue'
import Ticker from '@/components/sharable/Ticker.vue'
import Advantages from './media-buying-parts/Advantages.vue'
import Choice from './media-buying-parts/Choice.vue'
import OurStats from './media-buying-parts/OurStats.vue'
import RequestModal from '../components/dialogs/MediaBuyingRequest.vue'
import { addMetaToThePage } from '@/utils/meta'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CareerView',
  components: {
    OurStats,
    Header,
    Footer,
    Button,
    Ticker,
    Advantages,
    Choice,
    RequestModal,
    LoginDialog,
    SignUpDialog,
  },
  data: () => {
    return {
      locale: 'ru',
      excelData: [],
      isLeaveRequestModalOpen: false,
      showLoginDialog: false,
      showRegDialog: false,
      mediaBuyerInfo: [
        { id: 1, label: 'Team Lead FB | Google' },
        { id: 2, label: 'Media Buyer Google PPC' },
        { id: 3, label: 'Media Buyer Google UAC' },
        { id: 4, label: 'Media Buyer Facebook Android / IOS' },
        { id: 5, label: 'Recruiter' },
        { id: 6, label: 'Motion Designer' },
        { id: 7, label: 'Affiliate Manager Junior' },
        { id: 8, label: 'Affiliate Manager Middle ' },
        { id: 9, label: 'Business Development Manager Masons Partners' },
        { id: 10, label: 'Business Development Manager Masons Traffic' },
        { id: 11, label: 'Quality Manager ' },
        { id: 12, label: 'Sales Manager ' },
        { id: 13, label: 'Tech Specialist Middle' },
        { id: 14, label: 'Full Stack Developer (Vue/Nest/PostgreSQL)' },
        { id: 15, label: 'Web Designer' },
      ],
    }
  },
  methods: {
    onClickLogin() {
      this.showLoginDialog = !this.showLoginDialog
    },
    onClickReg() {
      this.showRegDialog = !this.showRegDialog
    },
    onClickRequest() {
      this.isLeaveRequestModalOpen = !this.isLeaveRequestModalOpen
    },
  },
  mounted() {
    const { locale } = useI18n()
    this.locale = locale

    addMetaToThePage(
      this.$t('meta.career.title'),
      this.$t('meta.career.description'),
    )
  },
  watch: {
    locale() {
      addMetaToThePage(
        this.$t('meta.career.title'),
        this.$t('meta.career.description'),
      )
    },
  },
})
