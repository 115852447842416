
import axios from 'axios'
import { defineComponent } from 'vue'
import { enumToArray, payoutCurrencies as currencies } from '@/utils'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { Currency } from '@/enums/Currency'

export default defineComponent({
  name: 'AddPaymentSystemDialog',
  props: ['open', 'close', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      payoutSystemAccount: {},
      paymentSystemsEnum: PaymentSystem,
      paymentSystems: enumToArray(PaymentSystem),
      currencies,
      name: null,
      errors: {},
      loading: false,
    }
  },
  watch: {
    payoutSystemAccount: {
      handler() {
        if (
          [
            PaymentSystem.USDT_ERC20,
            PaymentSystem.USDT_TRC20,
            PaymentSystem.USDT_OMNI,
          ].includes((this.payoutSystemAccount as any).paymentSystem)
        ) {
          ;(this.payoutSystemAccount as any).currency = Currency.USD
        }
      },
      deep: true,
    },
  },
  methods: {
    addPayoutAccount() {
      this.loading = true
      axios
        .post('/api/payout-system-accounts', { ...this.payoutSystemAccount })
        .then(() => {
          this.$emit('refresh')
          this.onClose()
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClose() {
      this.payoutSystemAccount = {}
      this.errors = {}
      this.$emit('close')
    },
  },
})
