
import { defineComponent } from 'vue'
import Button from '../sharable/Button.vue'
import axios from 'axios'

interface MediaRequestForm {
  name: string
  telegram: string
  phone: string
  cv: File | undefined
  errors: {
    cv: string
  }
}
export default defineComponent({
  name: 'LoginDialog',
  props: ['open', 'close'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function(): MediaRequestForm {
    return {
      name: '',
      telegram: '',
      phone: '',
      cv: undefined,
      errors: {
        cv: '',
      },
    }
  },
  methods: {
    async submitRequest(event: any) {
      const name = event.target.name.value
      const phone = event.target.phone.value
      const telegram = event.target.telegram.value

      if (!this.cv) {
        this.errors.cv = 'загрузите резюме'
        return
      }

      const form = new FormData()
      form.append('name', name)
      form.append('telegram', telegram)
      form.append('phone', phone)

      form.append('cv', this.cv)

      try {
        await axios.post('/api/public/media-buying-request', form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
      } catch (error) {
        this.errors.cv = error.message
      } finally {
        this.onClose()
      }

      return
    },
    onClose() {
      this.name = ''
      this.telegram = ''
      this.phone = ''
      this.cv = undefined
      this.$emit('close')
    },
    onFileUpload(e: unknown) {
      this.createFile(e.target.files[0])
    },
    createFile(file: File) {
      const allowebFileSizeInMb = 1
      const maxSize = allowebFileSizeInMb * 1024 * 1024
      if (file.size > maxSize) {
        const notification =
          'Загрузите файл не более ' + allowebFileSizeInMb + ' MB'
        alert(notification)
        return
      }

      this.errors.cv = ''
      const reader = new FileReader()
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const component = this
      reader.onload = function(e: any) {
        component.image = e.target.result
      }
      reader.readAsDataURL(file)
      this.cv = file
    },
    baseValidation(val?: string) {
      if (val && val.length) {
        const isIncludedNotAppropriateValue = val
          .split('')
          .some(el => [':', '>', '<', 'script', 'javascript'].includes(el))
        return !isIncludedNotAppropriateValue || 'недопустимые символы'
      }
      return true
    },
    requiredField(val?: string) {
      return (val !== null && val !== '') || 'Обязательное поле'
    },
  },
  components: { Button },
})
