
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import router from '@/router'
import Ticker from '@/components/sharable/Ticker.vue'

import Button from '../components/sharable/Button.vue'
import ExpansionList from '../components/sharable/ExpansionList.vue'
import Footer from '../components/footer/Footer.vue'
import Header from '../components/main-header/MainHeader.vue'
import LoginDialog from '../components/dialogs/LoginDialog.vue'
import SignUpDialog from '../components/dialogs/SignUpDialog.vue'
import TeamSection from '../views/media-buying-parts/MediaTeam.vue'
import Advantages from './components/Advantages.vue'
import Expertise from './components/Expertise.vue'
import ContactForm from './components/ContactForm.vue'
import { addMetaToThePage } from '@/utils/meta'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'MainLanding',
  components: {
    Button,
    // NewsSLider,
    ExpansionList,
    Footer,
    Header,
    LoginDialog,
    SignUpDialog,
    TeamSection,
    Advantages,
    Expertise,
    Ticker,
    ContactForm,
  },
  computed: mapState('authModule', ['status']),
  data: function() {
    return {
      locale: 'ru',
      showMenu: false,
      showLoginDialog: false,
      showRegDialog: false,
    }
  },
  methods: {
    async enterRedirect() {
      if (this.status.loggedIn) {
        await router.push('/dashboard')
      } else {
        this.showLoginDialog = true
      }
    },
    async onClickLogin() {
      if (this.status.loggedIn) {
        await router.push('/dashboard')
        return
      }
      this.showLoginDialog = !this.showLoginDialog
    },
    onClickReg() {
      this.showRegDialog = !this.showRegDialog
    },
    prepareFaqArticles() {
      return [
        {
          numbered: true,
          title: this.$t('landingFaq.0.title'),
          text: this.$t('landingFaq.0.text'),
        },
        {
          numbered: true,
          title: this.$t('landingFaq.1.title'),
          text: this.$t('landingFaq.1.text'),
        },
        {
          numbered: true,
          title: this.$t('landingFaq.2.title'),
          text: this.$t('landingFaq.2.text'),
        },
        {
          numbered: true,
          title: this.$t('landingFaq.3.title'),
          text: this.$t('landingFaq.3.text'),
        },
        {
          numbered: true,
          title: this.$t('landingFaq.4.title'),
          text: this.$t('landingFaq.4.text'),
        },
        {
          numbered: true,
          title: this.$t('landingFaq.5.title'),
          text: this.$t('landingFaq.5.text'),
        },
      ]
    },
  },
  mounted() {
    const { locale } = useI18n()
    this.locale = locale

    addMetaToThePage(
      this.$t('meta.mainPage.title'),
      this.$t('meta.mainPage.description'),
    )
  },
  watch: {
    locale() {
      addMetaToThePage(
        this.$t('meta.mainPage.title'),
        this.$t('meta.mainPage.description'),
      )
    },
  },
})
