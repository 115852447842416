
import { defineComponent } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  isMonday,
  previousMonday,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns'

export default defineComponent({
  name: 'DatePicker',
  props: ['from', 'to', 'changeDate', 'isDepositDate'],
  components: { Datepicker },
  data: function() {
    const dateValue = [this.from, this.to]

    return {
      dateValue,
    }
  },
  watch: {
    from() {
      this.dateValue[0] = this.from
    },
    to() {
      this.dateValue[1] = this.to
    },
    dateValue() {
      const from = this.formatDate(this.dateValue[0])
      const to = this.formatDate(this.dateValue[1])
      this.$emit('changeDate', [from, to])
    },
  },
  methods: {
    formatDate(date: any): string {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('/')
    },
    getCurrentWeek() {
      const date = new Date()
      if (isMonday(date)) {
        return [date, date]
      }
      return [previousMonday(date), date]
    },
    getLastWeek() {
      const date = new Date()
      if (isMonday(date)) {
        return [sub(date, { days: 7 }), sub(date, { days: 1 })]
      }
      const dayOnPrevWeek = sub(date, { weeks: 1 })
      if (isMonday(dayOnPrevWeek)) {
        return [dayOnPrevWeek, endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 })]
      }
      return [
        previousMonday(dayOnPrevWeek),
        endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
      ]
    },
  },
  computed: {
    createCustomShortCuts() {
      const today = new Date()
      const customShortcuts = [
        {
          label: this.$t('calendar.today'),
          range: [today, today],
        },
        {
          label: this.$t('calendar.yesterday'),
          range: [sub(today, { days: 1 }), sub(today, { days: 1 })],
        },
        {
          label: this.$t('calendar.currentWeek'),
          range: this.getCurrentWeek(),
        },
        {
          label: this.$t('calendar.lastWeek'),
          range: this.getLastWeek(),
        },
        {
          label: this.$t('calendar.currentMonth'),
          range: [startOfMonth(today), today],
        },
        {
          label: this.$t('calendar.lastMonth'),
          range: [
            startOfMonth(sub(today, { months: 1 })),
            endOfMonth(sub(today, { months: 1 })),
          ],
        },
        {
          label: this.$t('calendar.thisYear'),
          range: [startOfYear(today), today],
        },
        {
          label: this.$t('calendar.lastYear'),
          range: [
            startOfYear(sub(today, { years: 1 })),
            endOfYear(sub(today, { years: 1 })),
          ],
        },
        {
          label: this.$t('calendar.allTime'),
          range: [new Date('2022-05-01'), today],
        },
      ]
      return customShortcuts
    },
  },
})
