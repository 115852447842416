
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import PostbackNoteDialog from '@/components/dialogs/PostbackNoteDialog.vue'
import { Postback } from '@/store/types'
import axios from 'axios'
import { getPostbackTypes } from '@/utils/postbacks'

export default defineComponent({
  name: 'Postbacks',
  props: ['isGlobal'],
  components: { PostbackNoteDialog },
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  async created() {
    this.errors = {}
    if (!this.isGlobal) {
      this.postbacks = [...this.campaign.postbacks] as never[]
    } else {
      this.postbacks = (await axios.get('/api/postbacks')).data
    }

    if (!this.postbacks?.length) {
      this.createPostback()
    }
  },
  data: function() {
    return {
      showPostbackNoteDialog: false,
      postbacks: [],
      postbackTypes: getPostbackTypes(),
    }
  },
  watch: {
    postbacks: {
      handler() {
        this.updatePostbacks()
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    createPostback() {
      ;(this.postbacks as Postback[]).push({
        types: this.postbackTypes.map(({ value }) => value),
        url: '',
      })
    },
    addPostback() {
      this.createPostback()
      this.update({ postbacks: [...this.postbacks] })
    },
    updatePostbacks() {
      this.update({ postbacks: this.postbacks })
    },
    removePostback(index: number) {
      this.postbacks.splice(index, 1)
      this.updatePostbacks()
    },
  },
})
