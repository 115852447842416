
import axios from 'axios'
import { defineComponent } from 'vue'
import ProfileEditDialog from '@/components/dialogs/ProfileEditDialog.vue'
import AvatarEditDialog from '@/components/dialogs/AvatarEditDialog.vue'

export default defineComponent({
  name: 'GeneralInfo',
  components: { AvatarEditDialog, ProfileEditDialog },
  async created(): Promise<void> {
    await this.onRequest()
    this.loading = false
  },
  data: function() {
    return {
      storageUrl: process.env.CDN_STORAGE_URL,
      affiliate: { credentials: {}, contacts: { telegram: null }, image: '' },
      loading: true,
      showProfileEditDialog: false,
      showAvatarEditDialog: false,
    }
  },
  methods: {
    async onRequest() {
      this.affiliate = (await axios.get('/api/profile')).data
    },
  },
})
