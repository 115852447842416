import { GroupByOptions } from '@/utils/request'

export const groupByOptions: GroupByOptions = [
  {
    id: 'date',
    name: 'Дата',
    field: 'date',
  },
  {
    id: 'week',
    name: 'Недели',
    field: 'week',
  },
  {
    id: 'offerId',
    name: 'Оффер',
    field: 'offerName',
  },
  {
    id: 'campaignId',
    name: 'Кампания',
    field: 'campaignName',
  },
  {
    id: 'geoId',
    name: 'Гео',
    field: 'geoName',
  },
  {
    id: 'landingId',
    name: 'Промо оффера (лендинг)',
    field: 'promoName',
  },
  {
    id: 'sub1',
    name: 'sub 1',
    field: 'sub1',
  },
  {
    id: 'sub2',
    name: 'sub 2',
    field: 'sub2',
  },
  {
    id: 'sub3',
    name: 'sub 3',
    field: 'sub3',
  },
  {
    id: 'sub4',
    name: 'sub 4',
    field: 'sub4',
  },
  {
    id: 'sub5',
    name: 'sub 5',
    field: 'sub5',
  },
  {
    id: 'sub6',
    name: 'sub 6',
    field: 'sub6',
  },
  {
    id: 'sub7',
    name: 'sub 7',
    field: 'sub7',
  },
  {
    id: 'sub8',
    name: 'sub 8',
    field: 'sub8',
  },
  {
    id: 'sub9',
    name: 'sub 9',
    field: 'sub9',
  },
  {
    id: 'sub10',
    name: 'sub 10',
    field: 'sub10',
  },
  {
    id: 'sub11',
    name: 'sub 11',
    field: 'sub11',
  },
  {
    id: 'sub12',
    name: 'sub 12',
    field: 'sub12',
  },
  {
    id: 'sub13',
    name: 'sub 13',
    field: 'sub13',
  },
  {
    id: 'sub14',
    name: 'sub 14',
    field: 'sub14',
  },
  {
    id: 'sub15',
    name: 'sub 15',
    field: 'sub15',
  },
]
