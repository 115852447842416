
import { defineComponent } from 'vue'
import axios from 'axios'
import { enumToArray } from '@/utils'
import { Messengers } from '@/enums/Messengers'
import { lookupGeos } from '@/utils/lookups'

export default defineComponent({
  name: 'ProfileEditDialog',
  props: ['open', 'close', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  async created(): Promise<void> {
    this.affiliate = (await axios.get('/api/profile')).data
    this.geos = (await axios.get('/api/geos')).data
    this.ready = true
  },
  data() {
    return {
      affiliate: { credentials: {}, contacts: { telegram: null }, image: '' },
      geos: [],
      ready: false,
      loading: false,
      errors: {},
      messengers: enumToArray(Messengers),
      selectedMessenger: Messengers.Telegram,
    }
  },
  methods: {
    onSubmit: async function() {
      this.loading = true
      try {
        await axios.put('/api/profile', this.affiliate)
        this.errors = {}
        this.$emit('refresh')
        this.$emit('close')
      } catch (error) {
        this.errors = (error as any).response?.data.errors
      }
      this.loading = false
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
  },
})
