
import { defineComponent } from 'vue'
import LineChart from '@/components/charts/LineChart.vue'
import { getData } from '@/utils/getData'
import {
  FiltersType,
  getDefaultFilters,
  getTableProperties,
} from '@/utils/request'
import { groupByOptions } from '../statistics/config/groupByOptions'
import { getGeneralColumns } from '@/pages/statistics/config/columns'
import Filters from '@/components/filters/Filters.vue'
import Statistics from './Statistics.vue'

export default defineComponent({
  name: 'Dashboard',
  components: { Filters, LineChart, Statistics },
  async created() {
    await this.onRequestChartData()
    await this.onRequest()
  },
  computed: {
    getGroupByOptions(): any {
      return groupByOptions
    },
    columns(): any {
      return getGeneralColumns()
    },
  },
  data: function() {
    return {
      chartLoading: false,
      chartData: [],
      conv: {
        clicks: 0,
        conversions: 0,
        hold: 0,
        approve: 0,
      },
      selectedColumns: [
        'uniqueClicks',
        'registrationCount',
        'firstDepositCount',
      ],
      filters: getDefaultFilters(),
      ...getTableProperties('groupByField'),
    }
  },
  watch: {
    async filters() {
      this.pagination.page = 1
      await this.onRequestChartData()
      await this.onRequest()
    },
  },
  methods: {
    async onRequest() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.conv = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/dashboard',
      )
    },
    async onRequestChartData() {
      this.chartLoading = true
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }

      this.chartData = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/dashboard/chart',
      )

      this.chartLoading = false
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = { ...getDefaultFilters(true) }
    },
  },
})
