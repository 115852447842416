import { formatDate, formatValue, isDefined, RowValue } from '@/utils'
import { Row } from '@/utils/request'
import { Currency } from '@/enums/Currency'
import { LeadStatus } from '@/enums/LeadStatus'
import { useI18n } from 'vue-i18n'

export default function getColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'datetime',
      label: t('tables.conversions.date'),
      field: 'datetime',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    // {
    //   name: 'conversionDate',
    //   label: t('tables.conversions.dateDep'),
    //   field: 'conversionDate',
    //   align: 'center',
    //   sortable: true,
    //   format: (val: string) => formatDate(val),
    // },
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'campaignName',
      label: t('tables.conversions.campaighn'),
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: t('tables.conversions.offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'geo',
      label: t('tables.conversions.geo'),
      field: 'geo',
      align: 'center',
      sortable: true,
    },
    {
      name: 'status',
      label: t('tables.conversions.status'),
      field: 'status',
      align: 'center',
      sortable: true,
      format: (val: number) => {
        return LeadStatus[val]
      },
    },
    {
      name: 'rewardType',
      label: t('tables.conversions.type'),
      field: 'rewardType',
      align: 'center',
      format: (val: RowValue, row: Row) => {
        if (row.status === LeadStatus.Pending && row.amount === null) {
          return 'Registration'
        }
        return isDefined(val)
      },
    },
    {
      name: 'amount',
      label: t('tables.conversions.payout'),
      field: 'amount',
      align: 'center',
      format: (val: RowValue, row: any) => {
        let dislayValue = formatValue(val, 3) + ' '

        if (!row?.attributes?.over_postclick) {
          dislayValue += Currency[row.currency] ?? ''
        }

        return dislayValue
      },
    },
    {
      name: 'trafficSource',
      label: t('tables.conversions.source'),
      field: 'trafficSource',
      align: 'center',
    },
    {
      name: 'trafficProperty',
      label: t('tables.conversions.sub'),
      field: 'trafficProperty',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'redirectEventId',
      label: t('tables.conversions.click'),
      field: 'redirectEventId',
      align: 'center',
    },
  ]
}
