import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-472cb8c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "flex column" }
const _hoisted_3 = { class: "flex column" }
const _hoisted_4 = { class: "flex column" }
const _hoisted_5 = { class: "flex column" }
const _hoisted_6 = { class: "flex column general__space" }
const _hoisted_7 = { class: "flex column general__space" }
const _hoisted_8 = { class: "flex column" }
const _hoisted_9 = { class: "flex column" }
const _hoisted_10 = { class: "flex column" }
const _hoisted_11 = { class: "flex column" }
const _hoisted_12 = { class: "flex column general__space" }
const _hoisted_13 = { class: "flex column general__space" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_AddCustomGroupDialog = _resolveComponent("AddCustomGroupDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.name')), 1),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          placeholder: _ctx.$t('pages.campaighn.steps.buyingGenInfo.namePlaceholder'),
          "model-value": _ctx.campaign.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = name => _ctx.update({ name })),
          error: !!_ctx.errors?.name,
          "error-message": _ctx.errors?.name
        }, null, 8, ["placeholder", "model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.identity')), 1),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          placeholder: 
          _ctx.$t('pages.campaighn.steps.buyingGenInfo.identityPlaceholder')
        ,
          "model-value": _ctx.campaign.identifier,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = identifier => _ctx.update({ identifier })),
          error: !!_ctx.errors?.identifier,
          "error-message": _ctx.errors?.identifier
        }, null, 8, ["placeholder", "model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.group')), 1),
        _createVNode(_component_q_select, {
          dense: "",
          clearable: "",
          "options-dense": "",
          "model-value": _ctx.campaign.groupId,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = groupId => _ctx.update({ groupId })),
          outlined: "",
          options: _ctx.groups,
          onFilter: _ctx.getCampaignsGroups,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          error: !!_ctx.errors?.groupId,
          "error-message": _ctx.errors?.groupId
        }, {
          after: _withCtx(() => [
            _createVNode(_component_q_btn, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddGroupDialog = !_ctx.showAddGroupDialog)),
              size: "sm",
              color: "secondary",
              icon: "add"
            })
          ]),
          _: 1
        }, 8, ["model-value", "options", "onFilter", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.link')), 1),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          disable: true,
          placeholder: _ctx.$t('pages.campaighn.steps.buyingGenInfo.linkPlaceholder'),
          "model-value": _ctx.campaign.url
        }, {
          after: _withCtx(() => [
            _createVNode(_component_q_btn, {
              disable: !_ctx.campaign.url,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.copy(_ctx.campaign.url))),
              size: "sm",
              color: "secondary",
              icon: "content_copy"
            }, null, 8, ["disable"])
          ]),
          _: 1
        }, 8, ["placeholder", "model-value"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.end')), 1),
        _createVNode(_component_q_select, {
          outlined: "",
          dense: "",
          "options-dense": "",
          "model-value": _ctx.campaign.urlRoute,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = urlRoute => _ctx.update({ urlRoute })),
          options: _ctx.urlRoutes
        }, null, 8, ["model-value", "options"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.status')), 1),
        _createVNode(_component_q_select, {
          dense: "",
          "options-dense": "",
          outlined: "",
          "model-value": _ctx.campaign.status,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = status => _ctx.update({ status })),
          options: _ctx.statuses,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          error: !!_ctx.errors?.status,
          "error-message": _ctx.errors?.status
        }, null, 8, ["model-value", "options", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.chapter')), 1),
        _createVNode(_component_q_select, {
          dense: "",
          "options-dense": "",
          outlined: "",
          "model-value": _ctx.campaign.department,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = department => _ctx.update({ department })),
          options: _ctx.departments,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          error: !!_ctx.errors?.department,
          "error-message": _ctx.errors?.department
        }, null, 8, ["model-value", "options", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.source')), 1),
        _createVNode(_component_q_select, {
          dense: "",
          "options-dense": "",
          "model-value": _ctx.campaign.trafficSourceId,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = trafficSourceId => _ctx.update({ trafficSourceId })),
          outlined: "",
          options: _ctx.trafficSources,
          onFilter: _ctx.getTrafficSources,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          error: !!_ctx.errors?.trafficSourceId,
          "error-message": _ctx.errors?.trafficSourceId
        }, null, 8, ["model-value", "options", "onFilter", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.domain')), 1),
        _createVNode(_component_q_select, {
          dense: "",
          "options-dense": "",
          "model-value": _ctx.campaign.redirectDomainId,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = redirectDomainId => _ctx.update({ redirectDomainId })),
          outlined: "",
          options: _ctx.redirectDomains,
          onFilter: _ctx.getRedirectDomains,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          error: !!_ctx.errors?.redirectDomainId,
          "error-message": _ctx.errors?.redirectDomainId
        }, null, 8, ["model-value", "options", "onFilter", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_q_checkbox, {
          "model-value": _ctx.campaign.bindUser,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = bindUser => _ctx.update({ bindUser })),
          label: _ctx.$t('pages.campaighn.steps.buyingGenInfo.checkbox')
        }, null, 8, ["model-value", "label"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.type')), 1),
        _createVNode(_component_q_option_group, {
          modelValue: _ctx.campaign.redirectLinkType,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.campaign.redirectLinkType) = $event)),
          options: _ctx.redirectLinkTypeOptions,
          color: "primary",
          dark: "",
          dense: ""
        }, null, 8, ["modelValue", "options"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.buyingGenInfo.notes')), 1),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          type: "textarea",
          "model-value": _ctx.campaign.comment,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = value => _ctx.update({ comment: value })),
          error: !!_ctx.errors?.comment,
          "error-message": _ctx.errors?.comment
        }, null, 8, ["model-value", "error", "error-message"])
      ])
    ]),
    _createVNode(_component_AddCustomGroupDialog, {
      open: _ctx.showAddGroupDialog,
      onClose: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showAddGroupDialog = false)),
      field: "campaign"
    }, null, 8, ["open"])
  ], 64))
}