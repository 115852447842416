
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import GeneralTable from './tables/GeneralTable.vue'
import { FiltersType, getDefaultFilters } from '@/utils/request'
import { LocationQueryValue } from 'vue-router'
import { getPagination } from '@/utils/request'
import { getGeneralColumns } from '@/pages/statistics/config/columns'

export default defineComponent({
  name: 'Statistics',
  components: { Filters, GeneralTable },
  computed: {
    complexFilters(): any {
      return { ...this.filters, pagination: this.pagination }
    },
  },
  created() {
    if (Object.keys(this.$route.params).length) {
      const paramsFilters = JSON.parse(this.$route.params.filters as any)
      for (const [key, value] of Object.entries(paramsFilters)) {
        if (Number(value) || Number(value) === 0) {
          paramsFilters[key] = (Number(value) as unknown) as string
        }
      }
      this.filters = { ...this.filters, ...paramsFilters }
    }
  },
  data: function() {
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      if (isNaN(Number(value))) {
        queryFilters[filter] = value as LocationQueryValue
      } else {
        queryFilters[filter] = (Number(value) as unknown) as LocationQueryValue
      }
    }
    const mb = JSON.parse(localStorage.getItem('user') as any)?.data
      ?.isMediaBuying
    const storagePagination =
      this.$storage.getStorageSync('buyerStatsPagination') || null
    return {
      exportCsv: false,
      filters: { ...getDefaultFilters(), ...queryFilters },
      pagination: storagePagination || {
        ...getPagination('groupByField', 30),
        selectedColumns: ['clicks', 'registrationCount', 'firstDepositCount'],
        visibleColumns: getGeneralColumns(mb).map((c: any) => c.name),
      },
    }
  },
  methods: {
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = { ...getDefaultFilters(true) }
    },
    onExport() {
      this.exportCsv = !this.exportCsv
    },
    changePagination(pagination: any) {
      this.pagination = pagination
      this.$storage.setStorageSync('buyerStatsPagination', pagination)
    },
  },
})
