import axios, { AxiosError } from 'axios'
import { Currency } from '@/enums/Currency'
import { Balance } from '@/store/types'
import { BalanceType } from '@/enums/BalanceType'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

type SearchParams = { ids?: number[]; search: string }
export type RowValue = string | null | number | undefined
const StringIsNumber = (value: string) => !isNaN(Number(value))
interface EnumItem<E> {
  id: keyof E
  name: E
}

export function enumToArray<E>(enumObject: {
  [key: string]: E
}): EnumItem<E>[] {
  return Object.keys(enumObject)
    .filter(StringIsNumber)
    .map(key => ({ id: Number(key), name: enumObject[key] } as EnumItem<E>))
}

export function isDefined(value: RowValue) {
  if (value === null || value === undefined) {
    return '—'
  }
  return value
}

export function formatValue(value: RowValue, round = 2, isMoney = false) {
  const formattedValue = isDefined(value)
  if (!isNaN(Number(formattedValue)) && isMoney) {
    return (+formattedValue).toFixed(round)
  }
  if (Number.isInteger(+formattedValue)) {
    return Number.parseInt(String(formattedValue))
  }
  if (formattedValue === '—') {
    return formattedValue
  }
  return Number((+formattedValue).toFixed(round))
}

export function formatDate(isoDate: string) {
  if (!isoDate) {
    return '—'
  }
  const date = new Date(isoDate)
  if (!date) {
    return '—'
  }
  return (
    date
      .toLocaleDateString()
      .split('/')
      .reverse()
      .join('/') +
    ' ' +
    date.toLocaleTimeString()
  )
}

export async function getOptions(
  options: [],
  value: string,
  url: string,
  ids: number[] = [],
  extraParams: any = {},
): Promise<any> {
  if (!options.length || value === '') {
    return (await axios.get(url, { params: extraParams })).data
  }

  const params: SearchParams = { search: value }
  const selectedIds = ids.filter(Number)
  if (selectedIds.length) {
    params.ids = selectedIds
  }

  if (value !== '') {
    return (await axios.get(url, { params: { ...params, ...extraParams } }))
      .data
  }

  return options
}

export const payoutCurrencies = enumToArray(Currency).filter(c =>
  [Currency.USD].includes((c.id as unknown) as number),
)

export function getAffiliateBalances(
  affiliateBalances: Balance[],
  type?: BalanceType,
) {
  const balances: Balance[] = affiliateBalances
    .filter((b: Balance) => !b.isRentAppsBalance)
    .sort((b1: Balance, b2: Balance) =>
      String(b1.currency).localeCompare(String(b2.currency)),
    )
  const available = balances.filter(
    (b: Balance) => b.type === BalanceType.Available,
  )
  const hold = balances.filter((b: Balance) => b.type === BalanceType.Hold)
  const formattedBalances = []
  for (let i = 0; i < available.length; i++) {
    const holdBalance = formatValue(hold[i].amount)
    const availableBalance = formatValue(available[i].amount)
    if (type === BalanceType.Hold) {
      formattedBalances.push(
        `${holdBalance} ${Currency[available[i].currency]}`,
      )
    } else {
      formattedBalances.push(
        `${availableBalance}   [ ${holdBalance} ]  ${
          Currency[available[i].currency]
        }`,
      )
    }
  }
  return formattedBalances.join('\n')
}

export async function downloadPromo(id: string) {
  const response: any = await axios.get(`/api/promos/${id}/download`)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = response.data.url
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    URL.revokeObjectURL(link.href)
    ;(link.parentNode as any).removeChild(link)
  }, 0)
}

export function showErrorSnackBar(err: AxiosError) {
  if (err.response?.status === 500) {
    Notify.create({
      type: 'negative',
      timeout: 3000,
      position: 'top-right',
      message: `Internal Server Error: ${err.message}`,
    })
  }
}

export function showSuccessSnackBar(message: string) {
  Notify.create({
    type: 'positive',
    timeout: 3000,
    position: 'top-right',
    message,
  })
}

export function confirmDialog(message: string, okFn: any) {
  Dialog.create({
    title: 'Подтвердите действие',
    message,
    cancel: true,
    persistent: true,
  }).onOk(okFn)
}

export function showErrorMessageSnackBar(message: string) {
  Notify.create({
    type: 'negative',
    timeout: 3000,
    position: 'top-right',
    message,
  })
}

export const campaignRoutes = [
  'sp',
  'reg',
  'r',
  'e9ZqMY',
  'Fj3MXi',
  'AJIOSj',
  'th9Oyi',
  '7mTNSO',
  'iLDATa',
  'fo1K6T',
  'qc3I9z',
  'Ue1oxQ',
  'KWamNW',
  '3dnjGp',
  'UT19kU',
  'dvPFGg',
  'Hbdf7J',
  'NoP2G1',
  's6OBCq',
  'GnFEoT',
  'cctEN5',
  'SMDzSU',
  'je6xYZ',
  'YzebqX',
  'FnvtTC',
  'mjwibz',
  '2hWbg3',
  'sRZNqT',
  'h61bFI',
  'usQSWG',
  'KqknEM',
  'RcICmk',
  'L6C8Tj',
  'FQRfDh',
  'm9FwdE',
  'wWiHsD',
  'CeHZ0c',
  'kPl6hB',
  'UCn0RD',
  'ilqZgs',
  'JGqtSI',
  'jlr3LN',
  'ytPMTY',
  'gN8GgZ',
  '4CW3TV',
  'XtMQWA',
  '91iY2Q',
  'xNGHQ7',
  '1SDhT2',
  'HU1j2i',
  'uM5JAU',
  '7d9BDb',
  'JYT1yy',
  'acaVGD',
  'tRG4fU',
  'KfSeq6',
  'TerIJj',
  'CjKrOB',
  'i0528v',
  'XG0npW',
  'nQCVT9',
  'srArhx',
  '0PERC0',
  'M8GO6R',
  'O1kaNb',
  'w7NjQJ',
  'uCjyNr',
  'v2OiBN',
  'YIMLCn',
  'DCd5Ng',
  'w97pG4',
  'h37wGv',
  'ckug5X',
  'm5nO1J',
  'BLIJZY',
  'WzfJbo',
  'hm3kst',
  'fNr7du',
  'jMwEqe',
  '36gSbp',
  'OBQb2n',
  'RJcsXB',
  'ZJiRk8',
  'UPgqZq',
  '4wWRBj',
  'A8lSKT',
  'wZpEtC',
  'xwghdp',
  'wm41kc',
  'LksLw2',
  'WAs2ar',
  'b5RLCt',
  'ffQ1Ul',
  'YzqrQB',
  'RW4D3F',
  'TUNuZP',
  'rVB74Z',
  'V1Vr1F',
  'ZbPQwQ',
  'hWK8TX',
  'QJAKAV',
  'GTB19a',
  'b8cAHr',
]
