
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import {
  Stream,
  StreamFilter,
  StreamLandingRule,
  StreamOfferRule,
} from '@/store/types'
import AddStreamRuleDialog from '@/components/dialogs/AddStreamRuleDialog.vue'
import { lookupLandingsForBuying, lookupOffers } from '@/utils/lookups'
import { StreamFilterOption } from '@/enums/StreamFilterOption'
import { enumToArray } from '@/utils'
import { DeviceType } from '@/enums/DeviceType'
import { OperatingSystem } from '@/enums/OperatingSystem'
import { Browser } from '@/enums/Browser'
import { lookupGeos } from '@/utils/lookups'
import { lookupOfferPromos } from '@/utils/lookups'
import { lookupCampaigns } from '@/utils/lookups'
import { lookupRotations } from '@/utils/lookups'
import { cloneDeep } from 'lodash'
import axios from 'axios'

export default defineComponent({
  name: 'Streams',
  components: { AddStreamRuleDialog },
  props: ['defaultOptions'],
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  async created() {
    this.streams = [...this.campaign.streams]

    if (!this.streams?.length) {
      this.createStream()
    }
  },
  data: function() {
    return {
      isChangingRotation: false,
      rotations: [...(this.defaultOptions.rotations || [])],
      campaigns: [...(this.defaultOptions.campaigns || [])],
      promos: [...(this.defaultOptions.promos || [])],
      offers: [...(this.defaultOptions.offers || [])],
      geos: [...(this.defaultOptions.geos || [])],
      landings: [...(this.defaultOptions.landings || [])],
      showFiltersDialog: false,
      streams: [] as any[],
      streamId: null,
      errors: {},
      filterTypesEnum: StreamFilterOption,
      filterTypes: enumToArray(StreamFilterOption),
      devices: enumToArray(DeviceType),
      oses: enumToArray(OperatingSystem),
      browsers: enumToArray(Browser),
    }
  },
  watch: {
    streams: {
      handler() {
        this.update({ streams: this.streams })
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    createStream() {
      this.streams.push({
        isDefault: false,
        name: 'Правило ' + Number(this.streams.length + 1),
        filters: [],
        offerRules: [],
        landingRules: [],
      })
    },
    addStream() {
      this.createStream()
      this.update({ streams: [...this.streams] })
    },
    removeStream(index: number) {
      this.streams.splice(index, 1)
      this.update({ streams: this.streams, rotationId: null })
    },
    updateStream(stream: Stream, index: number) {
      const streams = this.streams
      streams[index] = stream
      this.update({ streams, rotationId: null })
    },
    addLandingRule(streamIndex: number) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      const landingRules = [
        ...stream.landingRules,
        { landingId: null, percent: 100, isActive: true },
      ]
      this.updateStream({ ...stream, landingRules }, streamIndex)
    },
    removeLandingRule(landingIndex: number, streamIndex: number) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      stream.landingRules.splice(landingIndex, 1)
      this.updateStream(stream, streamIndex)
    },
    updateLandingRule(
      landingRule: StreamLandingRule,
      landingIndex: number,
      streamIndex: number,
    ) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      const landingRules = stream.landingRules
      landingRules[landingIndex] = landingRule
      this.updateStream({ ...stream, landingRules }, streamIndex)
    },
    addOfferRule(streamIndex: number, isCampaignRule = false) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      const offerRules = [
        ...stream.offerRules,
        {
          campaignId: null,
          offerId: null,
          promoId: null,
          percent: 100,
          isActive: true,
          isCampaignRule,
        },
      ]
      this.updateStream({ ...stream, offerRules }, streamIndex)
    },
    removeOfferRule(offerIndex: number, streamIndex: number) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      stream.offerRules.splice(offerIndex, 1)
      this.updateStream(stream, streamIndex)
    },
    updateOfferRule(
      offerRule: StreamOfferRule,
      offerIndex: number,
      streamIndex: number,
    ) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      const offerRules = stream.offerRules
      offerRules[offerIndex] = offerRule
      this.updateStream({ ...stream, offerRules }, streamIndex)
    },
    addFilterCriteria(streamIndex: number) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      const filters = [
        ...stream.filters,
        { option: null, value: null, isEqual: true },
      ]
      this.updateStream({ ...stream, filters }, streamIndex)
    },
    removeFilter(filterIndex: number, streamIndex: number) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      stream.filters.splice(filterIndex, 1)
      this.updateStream(stream, streamIndex)
    },
    updateFilter(
      filter: StreamFilter,
      filterIndex: number,
      streamIndex: number,
    ) {
      const stream = this.streams.find(
        (v: Stream, ind: number) => ind === streamIndex,
      )
      const filters = stream.filters
      filters[filterIndex] = filter
      this.updateStream({ ...stream, filters }, streamIndex)
    },
    onRefresh() {
      this.streamId = null
      this.streams = [...this.campaign.streams]
      this.showFiltersDialog = false
    },
    onOpenFiltersDialog(streamId: number) {
      this.streamId = streamId as any
      this.showFiltersDialog = true
    },
    async getOffers(value: string, update: any) {
      await lookupOffers(this, value, update)
    },
    async getCampaigns(value: string, update: any) {
      await lookupCampaigns(this, value, update)
    },
    async getLandings(value: string, update: any) {
      await lookupLandingsForBuying(this, value, update)
    },
    async getPromos(value: string, update: any, offerId: number) {
      await lookupOfferPromos(this, value, update, offerId)
    },
    async getGeos(value: string, update: any) {
      await lookupGeos(this, value, update)
    },
    async changeRotation(rotationId: number) {
      const elem = document.getElementById('streams-block')
      elem?.classList.add('areadisabled')
      if (!rotationId) {
        this.isChangingRotation = true
        if (this.campaign.oldStreams?.length) {
          this.update({
            streams: this.campaign.oldStreams,
            oldStreams: this.campaign.streams,
            rotationId: null,
          })
          this.streams = this.campaign.oldStreams
        } else {
          this.update({
            streams: [],
            oldStreams: this.campaign.streams,
            rotationId: null,
          })
          this.streams = []
          this.addStream()
        }
        this.isChangingRotation = false
        elem?.classList.remove('areadisabled')
        return
      }
      this.isChangingRotation = true
      const rotation = (await axios.get(`/api/rotations/${rotationId}`)).data
      const streams = cloneDeep(rotation.streams).map((object: any) => {
        object = this.getClearObject(object)
        if (object.filters?.length) {
          object.filters = object.filters.map((f: any) =>
            this.getClearObject(f),
          )
        }
        if (object.landingRules?.length) {
          object.landingRules = object.landingRules.map((f: any) =>
            this.getClearObject(f),
          )
        }
        if (object.offerRules?.length) {
          object.offerRules = object.offerRules.map((f: any) =>
            this.getClearObject(f, false),
          )
        }
        return object
      })
      this.update({ streams, oldStreams: this.campaign.streams, rotationId })
      this.streams = streams
      this.isChangingRotation = false
      elem?.classList.remove('areadisabled')
    },
    async getRotations(value: string, update: any) {
      await lookupRotations(this, value, update)
    },
    getClearObject(object: any, deleteCampaignProperty = true) {
      delete object['id']
      delete object['createdAt']
      delete object['updatedAt']
      delete object['removedAt']
      if (deleteCampaignProperty) {
        delete object['campaign']
        delete object['campaignId']
      }
      delete object['rotation']
      delete object['rotationId']
      delete object['offer']
      delete object['promo']
      delete object['landing']
      return object
    },
  },
})
