
import axios from 'axios'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'AddStreamRuleDialog',
  props: ['open', 'close'],
  computed: {
    ...mapState('authModule', ['user']),
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      name: null,
      errors: {},
    }
  },
  methods: {
    addRedirectDomain() {
      axios
        .post('/api/redirect-domains', {
          name: this.name,
        })
        .then(() => {
          this.onClose()
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
    },
    onClose() {
      this.name = null
      this.errors = {}
      this.$emit('close')
    },
  },
})
