
import { getColumns, getBuyingCampaignColumns } from './config/getColumns'
import { defineComponent } from 'vue'
import axios from 'axios'
import Filters from '@/components/filters/Filters.vue'
import CampaignUrlsBuilder from '@/components/dialogs/CampaignUrlsBuilder.vue'
import {
  FiltersType,
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { mapState } from 'vuex'
import {
  confirmDialog,
  showErrorMessageSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import { CampaignDepartment } from '@/enums/CampaignDepartment'
import { LocationQueryValue } from 'vue-router'
import EditNoteDialog from '@/components/dialogs/EditNoteDialog.vue'
import { CampaignStatus } from '@/enums/CampaignStatus'

export default defineComponent({
  name: 'CampaignsList',
  components: { EditNoteDialog, Filters, CampaignUrlsBuilder },
  async created() {
    await this.onRequest({ pagination: this.pagination })
    this.rows = this.rows.map(row => ({
      ...(row as {}),
      showCampaignUrlsBuilder: false,
    })) as never[]
  },
  computed: {
    ...mapState('authModule', ['isMB', 'user']),
    getColumns() {
      const mb = JSON.parse(localStorage.getItem('user') as any)?.data
        ?.isMediaBuying
      return mb ? getBuyingCampaignColumns() : getColumns()
    },
  },
  data() {
    const queryFilters: any = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      queryFilters[filter] = (Number(value) as unknown) as LocationQueryValue
    }

    return {
      ...getTableProperties('id'),
      selected: [],
      tab: 'all',
      filters: {
        ...getDefaultFilters(),
        ...queryFilters,
      },
      campaigns: [],
      id: null,
      comment: null,
      showEditNoteDialog: false,
      campaignStatuses: CampaignStatus,
    }
  },
  watch: {
    async filters() {
      this.pagination.page = 1
      this.changeTab()
      await this.onRequest({ pagination: this.pagination })
    },
    async tab() {
      this.pagination.page = 1
      this.changeTab()
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    changeTab() {
      switch (this.tab) {
        case 'all':
          delete this.filters.isRemoved
          delete this.filters.department
          break
        case 'fb':
          delete this.filters.isRemoved
          this.filters.department = CampaignDepartment.FB
          break
        case 'uac':
          delete this.filters.isRemoved
          this.filters.department = CampaignDepartment.GOOGLE
          break
        case 'tt':
          delete this.filters.isRemoved
          this.filters.department = CampaignDepartment.TIKTOK
          break
        case 'ppc':
          delete this.filters.isRemoved
          this.filters.department = CampaignDepartment.PPC
          break
        case 'organic':
          delete this.filters.isRemoved
          this.filters.department = CampaignDepartment.ORGANIC
          break
        case 'aso':
          delete this.filters.isRemoved
          this.filters.department = CampaignDepartment.ASO
          break
        case 'other':
          delete this.filters.isRemoved
          this.filters.department = CampaignDepartment.OTHER
          break
        case 'deleted':
          delete this.filters.department
          this.filters.isRemoved = true
      }
    },
    async changeStatus(row: any) {
      this.loading = true
      try {
        await axios.post(`/api/campaigns/status?id=` + row.id, {
          status:
            row.status === CampaignStatus.Stopped
              ? CampaignStatus.Active
              : CampaignStatus.Stopped,
        })
        await this.onRequest({ pagination: this.pagination })
        if (row.status === CampaignStatus.Active) {
          showSuccessSnackBar(this.$t('pages.campaighn.alerts.stop'))
        } else {
          showSuccessSnackBar(this.$t('pages.campaighn.alerts.active'))
        }
      } catch (err) {
        showErrorMessageSnackBar(this.$t('pages.campaighn.alerts.refresh'))
        await this.onRequest({ pagination: this.pagination })
      }
      this.loading = false
    },
    async note() {
      this.id = (this.selected[0] as any).id as never
      this.comment = (this.selected[0] as any).comment as never
      this.showEditNoteDialog = !this.showEditNoteDialog
    },
    async clone() {
      this.loading = true
      try {
        await axios.post(
          `/api/campaigns/clone?id=` + (this.selected[0] as any).id,
        )
        await this.onRequest({ pagination: this.pagination })
        showSuccessSnackBar(this.$t('pages.campaighn.alerts.clone'))
      } catch (err) {
        showErrorMessageSnackBar(this.$t('pages.campaighn.alerts.cloneError'))
        await this.onRequest({ pagination: this.pagination })
      }
      this.loading = false
    },
    async deleteRow() {
      confirmDialog(
        `${this.$t('pages.campaighn.alerts.delete')} ${
          (this.selected[0] as any).name
        }?`,
        async () => {
          this.loading = true
          await axios.delete(`/api/campaigns`, {
            params: { id: (this.selected[0] as any).id },
          })
          this.selected = []
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      this.selected = []
      const options = { pagination: this.pagination, ...props }
      await request(
        options,
        this as Table,
        `/api/campaigns/${this.isMB ? 'buying' : ''}`,
      )
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters(status?: number) {
      this.pagination.page = 1
      this.filters = {
        ...getDefaultFilters(true),
        status: status !== undefined ? status : this.filters.status,
      }
    },
    copyUrl(text: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar(this.$t('pages.campaighn.alerts.copy'))
    },
  },
})
