
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import { getDefaultFilters, getTableProperties } from '@/utils/request'
import { getAffiliatePostbackColumns } from './columns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { FiltersType } from '@/utils/request'
export default defineComponent({
  name: 'AffiliateLogs',
  components: { Filters },
  mounted() {
    this.onRequest({ pagination: this.pagination })
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      filters: getDefaultFilters(),
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/logs/postbacks')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters(true)
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
  },
  computed: {
    createColumns() {
      return getAffiliatePostbackColumns()
    },
  },
})
