
import { defineComponent } from 'vue'
import axios from 'axios'
import { Promo, Reward } from '@/store/types'
import { MarkupType } from '@/enums/MarkupType'
import { Vertical } from '@/enums/Vertical'
import { Currency } from '@/enums/Currency'
import { RewardType } from '@/enums/RewardType'
import { AffiliateAccessStatus } from '@/enums/AffiliateAccessStatus'
import { downloadPromo } from '@/utils'
import { mapState } from 'vuex'
import TitleDivider from '@/components/sharable/TitleDivider.vue'

export default defineComponent({
  name: 'Offer',
  components: { TitleDivider },
  computed: {
    ...mapState('authModule', ['isMB']),
    promos(): Promo[] {
      return this.offer.promos.filter((p: Promo) => p.showForOffers)
    },
  },
  async created() {
    await this.getOffer()
    this.loading = false
  },
  data: function() {
    return {
      storageUrl: process.env.CDN_STORAGE_URL,
      offer: { id: '', image: '', description: '', name: '' },
      statistic: {
        geo: '',
        type: '',
        percent: '',
      },
      loading: true,
      markupTypes: MarkupType,
      currencies: Currency,
      rewardTypes: RewardType,
      verticals: Vertical,
    }
  },
  methods: {
    customMethod(offer) {
      const uniqueGeos = {}
      const uniqueRewardType = {}
      const uniquePayment = {}

      const rewards = [...offer.commonRewards, ...offer.affiliateRewards]

      rewards.forEach(reward => {
        uniqueRewardType[this.rewardTypes[reward.type]] = this.rewardTypes[
          reward.type
        ]

        const percent =
          reward.type !== this.rewardTypes.RevShare
            ? reward.amount + ' ' + this.currencies[reward.currency]
            : reward.percent + '%'

        uniquePayment[percent] = percent

        reward.geos.forEach(geo => {
          uniqueGeos[geo.isoCode] = geo.isoCode
        })
      })

      return {
        geo: Object.keys(uniqueGeos).join(', '),
        type: Object.keys(uniqueRewardType).join(', '),
        percent: Object.keys(uniquePayment).join(', '),
      }
    },
    async getAccess() {
      await axios.get('/api/offers/access/' + this.offer.id)
      await this.getOffer()
    },
    async getOffer() {
      const { data } = await axios.get(
        '/api/offers/offer/' + this.$route.params.id,
      )

      this.statistic = this.customMethod(data)
      this.offer = data
    },
    hasAccess(status: AffiliateAccessStatus) {
      return status === AffiliateAccessStatus.Opened
    },
    getGeos(reward: Reward) {
      return reward.geos.map(geo => geo.isoCode.toUpperCase()).join(', ')
    },
    hasOfferAccess() {
      return !(
        !this.offer.offersAccess ||
        !((this.offer.offersAccess as unknown) as {
          status: AffiliateAccessStatus
        }).status
      )
    },
    landingRedirect(landingUrl: string) {
      // if (!this.hasOfferAccess()) {
      //   return
      // }
      window.open(landingUrl, '_blank')
    },
    async download(id: string) {
      if (!this.hasOfferAccess()) {
        return
      }
      await downloadPromo(id)
    },
  },
})
