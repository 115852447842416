import { formatDate } from '@/utils'
import { isDefined, RowValue } from '@/utils'
import { formatValue } from '@/utils'
import { useI18n } from 'vue-i18n'

export function getColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'status',
      label: '',
      field: 'status',
      align: 'center',
    },
    {
      name: 'id',
      label: 'ID',
      field: 'id',
      align: 'center',
      sortable: true,
    },
    {
      name: 'createdAt',
      label: t('tables.campaighns.date'),
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'name',
      label: t('tables.campaighns.name'),
      field: 'name',
      align: 'center',
      sortable: true,
    },
    {
      name: 'offerName',
      label: t('tables.campaighns.offer'),
      field: 'offerName',
      align: 'center',
    },
    {
      name: 'geo',
      label: t('tables.campaighns.geo'),
      field: 'geo',
      align: 'center',
    },
    {
      name: 'trafficSource',
      label: t('tables.campaighns.source'),
      field: 'trafficSource',
      align: 'center',
    },
    {
      name: 'url',
      label: t('tables.campaighns.link'),
      field: 'url',
      align: 'center',
    },
    {
      name: 'actions',
      label: t('tables.campaighns.actions'),
      field: 'actions',
      align: 'center',
    },
  ]
}

export function getBuyingCampaignColumns() {
  const { t } = useI18n()

  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'name',
      label: t('tables.campaighns.name'),
      field: 'name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'status',
      label: t('tables.campaighns.status'),
      field: 'status',
      align: 'center',
    },
    {
      name: 'createdAt',
      label: t('tables.campaighns.date1'),
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'groupName',
      label: t('tables.campaighns.group'),
      field: 'groupName',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'trafficSourceName',
      label: t('tables.campaighns.source1'),
      field: 'trafficSourceName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'clicksCount',
      label: t('tables.campaighns.unik'),
      field: 'clicksCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'regsCount',
      label: t('tables.campaighns.reg'),
      field: 'regsCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'depsCount',
      label: t('tables.campaighns.dep'),
      field: 'depsCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'profit',
      label: t('tables.campaighns.prof'),
      field: 'profit',
      align: 'right',
      format: (val: RowValue) => (val ? `$${formatValue(val, 2, true)}` : '—'),
    },
    {
      name: 'approvePercent',
      label: t('tables.campaighns.regdep'),
      field: 'approvePercent',
      align: 'right',
      format: (val: RowValue) => (val ? `${formatValue(val)}%` : '—'),
    },
    // {
    //   name: 'rejectPercent',
    //   label: 'Реджект, %',
    //   field: 'rejectPercent',
    //   align: 'right',
    //   format: (val: RowValue) => val ? `${formatValue(val)}%` : '—',
    // },
    {
      name: 'actions',
      label: t('tables.campaighns.actions'),
      field: 'actions',
      align: 'center',
    },
  ]
}
