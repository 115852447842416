import { useI18n } from 'vue-i18n'

export default function getColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'id',
      label: t('tables.referrals.id'),
      field: 'id',
      align: 'center',
      sortable: true,
    },
    {
      name: 'createdAt',
      label: t('tables.referrals.date'),
      field: 'createdAt',
      align: 'center',
      sortable: true,
    },
  ]
}
