
import { defineComponent } from 'vue'
import { Currency } from '@/enums/Currency'
import { PaymentSystem } from '@/enums/PaymentSystem'
import AddPaymentSystemDialog from '@/components/dialogs/AddPaymentSystemDialog.vue'
import axios from 'axios'

export default defineComponent({
  name: 'PaymentSystemAccounts',
  components: { AddPaymentSystemDialog },
  async created() {
    await this.onRequest()
  },
  data: function() {
    return {
      currencies: Currency,
      paymentSystems: PaymentSystem,
      showAddPaymentSystemDialog: false,
      paymentAccounts: [],
    }
  },
  methods: {
    async onRequest() {
      this.paymentAccounts = (
        await axios.get('/api/payout-system-accounts/')
      ).data
    },
    async deleteRow(id: number) {
      await axios.delete('/api/payout-system-accounts/', {
        params: { id },
      })
      await this.onRequest()
    },
  },
})
