
import { defineComponent } from 'vue'

interface ExpansionListItem {
  title: string
  text: string
  numbered?: boolean
}

export default defineComponent({
  name: 'ExpansionList',
  props: {
    items: {
      type: Array as () => ExpansionListItem[],
      required: true,
    },
  },
  methods: {
    displayLeadingZero(index: number) {
      const value = index + 1
      return value < 10 ? '0' + value : value
    },
    showCurrentSlidesWithTranslation() {
      return this.$props.items
    },
  },
})
