import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5535c2b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex user-info", { [_ctx.className]: !!_ctx.className }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/profile')))
  }, [
    _createVNode(_component_q_btn, {
      "no-caps": "",
      flat: "",
      round: "",
      class: "profile-btn q-mr-sm",
      icon: _ctx.image,
      color: "white"
    }, null, 8, ["icon"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.email) + " (ID: " + _toDisplayString(_ctx.id) + ")", 1)
    ])
  ], 2))
}