import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_ConversionsTable = _resolveComponent("ConversionsTable")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "dashboard__page" }, {
    default: _withCtx(() => [
      _createVNode(_component_Filters, {
        onReset: _ctx.resetFilters,
        onChange: _ctx.onChangeFilters,
        onChangeAll: _ctx.changeAll,
        onExport: _ctx.onExport,
        "current-filters": _ctx.filters
      }, null, 8, ["onReset", "onChange", "onChangeAll", "onExport", "current-filters"]),
      _createElementVNode("div", null, [
        _createVNode(_component_ConversionsTable, {
          filters: _ctx.filters,
          tab: _ctx.statsTab,
          exportCsv: _ctx.exportCsv
        }, null, 8, ["filters", "tab", "exportCsv"])
      ])
    ]),
    _: 1
  }))
}