export const addMetaToThePage = (title: string, description: string) => {
  window.document.title = title

  const existingDescription = document.querySelector('meta[name="description"]')

  if (existingDescription) {
    existingDescription.setAttribute('content', description as string)
  } else {
    const head = document.querySelector('head')
    const meta = document.createElement('meta')
    meta.setAttribute('name', 'description')
    meta.setAttribute('content', description as string)
    head?.prepend(meta)
  }

  const lang = localStorage.getItem('lang') || 'ru'
  document.querySelector('html')?.setAttribute('lang', lang)
}
