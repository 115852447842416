import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "table__wrapper" }
const _hoisted_2 = { class: "text-white" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["alt", "src"]
const _hoisted_6 = { style: {"margin-left":"8px"} }
const _hoisted_7 = ["alt", "src"]
const _hoisted_8 = { style: {"margin-left":"8px"} }
const _hoisted_9 = {
  class: "flex row items-center",
  style: {"width":"max-content"}
}
const _hoisted_10 = ["alt", "src"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "dashboard__page" }, {
    default: _withCtx(() => [
      _createVNode(_component_Filters, {
        onReset: _ctx.resetFilters,
        onChange: _ctx.onChangeFilters,
        onChangeAll: _ctx.changeAll,
        "current-filters": _ctx.filters
      }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_table, {
          rows: _ctx.rows,
          columns: _ctx.createColumns,
          "row-key": "id",
          dark: "",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          onRowClick: _ctx.onRowClick,
          "binary-state-sort": "",
          "column-sort-order": "da",
          "table-class": "offer__table",
          "table-header-class": "table__header",
          "card-container-class": "offer__table-card"
        }, {
          loading: _withCtx(() => [
            _createVNode(_component_q_inner_loading, {
              showing: "",
              color: "white",
              style: {"background":"#4c3d3d45"}
            })
          ]),
          "body-cell-name": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  class: "flex column items-center justify-center",
                  style: {"text-decoration":"none !important"},
                  to: { name: 'offer', params: { id: props.row.id } }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(props.row.name), 1),
                    (!_ctx.isMB)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          width: "100",
                          height: "50",
                          src: `${_ctx.storageUrl + '/' + props.row.image}`
                        }, null, 8, _hoisted_3))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-amount": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getRewardAmounts([
                ...props.row.commonRewards,
                ...props.row.affiliateRewards,
              ])) + " ", 1),
                (
                [...props.row.commonRewards, ...props.row.affiliateRewards]
                  .length > 1
              )
                  ? (_openBlock(), _createElementBlock("sup", _hoisted_4, "?"))
                  : _createCommentVNode("", true),
                (
                [...props.row.commonRewards, ...props.row.affiliateRewards]
                  .length > 1
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 1,
                      anchor: "top right",
                      self: "top right",
                      class: "q-tooltip custom-tooltip"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getRewards([
                  ...props.row.commonRewards,
                  ...props.row.affiliateRewards,
                ])), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-geo": _withCtx((props) => [
            _createVNode(_component_q_td, {
              class: "table__offers-geo",
              props: props
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGeos(props.row, true), (geo, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("span", null, [
                      _createElementVNode("img", {
                        height: "10",
                        width: "15",
                        alt: props.row.geo,
                        src: _ctx.getGeoImgSrc(geo.isoCode)
                      }, null, 8, _hoisted_5)
                    ]),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(geo.name), 1)
                  ]))
                }), 128)),
                (_ctx.getGeos(props.row).length > 3)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      onClick: _withModifiers(($event: any) => (true), ["stop"]),
                      size: "sm",
                      flat: "",
                      "no-caps": "",
                      push: "",
                      label: "Показать еще",
                      style: {"text-decoration-line":"underline","color":"#edb94a","margin-left":"-16px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_popup_proxy, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_banner, null, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGeos(props.row), (geo, key) => {
                                  return (_openBlock(), _createElementBlock("div", { key: key }, [
                                    _createElementVNode("span", null, [
                                      (geo.isoCode)
                                        ? (_openBlock(), _createElementBlock("img", {
                                            key: 0,
                                            class: "q-mr-sm",
                                            height: "10",
                                            width: "15",
                                            alt: props.row.geo,
                                            src: _ctx.getGeoImgSrc(geo.isoCode)
                                          }, null, 8, _hoisted_7))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(geo.name), 1)
                                  ]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-rewards": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.rewards, (r, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(r.geos, (code, geoIdx) => {
                      return (_openBlock(), _createElementBlock("div", { key: geoIdx }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("img", {
                            style: {"user-select":"none"},
                            class: "q-mr-sm",
                            height: "10",
                            width: "15",
                            alt: code?.isoCode,
                            src: 
                      `http://purecatamphetamine.github.io/country-flag-icons/3x2/${code?.isoCode}.svg`
                    
                          }, null, 8, _hoisted_10),
                          _createTextVNode(" " + _toDisplayString(r?.geos[geoIdx]?.name) + " - " + _toDisplayString(`${r?.amount} ${_ctx.currencies[r?.currency]}`), 1)
                        ])
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-rewardType": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRewardTypes(props.row), (type, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("span", { innerHTML: type }, null, 8, _hoisted_11)
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-actions": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (
                _ctx.hasAccess(
                  props.row.offersAccess && props.row.offersAccess.status,
                )
              )
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      "no-caps": "",
                      outline: "",
                      rounded: "",
                      class: "offer__table-access",
                      color: "primary",
                      label: _ctx.$t('pages.offers.create'),
                      onClick: _withModifiers(($event: any) => (
                _ctx.isMB
                  ? _ctx.$router.push('/campaigns')
                  : _ctx.$router.push(`/campaigns?offerId=${props.row.id}`)
              ), ["stop"])
                    }, null, 8, ["label", "onClick"]))
                  : (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      "no-caps": "",
                      outline: "",
                      rounded: "",
                      color: "primary",
                      class: "offer__table-access",
                      disable: !!props.row.offersAccess,
                      label: 
                props.row.offersAccess
                  ? _ctx.$t('pages.offers.asked')
                  : _ctx.$t('pages.offers.ask')
              ,
                      onClick: _withModifiers(($event: any) => (_ctx.getAccess(props.row.id)), ["stop"])
                    }, null, 8, ["disable", "label", "onClick"]))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest", "onRowClick"])
      ])
    ]),
    _: 1
  }))
}