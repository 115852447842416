import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3048986"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "password__block" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('pages.passwords.title')), 1),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          class: "width-300",
          type: _ctx.hidePassword ? 'password' : 'text',
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          error: !!_ctx.errors?.message,
          "error-message": _ctx.errors?.message
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: _ctx.hidePassword ? 'visibility_off' : 'visibility',
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hidePassword = !_ctx.hidePassword))
            }, null, 8, ["name"])
          ]),
          _: 1
        }, 8, ["type", "modelValue", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('pages.passwords.subTitle')), 1),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          class: "width-300",
          type: _ctx.hideNewPassword ? 'password' : 'text',
          modelValue: _ctx.newPassword,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newPassword) = $event)),
          error: !!_ctx.errors?.message,
          "error-message": _ctx.errors?.message
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: _ctx.hideNewPassword ? 'visibility_off' : 'visibility',
              class: "cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hideNewPassword = !_ctx.hideNewPassword))
            }, null, 8, ["name"])
          ]),
          _: 1
        }, 8, ["type", "modelValue", "error", "error-message"])
      ]),
      _createVNode(_component_q_btn, {
        "no-caps": "",
        label: _ctx.$t('btn.save'),
        type: "submit",
        color: "primary",
        outline: "",
        onClick: _ctx.changePassword,
        style: {"margin-top":"15px","width":"100%","border-color":"#EDB94A","padding-top":"12px","padding-bottom":"12px"},
        class: "balances__btn profile__btn"
      }, null, 8, ["label", "onClick"])
    ])
  ]))
}