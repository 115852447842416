import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-xl" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('pages.referrals.title')) + ":", 1),
        _createVNode(_component_q_input, {
          class: "col self-center q-mx-lg",
          outlined: "",
          modelValue: _ctx.referralLink,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.referralLink) = $event)),
          readonly: ""
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t('pages.referrals.subTitle')) + ":", 1),
      _createVNode(_component_q_table, {
        rows: _ctx.rows,
        columns: _ctx.createColumns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": ""
      }, {
        "body-cell-id": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.id), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-createdAt": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.createdAt), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ]),
    _: 1
  }))
}