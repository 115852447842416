
import { defineComponent, ref } from 'vue'

interface NavLink {
  label: string
  path: string
  isHome?: boolean
  hash?: string
}
export default defineComponent({
  name: 'MainNavigation',
  setup() {
    const isHovered = ref('')
    return {
      isHovered,
    }
  },
  methods: {
    getNavLinks() {
      return [
        {
          label: this.$t('navigation.partner'),
          path: '/',
          name: 'contacts',
        },
        {
          label: this.$t('navigation.arbitrary'),
          path: '/media-buying',
          name: 'media buying',
        },
        {
          label: this.$t('navigation.career'),
          path: '/career',
          name: 'career',
        },
        // {
        //   label: this.$t('landing.nav.1.label'),
        //   path: '/media',
        //   name: 'media',
        // },
      ]
    },
    isLinkActive(link: NavLink): boolean {
      let isLinkActive = link.path === this.$route.path

      if (this.isHovered === link.label) isLinkActive = true

      if (link.hash && link.hash !== this.$route.hash) {
        isLinkActive = false
      }

      if (this.$route.hash && link.hash !== this.$route.hash) {
        isLinkActive = false
      }

      return isLinkActive
    },
  },
})
