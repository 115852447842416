import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "waiting__title" }
const _hoisted_2 = { class: "waiting__btn_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleDivider = _resolveComponent("TitleDivider")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, {
    style: {"padding-top":"0px","background-color":"#151A27"},
    class: "dashboard__page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TitleDivider, { title: "спасибо за регистрацию" }),
      _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.$t('dialogs.waitingApprove.title')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_btn, {
          to: "/",
          target: "_blank",
          "no-caps": "",
          "text-color": "dark",
          color: "primary",
          label: _ctx.$t('dialogs.waitingApprove.back'),
          class: "waiting__btn"
        }, null, 8, ["label"])
      ])
    ]),
    _: 1
  }))
}