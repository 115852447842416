
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { locale, availableLocales } = useI18n({ useScope: 'global' })

    return {
      locale,
      localeOptions: availableLocales,
    }
  },
  methods: {
    switchLanguage(lang: 'ru' | 'en' | 'ua') {
      localStorage.setItem('lang', lang)
      document.querySelector('html')?.setAttribute('lang', lang)
    },
  },
}
