export enum StreamFilterOption {
  Geo,
  Browser,
  OperatingSystem,
  DeviceType,
  QueryParameter,
  Bot,
  SUB1,
  SUB2,
  SUB3,
  SUB4,
  SUB5,
  SUB6,
  SUB7,
  SUB8,
  SUB9,
  SUB10,
  SUB11,
  SUB12,
  SUB13,
  SUB14,
  SUB15,
}
