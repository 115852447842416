import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ba2454f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "geoItem" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "geoItem__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", { src: _ctx.img }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ]))
}