
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import TitleDivider from '../sharable/TitleDivider.vue'

export default defineComponent({
  name: 'WaitingApprove',
  components: { TitleDivider },
  computed: mapState('authModule', ['user']),
  async mounted() {
    await this.checkIsApproved()
  },
  methods: {
    ...mapActions('authModule', ['checkIsApproved']),
  },
})
