import { formatDate, isDefined, RowValue } from '@/utils'
import { useI18n } from 'vue-i18n'

export default function getColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'createdAt',
      label: t('tables.postbacks.date'),
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'url', label: 'Ссылка', field: 'url', align: 'center' },
    {
      name: 'eventType',
      label: t('tables.postbacks.event'),
      field: 'eventType',
      align: 'center',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: t('tables.postbacks.campaighn'),
      field: 'campaignName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'status',
      label: t('tables.postbacks.status'),
      field: 'status',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
  ]
}
