export enum OperatingSystem {
  AIX,
  AmigaOS,
  Android,
  Arch,
  Bada,
  BeOS,
  BlackBerry,
  CentOS,
  ChromiumOS,
  Contiki,
  Fedora,
  FirefoxOS,
  FreeBSD,
  Debian,
  Deepin,
  DragonFly,
  elementaryOS,
  Fuchsia,
  Gentoo,
  GhostBSD,
  GNU,
  Haiku,
  HPUX,
  Hurd,
  iOS,
  Joli,
  KaiOS,
  Linpus,
  Linspire,
  Linux,
  MacOS,
  Maemo,
  Mageia,
  Mandriva,
  Manjaro,
  MeeGo,
  Minix,
  Mint,
  MorphOS,
  NetBSD,
  Nintendo,
  OpenBSD,
  OpenVMS,
  OS2,
  Palm,
  PCBSD,
  PCLinuxOS,
  Plan9,
  PlayStation,
  QNX,
  Raspbian,
  RedHat,
  RIMTabletOS,
  RISCOS,
  Sabayon,
  Sailfish,
  Series40,
  Slackware,
  Solaris,
  SUSE,
  Symbian,
  Tizen,
  Ubuntu,
  Unix,
  VectorLinux,
  WebOS,
  Windows,
  Zenwalk,
}
