
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Button from '@/components/sharable/Button.vue'

export default defineComponent({
  name: 'SignUp',
  computed: mapState('authModule', ['passwordResetError']),
  components: { Button },
  created: function() {
    this.resetErrors()
  },
  data: function() {
    return {
      newPassword: null,
      loading: false,
    }
  },
  methods: {
    ...mapActions('authModule', ['finishPasswordReset', 'login']),
    ...mapMutations('authModule', ['resetErrors']),
    async submit() {
      this.loading = true
      try {
        await this.finishPasswordReset({
          newPassword: this.newPassword,
          key: this.$route.params.key,
        })
      } finally {
        this.loading = false
      }
    },
  },
})
