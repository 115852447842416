import {
  CampaignData,
  Offer,
  Promo,
  RedirectDomain,
  TrafficSource,
  Geo,
} from '@/store/types'
import axios from 'axios'
import { RotationData } from '@/store/types'

export interface DefaultOptions {
  promos: Promo[]
  campaigns: CampaignData[]
  trafficSources: TrafficSource[]
  redirectDomains: RedirectDomain[]
  offers: Offer[]
  geos: Geo[]
  groups: []
  landings: []
  rotations: RotationData[]
}

export async function setOptionsByOfferId(
  campaign: CampaignData,
  options: DefaultOptions,
) {
  const promos = (
    await axios.get('/api/promos', { params: { offerId: campaign.offerId } })
  ).data
  options.promos = promos.map((promo: Promo) => ({
    ...promo,
    isActive: campaign.targetPromoIds.includes(promo.id),
  }))
  options.geos = (
    await axios.get('/api/geos', { params: { offerId: campaign.offerId } })
  ).data
  return options
}

export async function getInitialOptions(
  campaign: CampaignData,
  isMB: boolean,
): Promise<DefaultOptions> {
  const defaultOptions: DefaultOptions = {
    promos: [],
    campaigns: [],
    trafficSources: [],
    redirectDomains: [],
    offers: [],
    geos: [],
    groups: [],
    landings: [],
    rotations: [],
  }

  defaultOptions.geos = (await axios.get('/api/geos')).data
  defaultOptions.promos = (await axios.get('/api/promos/offer')).data

  defaultOptions.redirectDomains = (
    await axios.get('/api/redirect-domains')
  ).data

  defaultOptions.offers = (await axios.get('/api/offers/list/')).data

  defaultOptions.rotations = (await axios.get('/api/rotations')).data

  defaultOptions.landings = (await axios.get('/api/promos/buying')).data

  defaultOptions.trafficSources = (await axios.get('/api/traffic-sources')).data

  if (campaign.offerId) {
    await setOptionsByOfferId(campaign, defaultOptions)
  }

  if (isMB) {
    defaultOptions.campaigns = (await axios.get('/api/campaigns/list')).data
  }

  defaultOptions.groups = (await axios.get('/api/groups/campaign')).data

  return defaultOptions
}
