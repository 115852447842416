import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50a7afb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "streams__title" }
const _hoisted_2 = { class: "streams__name" }
const _hoisted_3 = { class: "streams__filters-block" }
const _hoisted_4 = { key: 4 }
const _hoisted_5 = {
  class: "",
  style: {"margin-top":"6px","padding-bottom":"20px"}
}
const _hoisted_6 = { class: "streams__offers-block" }
const _hoisted_7 = { class: "row streams__offers-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_AddStreamRuleDialog = _resolveComponent("AddStreamRuleDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.$t('pages.campaighn.steps.streams.title')), 1),
      _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.streams.rotation')), 1),
      _createVNode(_component_q_select, {
        loading: _ctx.isChangingRotation,
        dense: "",
        clearable: "",
        onClear: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeRotation(null))),
        "options-dense": "",
        "model-value": _ctx.campaign.rotationId,
        "onUpdate:modelValue": _ctx.changeRotation,
        outlined: "",
        options: _ctx.rotations,
        onFilter: _ctx.getRotations,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": ""
      }, null, 8, ["loading", "model-value", "onUpdate:modelValue", "options", "onFilter"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.streams, (stream, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _createVNode(_component_q_checkbox, {
            dark: "",
            label: _ctx.$t('pages.campaighn.steps.streams.label'),
            "model-value": stream.isDefault,
            "onUpdate:modelValue": 
          isDefault => _ctx.updateStream({ ...stream, isDefault }, index)
        
          }, null, 8, ["label", "model-value", "onUpdate:modelValue"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.streams.name')) + ":", 1),
            _createVNode(_component_q_input, {
              dense: "",
              outlined: "",
              "model-value": stream.name,
              "onUpdate:modelValue": name => _ctx.updateStream({ ...stream, name }, index),
              "error-message": _ctx.errors?.streams?.[index]?.name,
              error: !!_ctx.errors?.streams?.[index]?.name
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  onClick: ($event: any) => (_ctx.removeStream(index)),
                  style: {"margin-right":"-10px"},
                  "no-caps": "",
                  flat: "",
                  rounded: "",
                  size: "12px",
                  icon: "delete_outline"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["model-value", "onUpdate:modelValue", "error-message", "error"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.streams.filter')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stream.filters, (filter, filterInd) => {
              return (_openBlock(), _createElementBlock("div", {
                key: filterInd,
                class: "streams__filters"
              }, [
                _createVNode(_component_q_select, {
                  "options-dense": "",
                  outlined: "",
                  dense: "",
                  label: _ctx.$t('pages.campaighn.steps.streams.labelFilter'),
                  "label-color": "white",
                  "model-value": filter.option,
                  "onUpdate:modelValue": 
                option => _ctx.updateFilter({ ...filter, option }, filterInd, index)
              ,
                  options: _ctx.filterTypes,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  error: !!_ctx.errors?.filters,
                  "error-message": _ctx.errors?.filters
                }, null, 8, ["label", "model-value", "onUpdate:modelValue", "options", "error", "error-message"]),
                (filter.option === _ctx.filterTypesEnum.Geo)
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 0,
                      dense: "",
                      outlined: "",
                      multiple: "",
                      "use-chips": "",
                      label: _ctx.$t('pages.campaighn.steps.streams.labelCountry'),
                      "label-color": "white",
                      "model-value": filter.geoIds,
                      "onUpdate:modelValue": 
                geoIds => _ctx.updateFilter({ ...filter, geoIds }, filterInd, index)
              ,
                      onFilter: _ctx.getGeos,
                      options: _ctx.geos,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      "use-input": "",
                      error: !!_ctx.errors?.filters,
                      "error-message": _ctx.errors?.filters
                    }, null, 8, ["label", "model-value", "onUpdate:modelValue", "onFilter", "options", "error", "error-message"]))
                  : _createCommentVNode("", true),
                (filter.option === _ctx.filterTypesEnum.DeviceType)
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 1,
                      dense: "",
                      "options-dense": "",
                      outlined: "",
                      label: _ctx.$t('pages.campaighn.steps.streams.labelType'),
                      "label-color": "white",
                      "model-value": filter.deviceType,
                      "onUpdate:modelValue": 
                deviceType =>
                  _ctx.updateFilter({ ...filter, deviceType }, filterInd, index)
              ,
                      options: _ctx.devices,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      error: !!_ctx.errors?.filters,
                      "error-message": _ctx.errors?.filters
                    }, null, 8, ["label", "model-value", "onUpdate:modelValue", "options", "error", "error-message"]))
                  : _createCommentVNode("", true),
                (filter.option === _ctx.filterTypesEnum.Browser)
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 2,
                      dense: "",
                      "options-dense": "",
                      outlined: "",
                      label: _ctx.$t('pages.campaighn.steps.streams.labelBrowser'),
                      "label-color": "white",
                      "model-value": filter.browser,
                      "onUpdate:modelValue": 
                browser =>
                  _ctx.updateFilter({ ...filter, browser }, filterInd, index)
              ,
                      options: _ctx.browsers,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      error: !!_ctx.errors?.filters,
                      "error-message": _ctx.errors?.filters
                    }, null, 8, ["label", "model-value", "onUpdate:modelValue", "options", "error", "error-message"]))
                  : _createCommentVNode("", true),
                (filter.option === _ctx.filterTypesEnum.OperatingSystem)
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 3,
                      dense: "",
                      "options-dense": "",
                      outlined: "",
                      label: _ctx.$t('pages.campaighn.steps.streams.labelOperationSys'),
                      "label-color": "white",
                      "model-value": filter.os,
                      "onUpdate:modelValue": 
                oss => _ctx.updateFilter({ ...filter, os: oss }, filterInd, index)
              ,
                      options: _ctx.oses,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      error: !!_ctx.errors?.filters,
                      "error-message": _ctx.errors?.filters
                    }, null, 8, ["label", "model-value", "onUpdate:modelValue", "options", "error", "error-message"]))
                  : _createCommentVNode("", true),
                (filter.option === _ctx.filterTypesEnum.Bot)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4))
                  : _createCommentVNode("", true),
                (
                filter.option !== null &&
                  filter.option !== undefined &&
                  filter.option !== _ctx.filterTypesEnum.Bot &&
                  filter.option !== _ctx.filterTypesEnum.OperatingSystem &&
                  filter.option !== _ctx.filterTypesEnum.DeviceType &&
                  filter.option !== _ctx.filterTypesEnum.OperatingSystem &&
                  filter.option !== _ctx.filterTypesEnum.Geo &&
                  filter.option !== _ctx.filterTypesEnum.Browser
              )
                  ? (_openBlock(), _createBlock(_component_q_input, {
                      key: 5,
                      dense: "",
                      outlined: "",
                      label: _ctx.$t('pages.campaighn.steps.streams.labelValue'),
                      "label-color": "white",
                      "model-value": filter.value,
                      "onUpdate:modelValue": 
                value => _ctx.updateFilter({ ...filter, value }, filterInd, index)
              ,
                      error: !!_ctx.errors?.filters,
                      "error-message": _ctx.errors?.filters
                    }, null, 8, ["label", "model-value", "onUpdate:modelValue", "error", "error-message"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_component_q_btn_toggle, {
                    "model-value": filter.isEqual,
                    "onUpdate:modelValue": 
                  isEqual =>
                    _ctx.updateFilter({ ...filter, isEqual }, filterInd, index)
                ,
                    size: "md",
                    rounded: "",
                    unelevated: "",
                    "toggle-color": 
                  filter.isEqual === false ? 'negative' : 'positive'
                ,
                    color: "grey",
                    "text-color": "dark",
                    "toggle-text-color": "white",
                    options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                  }, null, 8, ["model-value", "onUpdate:modelValue", "toggle-color"]),
                  _createVNode(_component_q_btn, {
                    onClick: ($event: any) => (_ctx.removeFilter(filterInd, index)),
                    rounded: "",
                    flat: "",
                    size: "sm",
                    color: "negative",
                    icon: "delete"
                  }, null, 8, ["onClick"])
                ])
              ]))
            }), 128)),
            _createElementVNode("div", null, [
              _createVNode(_component_q_btn, {
                onClick: ($event: any) => (_ctx.addFilterCriteria(index)),
                label: _ctx.$t('pages.campaighn.steps.streams.filterBtn'),
                color: "primary",
                outline: "",
                icon: "add",
                class: "white-btn",
                style: {"width":"100%","margin-top":"20px"}
              }, null, 8, ["onClick", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.streams.offers')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stream.offerRules, (offer, offerInd) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "streams__offers",
                key: offerInd
              }, [
                (offer.isCampaignRule || offer.campaignId)
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 0,
                      dense: "",
                      clearable: "",
                      "options-dense": "",
                      "model-value": offer.campaignId,
                      "onUpdate:modelValue": 
              campaignId =>
                _ctx.updateOfferRule({ ...offer, campaignId }, offerInd, index)
            ,
                      outlined: "",
                      "label-color": "white",
                      options: _ctx.campaigns,
                      onFilter: _ctx.getCampaigns,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      "use-input": "",
                      label: _ctx.$t('pages.campaighn.steps.streams.campaighn')
                    }, null, 8, ["model-value", "onUpdate:modelValue", "options", "onFilter", "label"]))
                  : _createCommentVNode("", true),
                (offer.isCampaignRule === false || offer.offerId)
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 1,
                      dense: "",
                      "options-dense": "",
                      "model-value": offer.offerId,
                      "onUpdate:modelValue": 
              offerId => _ctx.updateOfferRule({ ...offer, offerId }, offerInd, index)
            ,
                      outlined: "",
                      "label-color": "white",
                      options: _ctx.offers,
                      onFilter: _ctx.getOffers,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      "use-input": "",
                      label: _ctx.$t('pages.campaighn.steps.streams.offer')
                    }, null, 8, ["model-value", "onUpdate:modelValue", "options", "onFilter", "label"]))
                  : _createCommentVNode("", true),
                (offer.isCampaignRule === false || offer.offerId)
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 2,
                      dense: "",
                      disable: !offer.offerId,
                      "options-dense": "",
                      "model-value": offer.promoId,
                      "onUpdate:modelValue": 
              promoId => _ctx.updateOfferRule({ ...offer, promoId }, offerInd, index)
            ,
                      outlined: "",
                      "label-color": "white",
                      options: _ctx.promos,
                      onFilter: 
              (value, updateFn) => _ctx.getPromos(value, updateFn, offer.offerId)
            ,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      "use-input": "",
                      label: _ctx.$t('pages.campaighn.steps.streams.promo')
                    }, null, 8, ["disable", "model-value", "onUpdate:modelValue", "options", "onFilter", "label"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_input, {
                  "label-color": "white",
                  dense: "",
                  outlined: "",
                  max: "100",
                  min: "0",
                  type: "number",
                  label: _ctx.$t('pages.campaighn.steps.streams.weight'),
                  "model-value": offer.percent,
                  "onUpdate:modelValue": 
              percent =>
                _ctx.updateOfferRule(
                  { ...offer, percent: Number(percent) },
                  offerInd,
                  index,
                )
            
                }, null, 8, ["label", "model-value", "onUpdate:modelValue"]),
                _createElementVNode("span", null, [
                  _createVNode(_component_q_checkbox, {
                    dark: "",
                    label: _ctx.$t('pages.campaighn.steps.streams.switcher'),
                    "model-value": offer.isActive,
                    "onUpdate:modelValue": 
                isActive =>
                  _ctx.updateOfferRule({ ...offer, isActive }, offerInd, index)
              
                  }, null, 8, ["label", "model-value", "onUpdate:modelValue"]),
                  _createVNode(_component_q_btn, {
                    onClick: ($event: any) => (_ctx.removeOfferRule(offerInd, index)),
                    rounded: "",
                    flat: "",
                    size: "sm",
                    color: "negative",
                    icon: "delete"
                  }, null, 8, ["onClick"])
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_q_btn, {
              onClick: ($event: any) => (_ctx.addOfferRule(index)),
              label: _ctx.$t('pages.campaighn.steps.streams.offer'),
              color: "primary",
              class: "white-btn",
              outline: "",
              icon: "add"
            }, null, 8, ["onClick", "label"]),
            _createVNode(_component_q_btn, {
              onClick: ($event: any) => (_ctx.addOfferRule(index, true)),
              label: _ctx.$t('pages.campaighn.steps.streams.campaighn'),
              class: "white-btn",
              outline: "",
              color: "primary",
              icon: "add"
            }, null, 8, ["onClick", "label"])
          ])
        ], 64))
      }), 128))
    ]),
    _createVNode(_component_q_btn, {
      onClick: _ctx.addStream,
      label: _ctx.$t('pages.campaighn.steps.streams.rule'),
      class: "white-btn",
      style: {"width":"100%","margin-top":"20px"},
      color: "primary",
      outline: "",
      icon: "add"
    }, null, 8, ["onClick", "label"]),
    _createVNode(_component_AddStreamRuleDialog, {
      open: _ctx.showFiltersDialog,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFiltersDialog = false)),
      onRefresh: _ctx.onRefresh,
      streamId: _ctx.streamId
    }, null, 8, ["open", "onRefresh", "streamId"])
  ], 64))
}