import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-279d3fe5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header__right" }
const _hoisted_3 = { class: "header__actions-btn" }
const _hoisted_4 = { class: "close" }
const _hoisted_5 = { class: "switcher" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_MainNavigation = _resolveComponent("MainNavigation")!
  const _component_LanguageSwitcher = _resolveComponent("LanguageSwitcher")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_header, { class: "non-fixed-header" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_toolbar, null, {
            default: _withCtx(() => [
              (_ctx.$q.screen.width < 1006)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    round: "",
                    icon: "menu",
                    onClick: _ctx.toggleMenu
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.$q.screen.width >= 1006)
                ? (_openBlock(), _createBlock(_component_MainNavigation, { key: 1 }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLogin && _ctx.onLogin(...args))),
                    class: "header__login"
                  }, _toDisplayString(_ctx.$t('nav.login')), 1),
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onReg && _ctx.onReg(...args))),
                    class: "header__login"
                  }, _toDisplayString(_ctx.$t('nav.register')), 1)
                ]),
                _createVNode(_component_LanguageSwitcher)
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    (_ctx.$q.screen.width < 1006)
      ? (_openBlock(), _createBlock(_component_q_drawer, {
          key: 0,
          style: {"background-color":"#131212","padding":"25px 10px"},
          class: "mobile-menu",
          modelValue: _ctx.menuVisible,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.menuVisible) = $event)),
          side: "left",
          overlay: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_btn, {
                round: "",
                icon: "close",
                onClick: _ctx.toggleMenu
              }, null, 8, ["onClick"])
            ]),
            _createVNode(_component_MainNavigation),
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localeOptions, (link) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: link,
                  style: {"text-transform":"capitalize"},
                  class: _normalizeClass({ active: _ctx.$i18n.locale === link }),
                  onClick: ($event: any) => (_ctx.changeLang(link))
                }, _toDisplayString(link), 11, _hoisted_6))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}