import { useI18n } from 'vue-i18n'

export default function getColumns() {
  const { t } = useI18n()

  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'name',
      label: t('pages.instruction.link'),
      field: 'name',
      align: 'center',
      sortable: true,
    },
    // {
    //   name: 'status',
    //   label: 'Статус',
    //   field: 'status',
    //   align: 'center',
    //   sortable: true,
    // },
  ]
}
