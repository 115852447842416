
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import { BalanceType } from '@/enums/BalanceType'
import { defineComponent } from 'vue'
import { Balance } from '@/store/types'
import { Currency } from '@/enums/Currency'
import { FormatMoney } from 'format-money-js'
import Navigation from '@/components/navigation/Navigation.vue'
import MoneyAndUserInfo from './MoneyAndUserInfo.vue'

export default defineComponent({
  name: 'Header',
  components: { Navigation, MoneyAndUserInfo },
  computed: {
    ...mapState('authModule', ['user']),
    moneyIconPath(): string {
      return !this.$route.path.includes('/offers/')
        ? 'img:./images/money-icon.png'
        : 'img:../images/money-icon.png'
    },
    profileImagePath(): string {
      return this.affiliate.image
        ? `img:${this.storageUrl + '/' + this.affiliate.image}`
        : 'img:/images/account.svg'
    },
    approveBalanceBuying(): any {
      return this.moneyFormatter.from(
        Number(this.availableBalances[0].amount) +
          Number(this.holdBalances[0].amount),
        { symbol: '$' },
      )
    },
    approveBalance(): any {
      return this.moneyFormatter.from(
        Number(this.availableBalances[0].amount),
        { symbol: '$' },
      )
    },
    holdBalance(): any {
      return this.moneyFormatter.from(Number(this.holdBalances[0].amount), {
        symbol: '$',
      })
    },
    isApproved(): boolean {
      return this.user?.data?.isApproved
    },
  },
  async created(): Promise<void> {
    this.affiliate = (await axios.get('/api/profile')).data
    await this.loadBalances()
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      moneyFormatter,
      lang: 'RU',
      menuVisible: false,
      storageUrl: process.env.CDN_STORAGE_URL,
      affiliate: { id: '', image: '', credentials: { email: '' } },
      balanceTypes: BalanceType,
      currencies: Currency,
      availableBalances: [{ amount: 0, currency: Currency.USD }],
      holdBalances: [{ amount: 0, currency: Currency.USD }],
    }
  },
  methods: {
    ...mapActions('authModule', ['logout']),
    toggleMenu() {
      this.menuVisible = !this.menuVisible
      document.body.style.overflow = this.menuVisible ? 'hidden' : 'scroll'
    },
    async loadBalances() {
      const balances = (await axios.get('/api/balances')).data
      if (balances.length) {
        this.availableBalances = balances.filter(
          (balance: Balance) => balance.type === BalanceType.Available,
        )
        this.holdBalances = balances.filter(
          (balance: Balance) => balance.type === BalanceType.Hold,
        )
      }
    },
  },
})
