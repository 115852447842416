
import { defineComponent } from 'vue'
import { Vue3Marquee } from 'vue3-marquee'

export default defineComponent({
  name: 'Ticker',
  props: ['text', 'color'],
  components: {
    Vue3Marquee,
  },
})
