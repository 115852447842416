
import { defineComponent } from 'vue'
import GeneralInfo from '@/pages/campaigns/steps/GeneralInfo.vue'
import Promos from '@/pages/campaigns/steps/Promos.vue'
import Postbacks from '@/pages/campaigns/steps/Postbacks.vue'
import RedirectDomain from '@/pages/campaigns/steps/RedirectDomain.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import {
  getInitialOptions,
  setOptionsByOfferId,
  DefaultOptions,
} from '@/pages/campaigns/config/getInitialOptions'
import { Postback } from '@/store/types'
import { showErrorMessageSnackBar } from '@/utils'

export default defineComponent({
  name: 'AffiliateCampaignEdit',
  props: ['id', 'isNew'],
  components: { GeneralInfo, Promos, Postbacks, RedirectDomain },
  computed: {
    ...mapState('campaignsModule', ['errors', 'campaign']),
    ...mapState('authModule', ['user']),
  },
  async created() {
    if (!this.id) {
      this.onReset()
      if (this.$route.params.offerId) {
        this.update({ offerId: Number(this.$route.params.offerId) })
        await this.selectOffer()
      }
      this.ready = true
      return
    }

    await this.getData(this.id)
    this.defaultOptions = await getInitialOptions(this.campaign, false)
    this.ready = true
  },
  data() {
    return {
      defaultOptions: {},
      ready: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('campaignsModule', ['getData']),
    ...mapMutations('campaignsModule', ['update', 'reset', 'setErrors']),
    ...mapMutations('authModule', ['logout']),
    selectOffer: async function() {
      if (!this.campaign.offerId) {
        return
      }

      const options = await setOptionsByOfferId(
        this.campaign,
        this.defaultOptions as DefaultOptions,
      )
      this.defaultOptions = Object.assign({}, this.defaultOptions, options)
    },
    onSubmit: function() {
      this.loading = true
      const method = !this.id ? 'post' : 'put'
      const postbacks = this.campaign.postbacks.filter(
        (postback: Postback) => postback.url,
      )
      if (!this.campaign.targetPromoIds?.length) {
        showErrorMessageSnackBar(
          this.$t('pages.campaighn.steps.editAffiliateCampaighn.chooseOne'),
        )
        this.loading = false
        return
      }
      if (this.campaign.splitTest) {
        if (this.campaign.targetPromoIds?.length < 2) {
          showErrorMessageSnackBar(
            this.$t('pages.campaighn.steps.editAffiliateCampaighn.choose'),
          )
          this.loading = false
          return
        }
        if (
          !this.campaign.promoPercents ||
          Object.keys(this.campaign.promoPercents)?.length !==
            this.campaign.targetPromoIds.length
        ) {
          showErrorMessageSnackBar(
            this.$t('pages.campaighn.steps.editAffiliateCampaighn.weight'),
          )
          this.loading = false
          return
        }
        for (const [id, percent] of Object.entries(
          this.campaign.promoPercents || {},
        )) {
          if (
            !id ||
            percent === null ||
            percent === undefined ||
            (percent as any)?.trim() === ''
          ) {
            showErrorMessageSnackBar(
              this.$t('pages.campaighn.steps.editAffiliateCampaighn.weight'),
            )
            this.loading = false
            return
          }
        }
      }

      axios[method](
        '/api/campaigns',
        { ...this.campaign, postbacks },
        {
          params: { id: this.id },
        },
      )
        .then(() => {
          this.setErrors({})
          this.$router.push('/campaigns')
          this.$emit('close')
          this.$emit('refresh')
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onReset: function() {
      this.reset()
    },
  },
})
