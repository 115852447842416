
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import MoneyInfo from './MoneyInfo.vue'
import LogOut from './LogOut.vue'
import UserInfo from './UserInfo.vue'
import LanguageSwitcher from '../sharable/LanguageSwitcher.vue'

export default defineComponent({
  name: 'MoneyAndUserInfo',
  components: { MoneyInfo, LogOut, UserInfo, LanguageSwitcher },

  props: ['affiliate', 'image', 'approve', 'hold', 'profit', 'className'],
  computed: {
    ...mapState('authModule', ['user']),
  },
  methods: {
    ...mapActions('authModule', ['logout']),
  },
})
