import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c71fde6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "navigation__list"
}
const _hoisted_2 = {
  key: 0,
  class: "navigation__item"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationIcons = _resolveComponent("NavigationIcons")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.user?.data?.isApproved)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNavLinks(), (link, idx) => {
          return (_openBlock(), _createElementBlock("li", {
            key: idx,
            class: _normalizeClass(["navigation__item", {
          navigation__item_selected: _ctx.$route.path === link.path,
          navigation__hidden: link.shouldHideOnDesktop,
        }])
          }, [
            (link.isVisible)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: link.path,
                  onClick: ($event: any) => (_ctx.onNavClick(link)),
                  class: "navigation__link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NavigationIcons, {
                      name: link.icon
                    }, null, 8, ["name"]),
                    _createElementVNode("span", null, _toDisplayString(link.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "onClick"]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128)),
        (_ctx.$q.screen.width < 1280)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localeOptions, (locale, idx) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: `${idx}-locale`,
                  class: _normalizeClass([{ navigation__lang_active: locale == _ctx.$i18n.locale }, "navigation__lang"]),
                  onClick: ($event: any) => (_ctx.handleLocaleClick(locale))
                }, _toDisplayString(locale), 11, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}