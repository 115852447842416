
import { defineComponent } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import ApexCharts from 'apexcharts'
import { formatValue } from '@/utils'
import getColorByColumn from '@/components/charts/getColors'
import { intersection, maxBy, sum } from 'lodash'

export default defineComponent({
  name: 'LineChart',
  props: [
    'rows',
    'columns',
    'loading',
    'allColumns',
    'groupBy',
    'groupByOptions',
    'exportData',
  ],
  components: {
    apexchart: VueApexCharts,
  },
  beforeUnmount() {
    ApexCharts.exec('lineChart', 'destroy')
  },
  watch: {
    columns() {
      const {
        series,
        xaxis,
        yaxis,
        stroke,
        fill,
        stacked,
        colors,
      } = this.getData()
      ApexCharts.exec(
        'lineChart',
        'updateOptions',
        {
          chart: {
            stacked,
          },
          series,
          stroke,
          fill,
          colors,
          xaxis,
          yaxis,
        },
        false,
        false,
      )
    },
    rows: {
      handler: function() {
        const {
          series,
          xaxis,
          yaxis,
          stroke,
          fill,
          stacked,
          colors,
        } = this.getData()
        this.chartOptions.xaxis = xaxis
        this.chartOptions.yaxis = yaxis
        this.chartOptions.stroke = stroke
        this.chartOptions.fill = fill
        this.chartOptions.colors = colors
        this.chartOptions.chart.stacked = stacked
        this.series = series
      },
      deep: true,
    },
  },
  data: function() {
    const {
      series,
      xaxis,
      yaxis,
      stroke,
      fill,
      stacked,
      colors,
    } = this.getData()
    return {
      series,
      chartOptions: {
        chart: {
          id: 'lineChart',
          type: 'line',
          height: 350,
          stacked,
          background: 'transparent',
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        tooltip: {
          theme: 'dark',
        },
        dataLabels: {
          enabled: false,
        },
        stroke,
        fill,
        xaxis,
        yaxis,
        plotOptions: {
          bar: {
            borderRadius: 4,
          },
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: true,
          },
          position: 'top',
          horizontalAlign: 'left',
          labels: {
            colors: ['#F5F5F5'],
          },
          itemMargin: {
            horizontal: 10,
          },
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: ' 100%',
        },
        colors,
        noData: {
          text: 'Нет данных',
          style: {
            color: '#F5F5F5',
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      },
    }
  },
  methods: {
    getData() {
      const allColumns = this.allColumns
      const colors = []
      const sortedRows = [...this.rows]
      for (const col of this.columns) {
        colors.push(getColorByColumn(col))
      }
      const series = []
      let stackedCount = 0
      let stackedCount2 = 0
      const stroke = {
        width: [],
        curve: 'smooth',
      }
      const fill = {
        opacity: [],
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      }
      const yaxis = []
      let xaxis: any = {
        labels: {
          style: {
            colors: '#AEAEAE',
          },
        },
      }
      if (this.groupBy === 'date' || this.groupBy === 'week') {
        xaxis = {
          type: 'datetime',
          min: Date.now(),
          max: Date.now(),
          categories: [],
          labels: {
            datetimeUTC: false,
            format: 'dd.MM',
            style: {
              colors: '#FFF',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: ' 100%',
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        }
        for (const row of sortedRows) {
          const value = this.groupBy === 'date' ? row.date : row.week
          xaxis.categories.push(new Date(value).getTime())
        }
        const min = Math.min.apply(null, xaxis.categories)
        const max = Math.max.apply(null, xaxis.categories)
        xaxis.min = min === Infinity ? new Date().getTime() : min
        xaxis.max = max === -Infinity ? new Date().getTime() : max
      } else {
        sortedRows.sort((r1: any, r2: any) => {
          let sum1 = 0
          let sum2 = 0
          for (const col of this.columns) {
            sum1 += Number(r1[col])
            sum2 += Number(r2[col])
          }
          if (sum1 > sum2) {
            return -1
          }
          if (sum1 < sum2) {
            return 1
          }
          return 0
        })
        xaxis = {
          categories: [],
          labels: {
            style: {
              colors: '#FFF',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: ' 100%',
            },
          },
        }
        const field = this.groupByOptions.find(
          (r: any) => r.id === this.groupBy,
        )?.field
        for (const row of sortedRows) {
          xaxis.categories.push(row[field])
        }
      }

      const { stackedYMaxConversions, stackedYMaxFinances } = this.getStackedY()
      for (const [i, col] of this.columns.entries()) {
        const isConversions = [
          'approvedLeads',
          'holdLeads',
          'trashLeads',
          'rejectedLeads',
        ].includes(col)
        const isFinances = ['approvedSum', 'holdSum', 'rejectedSum'].includes(
          col,
        )
        if (isConversions) {
          stackedCount++
        }
        if (isFinances) {
          stackedCount2++
        }
        const data = []
        let name = allColumns.find((r: any) => r.name === col)?.label as string
        for (const row of sortedRows) {
          data.push(formatValue(Number(row[col])))
        }
        if (col === 'registrationCount') {
          name = 'Регистрации'
        }
        if (col === 'firstDepositCount') {
          name = 'Депозиты'
        }
        series.push({
          name,
          data,
          type: [
            'firstDepositCount',
            'approvedLeads',
            'holdLeads',
            'trashLeads',
            'rejectedLeads',
            'approvedSum',
            'holdSum',
            'rejectedSum',
          ].includes(col)
            ? 'column'
            : 'area',
        })
        if (
          (!isConversions && !isFinances) ||
          (isConversions && stackedCount == 1) ||
          (isFinances && stackedCount2 == 1)
        ) {
          yaxis.push({
            min: 0,
            max:
              !isConversions && !isFinances
                ? Math.max.apply(null, data as number[]) + 1
                : isConversions
                ? stackedYMaxConversions
                : stackedYMaxFinances,
            opposite: i !== 0,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: true,
              color: colors[i],
            },
            labels: {
              style: {
                colors: '#FFF',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: ' 100%',
              },
            },
            title: {
              show: false,
            },
            floating: false,
            tooltip: {
              enabled: true,
            },
          })
        }
      }
      for (const serie of series) {
        stroke.width.push(2 as never)
      }
      const stacked = stackedCount > 1 || stackedCount2 > 1

      const finalData = {
        series,
        xaxis,
        yaxis,
        stroke,
        fill,
        stacked,
        colors,
      }

      return finalData
    },
    getStackedY() {
      if (!this.rows.length) {
        return {
          stackedYMaxConversions: 0,
          stackedYMinConversions: 0,
          stackedYMaxFinances: 0,
          stackedYMinFinances: 0,
        }
      }
      const convFields = intersection(this.columns, [
        'approvedLeads',
        'holdLeads',
        'trashLeads',
        'rejectedLeads',
      ])
      const financeFields = intersection(this.columns, [
        'approvedSum',
        'holdSum',
        'rejectedSum',
      ])
      const maxConvData = []
      const maxFinanceData = []
      for (const col of convFields) {
        maxConvData.push((maxBy(this.rows, col) as any)[col])
      }
      for (const col of financeFields) {
        maxFinanceData.push((maxBy(this.rows, col) as any)[col])
      }
      return {
        stackedYMaxConversions: sum(maxConvData.map(e => Number(e))),
        stackedYMaxFinances: sum(maxFinanceData.map(e => Number(e))),
      }
    },
  },
})
