
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { getOptions } from '@/utils'
import AddDomainDialog from '@/components/dialogs/AddDomainDialog.vue'
import { lookupRedirectDomains } from '@/utils/lookups'

export default defineComponent({
  name: 'RedirectDomain',
  props: ['defaultOptions'],
  components: { AddDomainDialog },
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  data: function() {
    return {
      showAddDomainDialog: false,
      redirectDomains: [...(this.defaultOptions.redirectDomains || [])],
    }
  },
  async mounted() {
    if (!this.campaign.redirectDomainId) {
      const redirectDomains = await getOptions(
        this.redirectDomains as [],
        '',
        '/api/redirect-domains',
      )
      this.redirectDomains = redirectDomains
      this.campaign.redirectDomainId = redirectDomains[0]?.id
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    async getRedirectDomains(value: string, update: any) {
      await lookupRedirectDomains(this, value, update)
    },
  },
})
