import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cfa1892"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statistic" }
const _hoisted_2 = { class: "statistic__item" }
const _hoisted_3 = { class: "statistic__title" }
const _hoisted_4 = { class: "statistic__text" }
const _hoisted_5 = { class: "statistic__item" }
const _hoisted_6 = { class: "statistic__title" }
const _hoisted_7 = { class: "statistic__text" }
const _hoisted_8 = { class: "statistic__item" }
const _hoisted_9 = { class: "statistic__title" }
const _hoisted_10 = { class: "statistic__text" }
const _hoisted_11 = { class: "statistic__item" }
const _hoisted_12 = { class: "statistic__title" }
const _hoisted_13 = { class: "statistic__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('pages.dashboard.unik')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.conv.clicks), 1)
    ]),
    _createElementVNode("li", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('pages.dashboard.rezult')), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.conv.conversions), 1)
    ]),
    _createElementVNode("li", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('pages.dashboard.income')), 1),
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.moneyFormatter.from(Number(_ctx.conv.hold), { symbol: '$' })), 1)
    ]),
    _createElementVNode("li", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('pages.dashboard.incomeApp')), 1),
      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.moneyFormatter.from(Number(_ctx.conv.approve), { symbol: '$' })), 1)
    ])
  ]))
}