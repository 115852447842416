
import { defineComponent } from 'vue'
export type IconName =
  | 'company'
  | 'conversion'
  | 'dashboard'
  | 'domains'
  | 'log-out'
  | 'logs'
  | 'offers'
  | 'payment'
  | 'statistic'
  | 'profile'
  | 'support'
  | 'log-out'

export default defineComponent({
  name: 'CompanyIcon',
  props: {
    name: {
      type: String as () => IconName,
      required: true,
    },
  },
})
