
import axios from 'axios'
import { defineComponent } from 'vue'
import { showSuccessSnackBar } from '@/utils'

export default defineComponent({
  name: 'CampaignUrlsBuilder',
  props: ['open', 'close', 'campaignId'],
  async created() {
    this.campaignUrl = (
      await axios.get('/api/campaigns/' + this.campaignId + '/url')
    ).data
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      sub1: null,
      sub2: null,
      sub3: null,
      sub4: null,
      sub5: null,
      campaignUrl: '',
      fullUrl: '',
    }
  },
  watch: {
    campaignUrl() {
      this.fullUrl = this.campaignUrl
    },
    sub1() {
      this.changeUrl()
    },
    sub2() {
      this.changeUrl()
    },
    sub3() {
      this.changeUrl()
    },
    sub4() {
      this.changeUrl()
    },
    sub5() {
      this.changeUrl()
    },
  },
  methods: {
    copyUrl() {
      const url = document.getElementById('campaign_url') as HTMLInputElement
      url.select()
      document.execCommand('copy')
      showSuccessSnackBar(this.$t('alerts.copy'))
    },
    changeUrl() {
      this.fullUrl = this.campaignUrl
      if (!this.sub1 && !this.sub2 && !this.sub3 && !this.sub4 && !this.sub5) {
        return
      }
      this.fullUrl += '?'
      const subs = [this.sub1, this.sub2, this.sub3, this.sub4, this.sub5]
      for (let index = 0; index < subs.length; index++) {
        if (!subs[index]) {
          continue
        }
        this.fullUrl += `sub${index + 1}=${subs[index]}&`
      }
      this.fullUrl = this.fullUrl.slice(0, -1)
    },
  },
})
