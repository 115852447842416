import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_RedirectEvents = _resolveComponent("RedirectEvents")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_AffiliateLogs = _resolveComponent("AffiliateLogs")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "dashboard__page" }, {
    default: _withCtx(() => [
      (Object.keys(_ctx.$route.params)?.length)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            "no-caps": "",
            icon: "chevron_left",
            flat: "",
            label: _ctx.$t('btn.back'),
            onClick: _ctx.backToStats
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "inline-label": "",
        breakpoint: 0,
        align: "justify",
        "no-caps": "",
        style: {"max-width":"600px","background-color":"#252c3e","margin-bottom":"25px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "clicks",
            label: _ctx.$t('pages.logs.tabClick')
          }, null, 8, ["label"]),
          _createVNode(_component_q_tab, {
            name: "affiliate-logs",
            label: _ctx.$t('pages.logs.tabPostBack')
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        animated: "",
        class: "bg-transparent q-mr-lg"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "clicks" }, {
            default: _withCtx(() => [
              _createVNode(_component_RedirectEvents)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "affiliate-logs" }, {
            default: _withCtx(() => [
              _createVNode(_component_AffiliateLogs)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}