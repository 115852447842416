
import { defineComponent } from 'vue'
import Postbacks from '@/pages/campaigns/steps/Postbacks.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { getInitialOptions } from '@/pages/campaigns/config/getInitialOptions'
import { Postback } from '@/store/types'
import axios from 'axios'
import BuyingGeneralInfo from '@/pages/campaigns/steps/BuyingGeneralInfo.vue'
import UrlParams from '@/pages/campaigns/steps/UrlParams.vue'
import Streams from '@/pages/campaigns/steps/Streams.vue'
import { showErrorMessageSnackBar, showSuccessSnackBar } from '@/utils'
import { Stream, StreamOfferRule } from '@/store/types'
import { omit } from 'lodash'
export default defineComponent({
  name: 'BuyingCampaignEdit',
  props: ['id', 'isNew'],
  components: { Streams, UrlParams, BuyingGeneralInfo, Postbacks },
  computed: {
    ...mapState('campaignsModule', ['errors', 'campaign']),
    ...mapState('authModule', ['user']),
  },
  async created() {
    if (!this.id) {
      this.onReset()
      this.ready = true
      return
    }

    await this.getData(this.id)
    this.defaultOptions = await getInitialOptions(this.campaign, true)
    this.ready = true
  },
  data() {
    return {
      tab: 'general',
      defaultOptions: {},
      ready: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('campaignsModule', ['getData']),
    ...mapMutations('campaignsModule', ['update', 'reset', 'setErrors']),
    ...mapMutations('authModule', ['logout']),
    onSubmit: async function() {
      this.loading = true
      const method = !this.id ? 'post' : 'put'
      const postbacks = this.campaign.postbacks.filter(
        (postback: Postback) => postback.url,
      )
      const streams = []
      if (this.campaign.streams?.length) {
        for (const s of this.campaign.streams) {
          const offerRules = s.offerRules.filter(
            (r: any) =>
              r.offerId || r.offer?.id || r.campaign?.id || r.campaignId,
          )
          const landingRules = s.landingRules.filter(
            (r: any) => r.landingId || r.landing?.id,
          )
          const filters = s.filters.filter(
            (r: any) => r.option !== null && r.option !== undefined,
          )
          streams.push({
            ...s,
            offerRules,
            landingRules,
            filters,
          })
        }
      }
      if (!this.campaign.streams.find((s: Stream) => s.isDefault)) {
        showErrorMessageSnackBar(
          this.$t('pages.campaighn.steps.editCampaighn.alerts.def'),
        )
        this.loading = false
        return
      }
      if (!this.campaign.trafficSourceId) {
        showErrorMessageSnackBar(
          this.$t('pages.campaighn.steps.editCampaighn.alerts.source'),
        )
        this.loading = false
        return
      }
      if (
        this.campaign.streams.find(
          (s: Stream) => s.isDefault && s.filters?.length,
        )
      ) {
        showErrorMessageSnackBar(
          this.$t('pages.campaighn.steps.editCampaighn.alerts.without'),
        )
        this.loading = false
        return
      }
      if (
        this.campaign.streams.find(
          (s: Stream) => !s.isDefault && !s.filters?.length,
        )
      ) {
        showErrorMessageSnackBar(
          this.$t('pages.campaighn.steps.editCampaighn.alerts.with'),
        )
        this.loading = false
        return
      }
      for (const s of this.campaign.streams) {
        if (!s.offerRules?.length) {
          showErrorMessageSnackBar(
            this.$t('pages.campaighn.steps.editCampaighn.alerts.define'),
          )
          this.loading = false
          return
        }
        if (!s.offerRules.filter((o: StreamOfferRule) => o.isActive)?.length) {
          showErrorMessageSnackBar(
            this.$t('pages.campaighn.steps.editCampaighn.alerts.on'),
          )
          this.loading = false
          return
        }
      }
      if (
        this.campaign.streams.filter((s: Stream) => s.isDefault)?.length > 1
      ) {
        showErrorMessageSnackBar(
          this.$t('pages.campaighn.steps.editCampaighn.alerts.one'),
        )
        this.loading = false
        return
      }

      const source = (
        await axios.get(
          '/api/traffic-sources/params?id=' + this.campaign.trafficSourceId,
        )
      ).data
      if (source.queryParams && !this.campaign.keys?.length) {
        const keys = [...this.campaign.keys]
        const values = [...this.campaign.values]
        for (const [k, v] of Object.entries(source.queryParams)) {
          if (keys.includes(k)) {
            continue
          }
          keys.push(k as never)
          values.push(v as never)
        }
        this.update({ keys, values })
      }

      const queryParams: any = {}
      for (let i = 0; i < this.campaign.keys.length; i++) {
        if (!this.campaign.keys[i].trim()) {
          continue
        }
        queryParams[this.campaign.keys[i]] = this.campaign.values[i]
      }

      axios[method](
        '/api/campaigns',
        {
          ...omit(this.campaign, 'customGroup'),
          isMediaBuying: true,
          postbacks,
          queryParams,
          streams,
        },
        {
          params: { id: this.id },
        },
      )
        .then(() => {
          this.setErrors({})
          this.$router.push('/campaigns')
          this.$emit('close')
          this.$emit('refresh')
          showSuccessSnackBar(
            this.$t('pages.campaighn.steps.editCampaighn.alerts.onSave'),
          )
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors)
          showErrorMessageSnackBar(
            this.$t('pages.campaighn.steps.editCampaighn.alerts.onError'),
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    onReset: function() {
      this.reset()
    },
  },
})
