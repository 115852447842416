import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = { class: "text-h6" }
const _hoisted_3 = { class: "row justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "campaign-url-card",
        style: {"min-width":"550px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between items-center" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('dialogs.campaighnUrlBuilder.title')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "close",
                  flat: "",
                  rounded: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                dense: "",
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sub1,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sub1) = $event)),
                placeholder: _ctx.$t('input.placeholders.sub1')
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_q_input, {
                dense: "",
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sub2,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sub2) = $event)),
                placeholder: _ctx.$t('input.placeholders.sub2')
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_q_input, {
                dense: "",
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sub3,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sub3) = $event)),
                placeholder: _ctx.$t('input.placeholders.sub3')
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_q_input, {
                dense: "",
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sub4,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sub4) = $event)),
                placeholder: _ctx.$t('input.placeholders.sub4')
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_q_input, {
                dense: "",
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sub5,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sub5) = $event)),
                placeholder: _ctx.$t('input.placeholders.sub5')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('dialogs.campaighnUrlBuilder.link')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_input, {
                  dense: "",
                  for: "campaign_url",
                  class: "col-sm-9 col-7 link-text",
                  outlined: "",
                  readonly: "",
                  modelValue: _ctx.fullUrl,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.fullUrl) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_btn, {
                  color: "positive",
                  flat: "",
                  outline: "",
                  "no-caps": "",
                  onClick: _ctx.copyUrl,
                  class: "col-sm-2 col-4 copy-btn",
                  label: _ctx.$t('btn.copy'),
                  icon: "content_copy"
                }, null, 8, ["onClick", "label"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}