import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03ecb4d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"margin-top":"32px"},
  class: "line-chart-container"
}
const _hoisted_2 = { class: "chart__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { style: {"background-color":"#151a27","padding-left":"16px","padding-right":"16px","padding-top":"40px","padding-bottom":"200px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_Statistics, { conv: _ctx.conv }, null, 8, ["conv"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('pages.dashboard.title')), 1),
          _createVNode(_component_Filters, {
            onReset: _ctx.resetFilters,
            onChange: _ctx.onChangeFilters,
            onChangeAll: _ctx.changeAll,
            "current-filters": _ctx.filters
          }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"])
        ]),
        _createVNode(_component_LineChart, {
          rows: _ctx.chartData,
          groupBy: _ctx.filters.groupBy,
          columns: _ctx.selectedColumns,
          allColumns: _ctx.columns,
          groupByOptions: _ctx.getGroupByOptions,
          loading: _ctx.chartLoading
        }, null, 8, ["rows", "groupBy", "columns", "allColumns", "groupByOptions", "loading"])
      ])
    ]),
    _: 1
  }))
}