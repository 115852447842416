
import { defineComponent } from 'vue'
import Header from '../../components/main-header/MainHeader.vue'
import Button from '../../components/sharable/Button.vue'
import LoginDialog from '../../components/dialogs/LoginDialog.vue'
import SignUpDialog from '../../components/dialogs/SignUpDialog.vue'

export default defineComponent({
  name: 'NotFound',
  components: { Header, Button, LoginDialog, SignUpDialog },
  data: function() {
    return {
      showLoginDialog: false,
      showRegDialog: false,
    }
  },
  methods: {
    onClickLogin() {
      console.log('click')
      this.showLoginDialog = !this.showLoginDialog
    },
    onClickReg() {
      this.showRegDialog = !this.showRegDialog
    },
  },
})
