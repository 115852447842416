import CampaignsList from '@/pages/campaigns/CampaignsList.vue'
import OffersList from '@/pages/offers/OffersList.vue'
import Offer from '@/pages/offers/Offer.vue'
import Statistics from '@/pages/statistics/Statistics.vue'
import DashboardView from '@/views/DashboardView.vue'
import MainLanding from '@/views/MainLanding.vue'
import Conversions from '@/pages/conversions/Conversions.vue'
import Payments from '@/pages/payments/Payments.vue'
import GlobalPostbacks from '@/pages/global-postbacks/GlobalPostbacks.vue'
import Profile from '@/pages/profile/Profile.vue'
import RedirectDomains from '@/pages/redirect-domains/RedirectDomains.vue'
import Referrals from '@/pages/referrals/Referrals.vue'
import Support from '@/pages/support/Support.vue'
import BeginResetPasswordDialog from '@/components/dialogs/BeginResetPasswordDialog.vue'
import EndResetPasswordDialog from '@/components/dialogs/EndResetPasswordDialog.vue'
import Dashboard from '@/pages/dashboard/Dashboard.vue'
import Logs from '@/pages/clicklog/Logs.vue'
import FAQ from '@/pages/faq/FAQ.vue'
import WaitingApprove from '@/components/dialogs/WaitingApprove.vue'
import CampaignEdit from '@/pages/campaigns/CampaignEdit.vue'
import NotFound from '@/pages/not-found/NotFoundPage.vue'
import MadiaBuying from '@/views/MediaBuyingView.vue'
import CareerView from '@/views/CareerView.vue'

const routes = [
  {
    path: '/',
    component: MainLanding,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/blog',
    component: MainLanding,
    meta: { requiresAuth: false },
    name: 'blog',
  },
  {
    path: '/not-found',
    component: NotFound,
    meta: { requiresAuth: false },
    name: 'not-found',
  },
  {
    path: '/career',
    component: CareerView,
    meta: {
      requiresAuth: false,
    },
    name: 'career',
  },
  {
    path: '/media-buying',
    component: MadiaBuying,
    meta: {
      requiresAuth: false,
    },
    name: 'media buying',
  },
  // {
  //   path: '/media',
  //   component: MediaView,
  //   meta: { requiresAuth: false,  title:
  //      'Всё про Aрбитраж Трафика и Affiliate Marketing | Masons.partners',
  //    description:
  //      'Все новости сферы арбитража трафика 🔥 Актуальные интервью и события 🤑 Будь в курсе! | Masons.partners', },
  //   name: 'media',
  // },
  {
    path: '/contacts',
    component: MainLanding,
    meta: { requiresAuth: false },
    name: 'contacts',
  },
  {
    path: '/faq',
    component: FAQ,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password',
    component: BeginResetPasswordDialog,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password/:key',
    component: EndResetPasswordDialog,
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    component: DashboardView,
    name: 'basic',
    meta: { requiresAuth: true },
    children: [
      { path: 'waiting', component: WaitingApprove },
      { path: 'dashboard', component: Dashboard },
      { path: 'campaigns', component: CampaignsList },
      { path: 'campaigns/new', component: CampaignEdit },
      { path: 'campaigns/:id', component: CampaignEdit, name: 'campaign' },
      { path: 'offers', component: OffersList },
      { path: 'offers/:id', component: Offer, name: 'offer' },
      { path: 'statistics', component: Statistics },
      { path: 'conversions', component: Conversions },
      { path: 'payments', component: Payments },
      { path: 'postbacks', component: GlobalPostbacks },
      { path: 'profile', component: Profile },
      { path: 'redirect-domains', component: RedirectDomains },
      { path: 'referrals', component: Referrals },
      { path: 'support', component: Support },
      { path: 'clicklog', component: Logs },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/',
      },
    ],
  },
]

export default routes
