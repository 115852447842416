export enum Browser {
  Amaya,
  AndroidBrowser,
  Arora,
  Avant,
  Avast,
  AVG,
  BIDUBrowser,
  Baidu,
  Basilisk,
  Blazer,
  Bolt,
  Brave,
  Bowser,
  Camino,
  Chimera,
  ChromeHeadless,
  ChromeWebView,
  Chrome,
  Chromium,
  ComodoDragon,
  Dillo,
  Dolphin,
  Doris,
  Edge,
  Electron,
  Epiphany,
  Facebook,
  Falkon,
  Fennec,
  Firebird,
  Firefox,
  Flock,
  Flow,
  GSA,
  GoBrowser,
  ICEBrowser,
  IE,
  IEMobile,
  IceApe,
  IceCat,
  IceDragon,
  Iceweasel,
  Instagram,
  Iridium,
  Iron,
  Jasmine,
  KMeleon,
  Kindle,
  Klar,
  Konqueror,
  LBBROWSER,
  Line,
  Links,
  Lunascape,
  Lynx,
  MIUIBrowser,
  MaemoBrowser,
  Maemo,
  Maxthon,
  MetaSrMidori,
  Minimo,
  MobileSafari,
  Mosaic,
  Mozilla,
  NetFront,
  NetSurf,
  Netfront,
  Netscape,
  NokiaBrowser,
  Obigo,
  OculusBrowser,
  OmniWeb,
  OperaCoast,
  Opera,
  PaleMoon,
  PhantomJS,
  Phoenix,
  Polaris,
  Puffin,
  QQ,
  QQBrowser,
  QQBrowserLite,
  Quark,
  QupZilla,
  RockMelt,
  Safari,
  SailfishBrowser,
  SamsungBrowser,
  SeaMonkey,
  Silk,
  Skyfire,
  Sleipnir,
  Slim,
  SlimBrowser,
  Swiftfox,
  Tesla,
  TizenBrowser,
  UCBrowser,
  UPBrowser,
  Vivaldi,
  Waterfox,
  WeChat,
  Weibo,
  Yandex,
  baidu,
  iCab,
  w3m,
  WhaleBrowser,
}
