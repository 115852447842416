export default function getColorByColumn(col: string): string {
  switch (col) {
    case 'clicks':
    case 'uniqueClicks':
    case 'revenue':
      return '#3757C1' //'#2FB2FF'
    case 'registrationCount':
      return '#C1BC37' // '#FF8C00'
    case 'registrations':
      return '#37FF9F'
    case 'firstDepositCount':
      return '#60cf44' //'#1CC928'
    case 'conversions':
      return '#7545e6'
    case 'firstDepositSum':
      return '#321c5c'
    case 'approvedLeads':
      return '#00E396'
    case 'holdLeads':
      return '#858585'
    case 'trashLeads':
      return '#000'
    case 'rejectedLeads':
      return '#e60b0b'
    case 'cr':
      return '#5e490d'
    case 'epc':
      return '#faaf16'
    case 'crReg':
      return '#134722'
    case 'crFtd':
      return '#1a0afc'
    case 'crFtdReg':
      return '#fc0a8b'
    case 'approvedPercent':
      return '#61a8c9'
    case 'approvedSum':
      return '#60cf44'
    case 'holdSum':
      return '#6e6867'
    case 'rejectedSum':
      return '#ba5343'
    case 'revShareRevenueSum':
    default:
      return '#000'
  }
}
