
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MediaTeam',
  data() {
    return {
      sideClasses: 'team__item team__item_side',
      centerClass: 'team__item',
      containerClass: '',
      slides: [
        {
          id: 'left',
          classes: 'team__item',
          img: 'images/team_1.jpg',
          link: 'https://t.me/Kirill_MasonsPartners',
          label: 't.me/Kirill_MasonsPartners',
        },
        {
          id: 'right',
          classes: 'team__item',
          img: 'images/team_2.png',
          link: 'https://t.me/yaroslavdm777',
          label: 't.me/yaroslavdm777',
        },
        {
          id: 'center',
          classes: 'team__item',
          img: 'images/team_3.jpg',
          link: 'https://t.me/Den_MasonsPartners',
          label: 't.me/Den_MasonsPartners',
        },
      ],
    }
  },
  methods: {},
})
