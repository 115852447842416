
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EditNoteDialog',
  props: ['open', 'close', 'entity', 'id', 'note', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
    noteApiPath(): string {
      if (this.entity === 'campaign') {
        return 'campaigns'
      }
      return 'campaigns'
    },
  },
  async created() {
    if (this.id) {
      this.comment = this.note
    }
  },
  data: function() {
    return {
      comment: null,
      errors: {},
    }
  },
  methods: {
    save() {
      axios
        .post(`/api/${this.noteApiPath}/notes/?id=${this.id}`, {
          comment: this.comment,
        })
        .then(() => {
          this.$emit('refresh')
          this.onClose()
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
    },
    onClose() {
      this.comment = null
      this.errors = {}
      this.$emit('close')
    },
  },
})
