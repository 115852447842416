
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'AvatarEditDialog',
  props: ['open', 'close', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data() {
    return {
      loading: false,
      image: '',
      targetFile: '',
    }
  },
  methods: {
    onDrop: function(e: any) {
      e.stopPropagation()
      e.preventDefault()
      this.createFile(e.dataTransfer.files[0])
    },
    onChange(e: any) {
      this.createFile(e.target.files[0])
    },
    createFile(file: File) {
      if (!file.type.match('image.*')) {
        alert('Выберите картинку')
        return
      }
      const reader = new FileReader()
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const component = this
      reader.onload = function(e: any) {
        component.image = e.target.result
      }
      reader.readAsDataURL(file)
      this.targetFile = (file as unknown) as string
    },
    removeFile() {
      this.image = ''
      this.targetFile = ''
    },
    async onSubmit() {
      this.loading = true
      try {
        const form = new FormData()
        form.append('image', this.targetFile)
        await axios.post(`/api/profile/image`, form)
        this.$emit('close')
        this.$emit('refresh')
      } catch (e) {
        alert(this.$t('dialogs.avatarEdit.alert'))
      }
      this.loading = false
    },
  },
})
