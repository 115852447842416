import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/icons/home.svg'


const _withScopeId = n => (_pushScopeId("data-v-9f2988ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_q_list, {
      tag: "ul",
      class: "mobile-menu__list"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNavLinks(), (link) => {
          return (_openBlock(), _createBlock(_component_q_item, {
            key: link.label,
            onMouseover: ($event: any) => (_ctx.isHovered = link.label),
            onMouseout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isHovered = '')),
            tag: "li",
            class: _normalizeClass({
            active: _ctx.isLinkActive(link),
          })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { path: link.path, hash: link.hash },
                class: "mobile-menu__link"
              }, {
                default: _withCtx(() => [
                  (link.isHome)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(link.label), 1)
                      ], 64))
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1032, ["onMouseover", "class"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}