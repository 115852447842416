import { getVerticalByOffer } from '@/utils/rewards'
import { useI18n } from 'vue-i18n'

export default function getColumns(isMb = false) {
  const { t } = useI18n()

  const arr = []
  if (isMb) {
    arr.push({
      name: 'id',
      label: 'ID',
      field: 'id',
      align: 'center',
      sortable: true,
    })
  }
  // { name: 'amount', label: 'Ставка', field: 'amount', align: 'center' },
  arr.push(
    {
      name: 'name',
      label: t('tables.offers.offer'),
      field: 'name',
      align: 'center',
      sortable: true,
    },
    {
      name: 'geo',
      label: t('tables.offers.geo'),
      field: 'geo',
      align: 'center',
    },
  )

  if (isMb) {
    arr.push({
      name: 'rewards',
      label: 'Ставка байера',
      field: 'rewards',
      align: 'left',
    })
  }

  arr.push(
    {
      name: 'vertical',
      label: t('tables.offers.vert'),
      field: 'vertical',
      align: 'center',
      sortable: true,
      format: (val: string, row: any) => getVerticalByOffer(row),
    },
    {
      name: 'rewardType',
      label: t('tables.offers.type'),
      field: 'rewardType',
      align: 'center',
    },
  )

  if (!isMb) {
    arr.push({
      name: 'actions',
      label: t('tables.offers.action'),
      field: 'actions',
      align: 'center',
    })
  }
  return arr
}
