import { formatDate, formatValue, getAffiliateBalances } from '@/utils'
import { TransactionReason } from '@/enums/TransactionReason'
import { Currency } from '@/enums/Currency'
import { RewardType } from '@/enums/RewardType'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { BalanceType } from '@/enums/BalanceType'
import { useI18n } from 'vue-i18n'

export function getPayoutColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'createdAt',
      label: t('tables.payments.date'),
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'paymentSystem',
      label: t('tables.payments.method'),
      field: 'paymentSystem',
      align: 'center',
      format: (val: string, row: any) =>
        PaymentSystem[row.payoutSystemAccount.paymentSystem],
    },
    {
      name: 'account',
      label: t('tables.payments.koshelek'),
      field: 'account',
      align: 'center',
      format: (val: string, row: any) => row.payoutSystemAccount.account,
    },
    {
      name: 'amount',
      label: t('tables.payments.amount'),
      field: 'amount',
      align: 'center',
      format: (val: string, row: any) =>
        row.amount + ' ' + Currency[row.currency],
    },
    {
      name: 'status',
      label: t('tables.payments.status'),
      field: 'status',
      align: 'center',
      sortable: true,
    },
  ]
}

export function getTransactionsColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'datetime',
      label: t('tables.transations.date'),
      field: 'datetime',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'holdAmount',
      label: t('tables.transations.incomeHold'),
      field: 'holdAmount',
      align: 'center',
      sortable: true,
      format: (val: string, row: any) => {
        return row.reason === TransactionReason.LeadHold &&
          row.rewardType !== RewardType.RevShare
          ? `${formatValue(row.transactionAmount, 3)} ${
              Currency[row.transactionCurrency]
            }`
          : '—'
      },
    },
    {
      name: 'revShareAmount',
      label: t('tables.transations.incomeRev'),
      field: 'revShareAmount',
      align: 'center',
      sortable: true,
      format: (val: string, row: any) => {
        return row.rewardType === RewardType.RevShare &&
          row.reason === TransactionReason.LeadHold
          ? `${formatValue(row.transactionAmount, 3)} ${
              Currency[row.transactionCurrency]
            }`
          : '—'
      },
    },
    {
      name: 'offerName',
      label: t('tables.transations.offer'),
      field: 'offerName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'transactionAmount',
      label: t('tables.transations.transfer'),
      field: 'transactionAmount',
      align: 'center',
    },
    {
      name: 'reason',
      label: t('tables.transations.status'),
      field: 'reason',
      align: 'center',
      sortable: true,
      format: (val: number) => TransactionReason[val],
    },
    {
      name: 'leadId',
      label: t('tables.transations.id'),
      field: 'leadId',
      align: 'center',
      sortable: true,
    },
  ]
}

export function getRevShareColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'offerId',
      label: t('tables.revShare.offer'),
      field: 'offerId',
      align: 'center',
      sortable: true,
      format: (val: string, row: any) => row.id,
    },
    {
      name: 'offerName',
      label: t('tables.revShare.name'),
      field: 'offerName',
      align: 'center',
      sortable: true,
      format: (val: string, row: any) => row.name,
    },
    {
      name: 'balances',
      label: t('tables.revShare.balance'),
      field: 'balances',
      align: 'center',
      format: (val: string, row: any) =>
        getAffiliateBalances(row.balances, BalanceType.Hold),
    },
  ]
}
