import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    dark: "",
    dense: "",
    rows: _ctx.rows,
    columns: _ctx.createColumns,
    "row-key": "id",
    separator: "cell",
    pagination: _ctx.pagination,
    "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
    loading: _ctx.loading,
    onRequest: _ctx.onRequest,
    "binary-state-sort": "",
    class: "table-with-min-height-row"
  }, null, 8, ["rows", "columns", "pagination", "loading", "onRequest"]))
}