
import { defineComponent } from 'vue'
import Button from '@/components/sharable/Button.vue'
import axios from 'axios'

export default defineComponent({
  name: 'ContactForm',
  components: {
    Button,
  },
  data: function() {
    return {
      isLoading: false,
      name: '',
      telegram: '',
      message: '',
      errors: {
        name: '',
        telegram: '',
        message: '',
      },
    }
  },
  methods: {
    requiredField(val?: string) {
      return (val !== null && val !== '') || 'Обязательное поле'
    },
    reset(e: any) {
      console.log('e :>> ', e)
    },
    async onSubmit() {
      console.log('SUBMIT')
      if (!this.name) {
        this.errors.name = 'Обязательное поле'
        console.log('this.name', this.name)
        console.log('this.name', this.errors)
      }
      if (!this.telegram) {
        this.errors.telegram = 'Обязательное поле'
      }
      if (!this.message) {
        this.errors.message = 'Обязательное поле'
      }

      if (!this.name || !this.telegram || !this.message) {
        return
      }

      try {
        await axios.post('/api/public/contact-info', {
          name: this.name,
          telegram: this.telegram,
          message: this.message,
        })
      } catch (error) {
        console.log('🚀 ~ onSubmit ~ error:', error)
      }
      this.name = ''
      this.telegram = ''
      this.message = ''
      console.log('🚀 ~ onSubmit ~ this.$refs:', this.$refs.form.reset())

      return
    },
  },
})
