
import { defineComponent } from 'vue'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getRevShareColumns } from '@/pages/payments/config/columns'

export default defineComponent({
  name: 'RevShare',
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('offerId'),
    }
  },
  computed: {
    createColumns() {
      return getRevShareColumns()
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/balances/revshare')
    },
  },
})
