import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { className: "text-h6" }
const _hoisted_2 = { class: "row q-mt-md" }
const _hoisted_3 = {
  key: 0,
  class: "col-12 q-pb-sm"
}
const _hoisted_4 = {
  key: 1,
  class: "col-12 q-pb-sm"
}
const _hoisted_5 = {
  key: 2,
  class: "col-12 q-pb-sm"
}
const _hoisted_6 = {
  key: 3,
  class: "col-12 q-pb-sm"
}
const _hoisted_7 = {
  key: 4,
  class: "col-12 q-pb-sm"
}
const _hoisted_8 = {
  key: 5,
  class: "col-12 q-pb-sm"
}
const _hoisted_9 = {
  key: 6,
  class: "col-12 q-pb-sm"
}
const _hoisted_10 = {
  key: 7,
  class: "col-12 q-pb-sm"
}
const _hoisted_11 = {
  key: 8,
  class: "col-12 q-pb-sm"
}
const _hoisted_12 = {
  key: 9,
  class: "col-12 q-pb-sm"
}
const _hoisted_13 = {
  key: 10,
  class: "col-12 q-pb-sm"
}
const _hoisted_14 = {
  key: 11,
  class: "col-12 q-pb-sm"
}
const _hoisted_15 = {
  key: 12,
  class: "col-12 q-pb-sm"
}
const _hoisted_16 = {
  key: 13,
  class: "col-12 q-pb-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.showDialog) = $event)),
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "card q-pa-none",
        style: {"min-width":"350px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { className: "q-card__section q-card__section--vert q-px-lg row justify-between items-center" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('dialog.filtersDialog.filter')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "close",
                  flat: "",
                  rounded: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { className: "q-pt-none q-form q-px-lg" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.hideForOffers)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.offer')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.offerId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.offerId) = $event)),
                        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeFilters('offerId', _ctx.filters.offerId))),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.offers,
                        onFilter: _ctx.getOffers,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForCampaigns && _ctx.hideForOffers && _ctx.hideForPostbacks)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.metki')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.trafficProperty,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.trafficProperty) = $event)),
                        onBlur: _cache[4] || (_cache[4] = ($event: any) => (
                _ctx.onChangeFilters('trafficProperty', _ctx.filters.trafficProperty)
              )),
                        onFilter: _ctx.getTrafficProperties,
                        outlined: "",
                        "use-input": "",
                        class: "col-4",
                        options: _ctx.trafficProperties
                      }, null, 8, ["modelValue", "onFilter", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForCampaigns && _ctx.hideForOffers)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.campaign')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.campaignId,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.campaignId) = $event)),
                        onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onChangeFilters('campaignId', _ctx.filters.campaignId))),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.campaigns,
                        onFilter: _ctx.getCampaigns,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForStatistics &&
                _ctx.hideForConversions &&
                _ctx.hideForCampaigns &&
                _ctx.hideForOffers
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.action')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.eventType,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters.eventType) = $event)),
                        onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onChangeFilters('eventType', _ctx.filters.eventType))),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.postbackEventTypes,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForPostbacks)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.city')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.geoId,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filters.geoId) = $event)),
                        onBlur: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onChangeFilters('geoId', _ctx.filters.geoId))),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.geos,
                        onFilter: _ctx.getGeos,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForStatistics &&
                _ctx.hideForConversions &&
                _ctx.hideForCampaigns &&
                _ctx.hideForPostbacks
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.ver')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.vertical,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filters.vertical) = $event)),
                        onBlur: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onChangeFilters('vertical', _ctx.filters.vertical))),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.verticals,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForStatistics &&
                _ctx.hideForConversions &&
                _ctx.hideForCampaigns &&
                _ctx.hideForPostbacks
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.category')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.offerCategoryId,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.filters.offerCategoryId) = $event)),
                        onBlur: _cache[14] || (_cache[14] = ($event: any) => (
                _ctx.onChangeFilters('offerCategoryId', _ctx.filters.offerCategoryId)
              )),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.offerCategories,
                        onFilter: _ctx.getOfferCategories,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForStatistics &&
                _ctx.hideForConversions &&
                _ctx.hideForCampaigns &&
                _ctx.hideForPostbacks
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.type')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.rewardType,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.filters.rewardType) = $event)),
                        onBlur: _cache[16] || (_cache[16] = ($event: any) => (_ctx.onChangeFilters('rewardType', _ctx.filters.rewardType))),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.rewardTypes,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForCampaigns && _ctx.hideForOffers && _ctx.hideForPostbacks)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.app')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.markupType,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.filters.markupType) = $event)),
                        onBlur: _cache[18] || (_cache[18] = ($event: any) => (_ctx.onChangeFilters('markupType', _ctx.filters.markupType))),
                        outlined: "",
                        disable: true,
                        class: "col-4",
                        options: _ctx.markupTypes,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForCampaigns && _ctx.hideForOffers && _ctx.hideForPostbacks)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.landing')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.landingId,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.filters.landingId) = $event)),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.landings,
                        onBlur: _cache[20] || (_cache[20] = ($event: any) => (_ctx.onChangeFilters('landingId', _ctx.filters.landingId))),
                        onFilter: _cache[21] || (_cache[21] = (value, updateFn) => _ctx.getLandings(value, updateFn, true)),
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForCampaigns && _ctx.hideForOffers && _ctx.hideForPostbacks)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.landingPre')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.preLandingId,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.filters.preLandingId) = $event)),
                        outlined: "",
                        class: "col-4",
                        options: _ctx.preLandings,
                        onBlur: _cache[23] || (_cache[23] = ($event: any) => (_ctx.onChangeFilters('preLandingId', _ctx.filters.preLandingId))),
                        onFilter: _cache[24] || (_cache[24] = (value, updateFn) => _ctx.getLandings(value, updateFn, false)),
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForConversions &&
                _ctx.hideForCampaigns &&
                _ctx.hideForOffers &&
                _ctx.hideForPostbacks
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.groupBy')) + ":", 1),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.groupBy,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.filters.groupBy) = $event)),
                        outlined: "",
                        onBlur: _cache[26] || (_cache[26] = ($event: any) => (_ctx.onChangeFilters('groupBy', _ctx.filters.groupBy))),
                        class: "col-4",
                        options: _ctx.groupByOptions,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForOffers)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.date')) + ":", 1),
                      _createVNode(_component_DatePicker, {
                        onChangeDate: _ctx.onChangeDate,
                        from: _ctx.filters.dateFrom,
                        to: _ctx.filters.dateTo
                      }, null, 8, ["onChangeDate", "from", "to"])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.hideForConversions)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('dialog.filtersDialog.dateDeposit')) + ":", 1),
                      _createVNode(_component_DatePicker, {
                        id: "2",
                        isDepositDate: true,
                        onChangeDate: _ctx.onChangeConversionDate,
                        from: _ctx.filters.conversionDateFrom,
                        to: _ctx.filters.conversionDateTo
                      }, null, 8, ["onChangeDate", "from", "to"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            align: "center",
            class: "flex column text-primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                disable: _ctx.loading,
                loading: _ctx.loading,
                "no-caps": "",
                rounded: "",
                color: "primary",
                "text-color": "dark",
                label: _ctx.$t('btn.add'),
                onClick: _ctx.addFilters
              }, null, 8, ["disable", "loading", "label", "onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                class: "reset-btn q-mt-md",
                label: _ctx.$t('btn.resetSimle'),
                onClick: _ctx.resetFilters
              }, null, 8, ["label", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}