import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "chart1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_cube = _resolveComponent("q-spinner-cube")!
  const _component_center = _resolveComponent("center")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_center, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_q_spinner_cube, {
              style: {"height":"415px"},
              color: "#f8bb6d",
              size: "3.5em"
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_apexchart, {
          key: 1,
          ref: "lineChart",
          type: "line",
          height: "400",
          width: "100%",
          options: _ctx.chartOptions,
          series: _ctx.series
        }, null, 8, ["options", "series"]))
  ]))
}