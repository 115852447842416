
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import BuyingCampaignEdit from '@/pages/campaigns/BuyingCampaignEdit.vue'
import AffiliateCampaignEdit from '@/pages/campaigns/AffiliateCampaignEdit.vue'
export default defineComponent({
  name: 'CampaignEdit',
  components: { AffiliateCampaignEdit, BuyingCampaignEdit },
  computed: {
    ...mapState('authModule', ['isMB']),
    isNew() {
      return this.$route.path.includes('new')
    },
    id() {
      return this.$route.path.includes('new') ? null : this.$route.params.id
    },
  },
})
