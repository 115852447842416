import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-deff4592"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accent" }
const _hoisted_2 = { class: "grey" }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass([{ [_ctx.className]: !!_ctx.className }, "money-info"])
  }, [
    (!_ctx.isMediaBuying)
      ? (_openBlock(), _createElementBlock("li", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/payments')))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('header.moneyinfo.approve')) + ": ", 1),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.approve) + " USD", 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isMediaBuying)
      ? (_openBlock(), _createElementBlock("li", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/payments')))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('header.moneyinfo.hold')) + ": ", 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.hold) + " USD", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isMediaBuying)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('header.moneyinfo.profit')) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.profit) + " USD", 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}