import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48267513"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "container mediabuying-section-wrapper" }
const _hoisted_3 = { class: "title__wrapper" }
const _hoisted_4 = { class: "mediabuying-section-title" }
const _hoisted_5 = { class: "mediabuying-section-subtitle" }
const _hoisted_6 = { class: "list" }
const _hoisted_7 = { class: "item cards" }
const _hoisted_8 = { class: "content" }
const _hoisted_9 = { class: "title accent" }
const _hoisted_10 = { class: "subtitle white" }
const _hoisted_11 = {
  style: {"background-color":"#EDB94A"},
  class: "item bg_text"
}
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "title black" }
const _hoisted_14 = { class: "subtitle black" }
const _hoisted_15 = {
  style: {"background-color":"#353A47"},
  class: "item bg_number"
}
const _hoisted_16 = { class: "content" }
const _hoisted_17 = { class: "title accent" }
const _hoisted_18 = { class: "subtitle white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('expertise')), 1),
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('mediaBuying.expertize.title')), 1)
        ]),
        _createVNode(_component_Button, {
          style: {"display":"flex"},
          outlined: "",
          onClick: this.onClickReg,
          text: _ctx.$t('btn.join')
        }, null, 8, ["onClick", "text"])
      ]),
      _createElementVNode("ul", _hoisted_6, [
        _createElementVNode("li", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('mediaBuying.expertize.first')), 1),
            _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.$t('mediaBuying.expertize.gambl')), 1)
          ])
        ]),
        _createElementVNode("li", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t('mediaBuying.expertize.best')), 1),
            _createElementVNode("h4", _hoisted_14, _toDisplayString(_ctx.$t('mediaBuying.expertize.help')), 1)
          ])
        ]),
        _createElementVNode("li", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.$t('mediaBuying.expertize.success')), 1),
            _createElementVNode("h4", _hoisted_18, _toDisplayString(_ctx.$t('mediaBuying.expertize.more')), 1)
          ])
        ])
      ])
    ])
  ]))
}