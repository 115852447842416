import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-between items-center" }
const _hoisted_2 = { class: "q-mb-lg" }
const _hoisted_3 = { class: "row justify-between items-center" }
const _hoisted_4 = { class: "col-1 text-h6" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "row q-mb-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_PostbackNoteDialog = _resolveComponent("PostbackNoteDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('postbacks.title')) + " ⚙", 1),
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          "no-caps": "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPostbackNoteDialog = !_ctx.showPostbackNoteDialog)),
          class: "col-1 q-mr-md",
          flat: "",
          label: _ctx.$t('postbacks.label'),
          icon: "info"
        }, null, 8, ["label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postbacks, (postback, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "postback-block q-mb-lg",
          key: index
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.$t('postbacks.subTitle')), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                onClick: ($event: any) => (_ctx.removePostback(index)),
                flat: "",
                rounded: "",
                disable: _ctx.postbacks.length < 2,
                color: "white",
                icon: "delete_outline"
              }, null, 8, ["onClick", "disable"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_input, {
              outlined: "",
              class: "col-12 q-mr-lg link-text",
              placeholder: _ctx.$t('postbacks.inputPlaceholder'),
              modelValue: postback.url,
              "onUpdate:modelValue": ($event: any) => ((postback.url) = $event),
              error: !!_ctx.errors?.postbacks,
              "error-message": _ctx.errors?.postbacks
            }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue", "error", "error-message"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_option_group, {
              modelValue: postback.types,
              "onUpdate:modelValue": ($event: any) => ((postback.types) = $event),
              options: _ctx.postbackTypes,
              color: "primary",
              type: "checkbox",
              inline: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
          ])
        ]))
      }), 128)),
      _createVNode(_component_PostbackNoteDialog, {
        open: _ctx.showPostbackNoteDialog,
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPostbackNoteDialog = false))
      }, null, 8, ["open"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_q_btn, {
        onClick: _ctx.addPostback,
        round: "",
        class: "q-mr-md",
        color: "secondary",
        icon: "add"
      }, null, 8, ["onClick"]),
      _createElementVNode("b", null, _toDisplayString(_ctx.$t('postbacks.btn')), 1)
    ])
  ], 64))
}