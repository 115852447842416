import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"350px","background-color":"#151A27 !important"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between items-center" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('dialogs.addDomain.title')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "close",
                  flat: "",
                  rounded: "",
                  onClick: _ctx.onClose
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-py-none" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                dense: "",
                outlined: "",
                type: "email",
                modelValue: _ctx.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                placeholder: "example.com",
                autofocus: "",
                onKeyup: [
                  _withKeys(_ctx.onClose, ["enter"]),
                  _withKeys(_ctx.onClose, ["esc"])
                ],
                error: !!_ctx.errors?.name,
                "error-message": _ctx.errors?.name
              }, null, 8, ["modelValue", "onKeyup", "error", "error-message"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            align: "center",
            class: "text-primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                disable: _ctx.loading,
                loading: _ctx.loading,
                "no-caps": "",
                outline: "",
                style: {"width":"100%","max-width":"250px","border-color":"#EDB94A","padding-top":"12px","padding-bottom":"12px"},
                class: "balances__btn",
                color: "primary",
                label: _ctx.$t('btn.add'),
                onClick: _ctx.addRedirectDomain
              }, null, 8, ["disable", "loading", "label", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}