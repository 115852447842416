
import { defineComponent } from 'vue'
import getColumns from './config/getColumns'
import {
  request,
  getPagination,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { exportToCsv } from '@/utils/export'
import { formatValue } from '@/utils'
import { FormatMoney } from 'format-money-js'

export default defineComponent({
  name: 'ConversionsTable',
  props: ['filters', 'tab', 'exportCsv'],
  mounted() {
    this.onRequest({ pagination: this.pagination })
  },
  computed: {
    createColumns() {
      const columns = getColumns()
      return columns
    },
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      loading: true,
      rows: [],
      columns: getColumns(),
      moneyFormatter,
      pagination: getPagination('datetime', 50),
    }
  },
  watch: {
    filters() {
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
    },
    exportCsv() {
      exportToCsv(this.rows, this.columns)
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/conversions/' + this.tab)
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {
          decimals: 2,
          separator: ' ',
        },
      )
    },
  },
})
