import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"25px"} }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex items-center justify-center" }
const _hoisted_6 = {
  key: 1,
  class: "new-filters"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 17,
  class: ""
}
const _hoisted_9 = { class: "self-center refresh-btn" }
const _hoisted_10 = {
  key: 20,
  class: "self-center refresh-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_FiltersDialog = _resolveComponent("FiltersDialog")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$q.screen.width < 600)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.hideForStatistics && _ctx.hideForConversions && _ctx.hideForDashboard)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_q_input, {
                  placeholder: _ctx.$t('input.placeholders.search'),
                  "label-color": "white",
                  debounce: "500",
                  class: "search-input",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                  "hide-bottom-space": ""
                }, null, 8, ["placeholder", "modelValue"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.hideForOffers && _ctx.hideForCampaigns && _ctx.hideForPostbacks)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_q_select, {
                  modelValue: _ctx.filters.offerId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.offerId) = $event)),
                  onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeFilters('offerId', _ctx.filters.offerId))),
                  placeholder: _ctx.$t('input.placeholders.offer'),
                  options: _ctx.offers,
                  onFilter: _ctx.getOffers,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": "",
                  "hide-bottom-space": "",
                  "hide-hint": ""
                }, null, 8, ["modelValue", "placeholder", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("i", {
              style: {"font-size":"20px"},
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showFiltersDialog = !_ctx.showFiltersDialog)),
              class: "fas fa-sliders-h"
            })
          ]),
          (_ctx.showFiltersDialog)
            ? (_openBlock(), _createBlock(_component_FiltersDialog, {
                key: 2,
                open: _ctx.showFiltersDialog,
                currentFilters: _ctx.filters,
                onChangeFilters: _ctx.submitAll,
                onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showFiltersDialog = false)),
                tab: _ctx.tab
              }, null, 8, ["open", "currentFilters", "onChangeFilters", "tab"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.hideForStatistics && _ctx.hideForConversions && _ctx.hideForDashboard)
            ? (_openBlock(), _createBlock(_component_q_input, {
                key: 0,
                dense: "",
                color: "white",
                "label-color": "white",
                standout: "",
                label: _ctx.$t('input.labels.search'),
                "hide-bottom-space": "",
                clearable: "",
                debounce: "500",
                class: "search-input",
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.search) = $event))
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.hideForOffers)
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 1,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                label: _ctx.$t('input.labels.offer'),
                clearable: "",
                modelValue: _ctx.filters.offerId,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filters.offerId) = $event)),
                onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onChangeFilters('offerId', _ctx.filters.offerId))),
                class: "col-4",
                options: _ctx.offers,
                onFilter: _ctx.getOffers,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options", "onFilter"]))
            : _createCommentVNode("", true),
          (_ctx.hideForCampaigns && _ctx.hideForOffers)
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 2,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.company'),
                modelValue: _ctx.filters.campaignId,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters.campaignId) = $event)),
                onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onChangeFilters('campaignId', _ctx.filters.campaignId))),
                class: "col-4",
                options: _ctx.campaigns,
                onFilter: _ctx.getCampaigns,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options", "onFilter"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForStatistics &&
            _ctx.hideForConversions &&
            _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForDashboard &&
            _ctx.hideForClickLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 3,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.event'),
                modelValue: _ctx.filters.eventType,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filters.eventType) = $event)),
                onBlur: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onChangeFilters('eventType', _ctx.filters.eventType))),
                class: "col-4",
                options: _ctx.postbackEventTypes,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (_ctx.hideForPostbacks && _ctx.hideForLogs)
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 4,
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                label: _ctx.$t('input.labels.geo'),
                dense: "",
                clearable: "",
                modelValue: _ctx.filters.geoId,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.filters.geoId) = $event)),
                onBlur: _cache[13] || (_cache[13] = ($event: any) => (_ctx.onChangeFilters('geoId', _ctx.filters.geoId))),
                class: "col-4",
                options: _ctx.geos,
                onFilter: _ctx.getGeos,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, {
                option: _withCtx((scope) => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ class: "flex items-center" }, scope.itemProps, _toHandlers(scope.itemEvents)), {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "q-mr-sm",
                        height: "10",
                        width: "15",
                        src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
                      }, null, 8, _hoisted_7),
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, {
                            innerHTML: scope.opt.name
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040)), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              }, 8, ["label", "modelValue", "options", "onFilter"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForStatistics &&
            _ctx.hideForCampaigns &&
            _ctx.hideForPostbacks &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 5,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.type'),
                modelValue: _ctx.filters.rewardType,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.filters.rewardType) = $event)),
                onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onChangeFilters('rewardType', _ctx.filters.rewardType))),
                class: "col-4",
                options: _ctx.rewardTypes,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForConversions &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 6,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.landing'),
                modelValue: _ctx.filters.landingId,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.filters.landingId) = $event)),
                class: "col-4",
                options: _ctx.landings,
                onBlur: _cache[17] || (_cache[17] = ($event: any) => (_ctx.onChangeFilters('landingId', _ctx.filters.landingId))),
                onFilter: _cache[18] || (_cache[18] = (value, updateFn) => _ctx.getLandings(value, updateFn, true)),
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (!_ctx.hideForCampaigns && _ctx.isMB)
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 7,
                dense: "",
                label: _ctx.$t('input.labels.group'),
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                "options-dense": "",
                modelValue: _ctx.filters.groupId,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.filters.groupId) = $event)),
                onBlur: _cache[20] || (_cache[20] = ($event: any) => (_ctx.onChangeFilters('groupId', _ctx.filters.groupId))),
                class: "col-4",
                options: _ctx.groups,
                onFilter: _ctx.getCampaignsGroups,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options", "onFilter"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForConversions &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 8,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.sub1'),
                modelValue: _ctx.filters.sub1,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.filters.sub1) = $event)),
                class: "",
                options: _ctx.sids1,
                onBlur: _cache[22] || (_cache[22] = ($event: any) => (_ctx.onChangeFilters('sub1', _ctx.filters.sub1))),
                onFilter: _cache[23] || (_cache[23] = (value, updateFn) => _ctx.getSids(value, updateFn, 'sids1')),
                "option-value": "value",
                "option-label": "value",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForConversions &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 9,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.sub2'),
                modelValue: _ctx.filters.sub2,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.filters.sub2) = $event)),
                class: "",
                options: _ctx.sids2,
                onBlur: _cache[25] || (_cache[25] = ($event: any) => (_ctx.onChangeFilters('sub2', _ctx.filters.sub2))),
                onFilter: _cache[26] || (_cache[26] = (value, updateFn) => _ctx.getSids(value, updateFn, 'sids2')),
                "option-value": "value",
                "option-label": "value",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForConversions &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 10,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.sub3'),
                modelValue: _ctx.filters.sub3,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.filters.sub3) = $event)),
                class: "",
                options: _ctx.sids3,
                onBlur: _cache[28] || (_cache[28] = ($event: any) => (_ctx.onChangeFilters('sub3', _ctx.filters.sub3))),
                onFilter: _cache[29] || (_cache[29] = (value, updateFn) => _ctx.getSids(value, updateFn, 'sids3')),
                "option-value": "value",
                "option-label": "value",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForConversions &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 11,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.sub4'),
                modelValue: _ctx.filters.sub4,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.filters.sub4) = $event)),
                class: "",
                options: _ctx.sids4,
                onBlur: _cache[31] || (_cache[31] = ($event: any) => (_ctx.onChangeFilters('sub4', _ctx.filters.sub4))),
                onFilter: _cache[32] || (_cache[32] = (value, updateFn) => _ctx.getSids(value, updateFn, 'sids4')),
                "option-value": "value",
                "option-label": "value",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForConversions &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 12,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.sub5'),
                modelValue: _ctx.filters.sub5,
                "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.filters.sub5) = $event)),
                class: "",
                options: _ctx.sids5,
                onBlur: _cache[34] || (_cache[34] = ($event: any) => (_ctx.onChangeFilters('sub5', _ctx.filters.sub5))),
                onFilter: _cache[35] || (_cache[35] = (value, updateFn) => _ctx.getSids(value, updateFn, 'sids5')),
                "option-value": "value",
                "option-label": "value",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForConversions &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 13,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.sub6'),
                modelValue: _ctx.filters.sub6,
                "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.filters.sub6) = $event)),
                class: "",
                options: _ctx.sids6,
                onBlur: _cache[37] || (_cache[37] = ($event: any) => (_ctx.onChangeFilters('sub6', _ctx.filters.sub6))),
                onFilter: _cache[38] || (_cache[38] = (value, updateFn) => _ctx.getSids(value, updateFn, 'sids6')),
                "option-value": "value",
                "option-label": "value",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (
          _ctx.hideForCampaigns &&
            _ctx.hideForOffers &&
            _ctx.hideForPostbacks &&
            _ctx.hideForConversions &&
            _ctx.hideForDashboard &&
            _ctx.hideForLogs
        )
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 14,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.sub7'),
                modelValue: _ctx.filters.sub7,
                "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.filters.sub7) = $event)),
                options: _ctx.sids7,
                onBlur: _cache[40] || (_cache[40] = ($event: any) => (_ctx.onChangeFilters('sub7', _ctx.filters.sub7))),
                onFilter: _cache[41] || (_cache[41] = (value, updateFn) => _ctx.getSids(value, updateFn, 'sids7')),
                "option-value": "value",
                "option-label": "value",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (!_ctx.hideForStatistics)
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 15,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                label: _ctx.$t('input.labels.groupBy1'),
                modelValue: _ctx.filters.groupBy,
                "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.filters.groupBy) = $event)),
                onBlur: _cache[43] || (_cache[43] = ($event: any) => (_ctx.onChangeFilters('groupBy', _ctx.filters.groupBy))),
                options: _ctx.groupByOptions,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": "",
                "hide-bottom-space": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (!_ctx.hideForStatistics)
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 16,
                dense: "",
                color: "white",
                "label-color": "white",
                rounded: "",
                standout: "",
                clearable: "",
                label: _ctx.$t('input.labels.groupBy2'),
                modelValue: _ctx.filters.groupBy2,
                "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.filters.groupBy2) = $event)),
                onBlur: _cache[45] || (_cache[45] = ($event: any) => (_ctx.onChangeFilters('groupBy2', _ctx.filters.groupBy2))),
                options: _ctx.groupByOptions,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": "",
                "hide-bottom-space": ""
              }, null, 8, ["label", "modelValue", "options"]))
            : _createCommentVNode("", true),
          (!_ctx.hideForStatistics)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (!_ctx.filters.groupBy2)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Выберите группировку 2")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_select, {
                  dense: "",
                  disable: !_ctx.filters.groupBy2,
                  color: "white",
                  "label-color": "white",
                  rounded: "",
                  standout: "",
                  clearable: "",
                  label: _ctx.$t('input.labels.groupBy3'),
                  modelValue: _ctx.filters.groupBy3,
                  "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.filters.groupBy3) = $event)),
                  onBlur: _cache[47] || (_cache[47] = ($event: any) => (_ctx.onChangeFilters('groupBy3', _ctx.filters.groupBy3))),
                  options: _ctx.groupByOptions,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": "",
                  "hide-bottom-space": ""
                }, null, 8, ["disable", "label", "modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.hideForOffers)
            ? (_openBlock(), _createBlock(_component_DatePicker, {
                key: 18,
                onChangeDate: _ctx.onChangeDate,
                from: _ctx.filters.dateFrom,
                to: _ctx.filters.dateTo
              }, null, 8, ["onChangeDate", "from", "to"]))
            : _createCommentVNode("", true),
          (!_ctx.hideForConversions)
            ? (_openBlock(), _createBlock(_component_DatePicker, {
                key: 19,
                id: "2",
                isDepositDate: true,
                onChangeDate: _ctx.onChangeConversionDate,
                from: _ctx.filters.conversionDateFrom,
                to: _ctx.filters.conversionDateTo
              }, null, 8, ["onChangeDate", "from", "to"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              rounded: "",
              outline: "",
              class: "white-btn",
              color: "primary",
              label: _ctx.$t('btn.reset'),
              icon: "autorenew",
              onClick: _ctx.resetFilters
            }, null, 8, ["label", "onClick"])
          ]),
          (!_ctx.hideForStatistics || !_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  rounded: "",
                  label: _ctx.$t('btn.export'),
                  color: "primary",
                  "text-color": "dark",
                  icon: "get_app",
                  onClick: _cache[48] || (_cache[48] = ($event: any) => (_ctx.$emit('export')))
                }, null, 8, ["label"])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}