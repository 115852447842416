
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Passwords',
  data: function() {
    return {
      password: null,
      newPassword: null,
      errors: {},
      hidePassword: true,
      hideNewPassword: true,
    }
  },
  methods: {
    changePassword() {
      axios
        .put('/api/profile/change-password', {
          password: this.password || '',
          newPassword: this.newPassword,
        })
        .then(() => {
          this.password = null
          this.newPassword = null
          this.errors = {}
          this.$q.notify({
            type: 'positive',
            message: `Password updated`,
          })
        })
        .catch(({ response }) => {
          this.errors = response?.data.errors
        })
    },
  },
})
