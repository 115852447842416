import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import router from './router'
import store from './store'
import quasarOptions from './configs/quasar-options'
import Vue3Storage from 'vue3-storage'
import { createI18n } from 'vue-i18n'
import { languages } from '@/locales/index'
import Vue3Marquee from 'vue3-marquee'

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'ru',
  fallbackLocale: 'ru',
  messages: languages,
})

createApp(App)
  .use(Quasar, quasarOptions)
  .use(i18n)
  .use(router)
  .use(store)
  .use(Vue3Storage, {})
  .use(Vue3Marquee)
  .mount('#app')
