
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Button from '../../components/sharable/Button.vue'

export default defineComponent({
  name: 'LoginDialog',
  props: ['open', 'close'],
  components: { Button },
  computed: {
    showDialog(): boolean {
      return this.open
    },
    ...mapState('authModule', ['loginError']),
  },
  created: function() {
    this.resetErrors()
    this.tryImpersonate()
  },
  data: function() {
    return {
      hidePassword: true,
      email: null,
      password: null,
      loading: false,
    }
  },
  methods: {
    ...mapActions('authModule', ['login', 'tryImpersonate']),
    ...mapMutations('authModule', ['resetErrors']),
    async submitLogin() {
      console.log('submit event')
      this.loading = true
      try {
        await this.login({
          email: this.email,
          password: this.password,
        })
      } finally {
        this.loading = false
      }
    },
    onClose() {
      this.resetErrors()
      this.email = null
      this.hidePassword = true
      this.password = null
      this.loading = false
      this.$emit('close')
    },
  },
})
