
import { defineComponent } from 'vue'
import axios from 'axios'
import { payoutCurrencies as currencies } from '@/utils'
import { PayoutSystemAccount } from '@/store/types'
export default defineComponent({
  name: 'CreatePayoutDialog',
  props: ['open', 'close', 'refresh', 'maxUsdAmount'],
  async created() {
    this.payoutSystemAccounts = (
      await axios.get('/api/payout-system-accounts')
    ).data
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
    accounts(): string[] {
      return this.payoutSystemAccounts.map(
        (payout: PayoutSystemAccount) => payout.account,
      )
    },
  },
  data: function() {
    return {
      loading: false,
      payoutSystemAccounts: [],
      payout: this.getDefaultPayoutValue(),
      errors: {},
      currencies,
    }
  },
  methods: {
    getDefaultPayoutValue() {
      return {
        amount: null,
        payoutSystemAccount: { account: null },
      }
    },
    createPayout() {
      const payoutSystemAccount = this.payoutSystemAccounts.find(
        (payoutAccount: PayoutSystemAccount) =>
          payoutAccount.account === this.payout.payoutSystemAccount.account,
      )
      // @todo use server validation
      if (!payoutSystemAccount) {
        this.errors = { payoutSystemAccount: 'Please, select payment account' }
        return
      }
      if (
        ((this.payout.amount as unknown) as number) < 50 ||
        ((this.payout.amount as unknown) as number) > this.maxUsdAmount
      ) {
        return
      }
      this.loading = true
      axios
        .post('/api/payouts', { ...this.payout, payoutSystemAccount })
        .then(() => {
          this.errors = {}
          this.payout = this.getDefaultPayoutValue()
          this.$emit('refresh')
        })
        .catch(() => {
          alert(this.$t('dialogs.createPayment.alert'))
        })
        .finally(() => {
          this.loading = false
          this.$emit('close')
        })
    },
  },
})
