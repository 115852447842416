
import { defineComponent } from 'vue'
import Button from '../../components/sharable/Button.vue'

export default defineComponent({
  name: 'Advantages',
  props: ['onClickReg'],
  components: {
    Button,
  },
  methods: {
    openExternalSite(link: string) {
      window.open(link, '_blank')
    },
  },
})
