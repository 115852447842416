
import { defineComponent } from 'vue'
import GeneralInfo from './tabs/GeneralInfo.vue'
import Passwords from './tabs/Passwords.vue'
import PaymentSystemAccounts from '@/pages/payments/tabs/PaymentSystemAccounts.vue'

export default defineComponent({
  name: 'Profile',
  components: { GeneralInfo, Passwords, PaymentSystemAccounts },
  data: function() {
    return {
      profileTab: 'general',
    }
  },
})
