
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MoneyInfo',
  props: {
    hold: {
      type: [Number, String],
      default: 0,
    },
    approve: {
      type: [Number, String],
      default: 0,
    },
    profit: {
      type: [Number, String],
      default: 0,
    },
    isMediaBuying: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
})
