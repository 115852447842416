
import { defineComponent } from 'vue'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getPayoutColumns } from '@/pages/payments/config/columns'
import { Currency } from '@/enums/Currency'
import CreatePayoutDialog from '@/components/dialogs/CreatePayoutDialog.vue'
import axios from 'axios'
import { BalanceType } from '@/enums/BalanceType'
import { Balance } from '@/store/types'
import { formatValue } from '@/utils'
import { PayoutStatus } from '@/enums/PayoutStatus'

export default defineComponent({
  name: 'Payouts',
  components: { CreatePayoutDialog },
  computed: {
    availableBalances(): Balance[] {
      return this.sortedBalances.map(arr => arr[0])
    },
    isPayoutDisabled(): boolean {
      return !this.availableBalances.filter(b => b.amount).length
    },
    getMaxUsdAmount(): number {
      const usdBalance = this.availableBalances.find(
        b => b.currency === Currency.USD,
      )
      if (!usdBalance) {
        return 0
      }
      return Math.max(usdBalance.amount as number)
    },
    createTableColumns() {
      return getPayoutColumns()
    },
  },
  async created() {
    this.refresh()
  },
  data: function() {
    return {
      ...getTableProperties('createdAt'),
      currencies: Currency,
      showCreatePayoutDialog: false,
      payoutStatuses: PayoutStatus,
      sortedBalances: [
        [
          {
            amount: 0,
            currency: Currency.USD,
            type: BalanceType.Available,
          },
          {
            amount: 0,
            currency: Currency.USD,
            type: BalanceType.Hold,
          },
        ],
      ],
    }
  },
  methods: {
    getBadgeClassName(props) {
      return props.row.status === this.payoutStatuses.Approved
        ? 'balances__badge_success'
        : props.row.status === this.payoutStatuses.Rejected
        ? 'balances__badge_error'
        : 'balances__badge_pending'
    },
    async onRequest(props: TableRequestProps) {
      const options = props || { pagination: this.pagination }
      await request(options, this as Table, '/api/payouts/')
      this.showCreatePayoutDialog = false
    },
    getFormattedValue(value: number) {
      return formatValue(value)
    },
    async refresh() {
      const balances = (await axios.get('/api/balances')).data
      if (balances.length) {
        this.sortedBalances = []
        const currencies = new Set([
          ...balances.map((b: Balance) => b.currency),
        ])
        for (const currency of currencies) {
          this.sortedBalances.push([
            balances.find(
              (b: Balance) =>
                b.currency === currency && b.type === BalanceType.Available,
            ),
            balances.find(
              (b: Balance) =>
                b.currency === currency && b.type === BalanceType.Hold,
            ),
          ])
        }
      }

      await this.onRequest({ pagination: this.pagination })
    },
  },
})
