import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row justify-end items-center",
  style: {"flex-grow":"1 !important"}
}
const _hoisted_2 = { class: "text-green" }
const _hoisted_3 = { class: "flex row items-center justify-center" }
const _hoisted_4 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_table, {
      rows: _ctx.rows,
      columns: _ctx.createColumns,
      "row-key": "id",
      separator: "cell",
      dark: "",
      dense: "",
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
      loading: _ctx.loading,
      onRequest: _ctx.onRequest,
      "binary-state-sort": "",
      "column-sort-order": "da",
      class: "table-with-min-height-row"
    }, {
      top: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.conversions.title')) + ": " + _toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.totalProfit || 0)) + "$", 1)
          ])
        ])
      ]),
      loading: _withCtx(() => [
        _createVNode(_component_q_inner_loading, {
          showing: "",
          color: "white",
          style: {"background":"#4c3d3d45"}
        })
      ]),
      "body-cell-geo": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                class: "q-mr-sm",
                height: "10",
                width: "15",
                alt: props.row.geo,
                src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geo}.svg`
              
              }, null, 8, _hoisted_4),
              _createTextVNode(" " + _toDisplayString(props.row.geo), 1)
            ])
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      _: 1
    }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
  ]))
}