import { createStore } from 'vuex'
import authModule from './modules/auth.module'
import campaignsModule from './modules/campaigns.module'

const store = createStore({
  modules: {
    authModule,
    campaignsModule,
  },
})

export default store
