import en from './en.json'
import ru from './ru.json'
import ua from './ua.json'
import uaConfig from './ua'
export const languages = {
  en,
  ru,
  ua,
}

export const uaConfigFile = uaConfig
