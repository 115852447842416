import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7716faa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team mediabuying-section" }
const _hoisted_2 = { class: "container mediabuying-section-wrapper" }
const _hoisted_3 = { class: "mediabuying-section-title" }
const _hoisted_4 = { class: "mediabuying-section-subtitle" }
const _hoisted_5 = { class: "section__text" }
const _hoisted_6 = { class: "team__wrapper" }
const _hoisted_7 = { class: "team__list" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { style: {"display":"flex"} }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "team__link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('mediaBuying.team.main')), 1),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('mediaBuying.team.title')), 1)
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('mediaBuying.team.subtitle')), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide) => {
          return (_openBlock(), _createElementBlock("li", {
            key: slide.id,
            id: slide.id
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(slide.classes)
            }, [
              _createElementVNode("a", {
                style: {"display":"flex"},
                target: "_blank",
                rel: "noopener noreferer",
                href: slide.link
              }, [
                _createElementVNode("img", {
                  src: slide.img
                }, null, 8, _hoisted_10)
              ], 8, _hoisted_9)
            ], 2),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("a", {
                style: {"display":"flex","justify-content":"center","width":"100%"},
                target: "_blank",
                rel: "noopener noreferer",
                href: slide.link
              }, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(slide.label), 1)
              ], 8, _hoisted_12)
            ])
          ], 8, _hoisted_8))
        }), 128))
      ])
    ])
  ]))
}