import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a68648a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "running__block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Marquee = _resolveComponent("Vue3Marquee")!

  return (_openBlock(), _createBlock(_component_Vue3Marquee, { duration: "10" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", {
          class: _normalizeClass([{
          running__text_white: !_ctx.color,
          running__text_accent: _ctx.color === 'accent',
        }, "running__text"])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
        ], 2)
      ])
    ]),
    _: 1
  }))
}