import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.generalInfo.name')), 1),
      _createVNode(_component_q_input, {
        dense: "",
        clearable: "",
        outlined: "",
        placeholder: _ctx.$t('pages.campaighn.steps.generalInfo.namePlaceholder'),
        "model-value": _ctx.campaign.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = name => _ctx.update({ name })),
        error: !!_ctx.errors?.name,
        "error-message": _ctx.errors?.name
      }, null, 8, ["placeholder", "model-value", "error", "error-message"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.generalInfo.offer')), 1),
      _createVNode(_component_q_select, {
        dense: "",
        clearable: "",
        "options-dense": "",
        "model-value": _ctx.campaign.offerId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = offerId => _ctx.update({ offerId })),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('select'))),
        outlined: "",
        options: _ctx.offers,
        onFilter: _ctx.getOffers,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        error: !!_ctx.errors?.offerId,
        "error-message": _ctx.errors?.offerId
      }, null, 8, ["model-value", "options", "onFilter", "error", "error-message"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.generalInfo.source')), 1),
      _createVNode(_component_q_select, {
        dense: "",
        clearable: "",
        "options-dense": "",
        "model-value": _ctx.campaign.trafficSourceId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = trafficSourceId => _ctx.update({ trafficSourceId })),
        outlined: "",
        disable: !_ctx.campaign.offerId,
        options: _ctx.trafficSources,
        onFilter: _ctx.getTrafficSources,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        error: !!_ctx.errors?.trafficSourceId,
        "error-message": _ctx.errors?.trafficSourceId
      }, null, 8, ["model-value", "disable", "options", "onFilter", "error", "error-message"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.generalInfo.geo')), 1),
      _createVNode(_component_q_select, {
        outlined: "",
        dense: "",
        clearable: "",
        "options-dense": "",
        multiple: "",
        "use-chips": "",
        disable: !_ctx.campaign.offerId,
        "model-value": _ctx.campaign.geoIds,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = geoIds => _ctx.update({ geoIds })),
        onFilter: _ctx.getGeos,
        options: _ctx.geos,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        "error-message": _ctx.errors?.geoIds,
        error: !!_ctx.errors?.geoIds
      }, {
        option: _withCtx((scope) => [
          _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ class: "flex items-center" }, scope.itemProps, _toHandlers(scope.itemEvents)), {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "q-mr-sm",
                height: "10",
                width: "15",
                src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
              }, null, 8, _hoisted_1),
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, {
                    innerHTML: scope.opt.name
                  }, null, 8, ["innerHTML"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1040)), [
            [_directive_close_popup]
          ])
        ]),
        _: 1
      }, 8, ["disable", "model-value", "onFilter", "options", "error-message", "error"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_q_checkbox, {
        "model-value": _ctx.campaign.bindUser,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = bindUser => _ctx.update({ bindUser })),
        label: _ctx.$t('pages.campaighn.steps.generalInfo.check1')
      }, null, 8, ["model-value", "label"]),
      _createVNode(_component_q_checkbox, {
        "model-value": _ctx.campaign.splitTest,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = splitTest => _ctx.update({ splitTest })),
        label: _ctx.$t('pages.campaighn.steps.generalInfo.check1')
      }, null, 8, ["model-value", "label"])
    ])
  ]))
}