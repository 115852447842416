
import { defineComponent } from 'vue'
import Postbacks from '@/components/postbacks/Postbacks.vue'
import PostbackLogs from './PostbackLogs.vue'
import axios from 'axios'
import { mapMutations, mapState } from 'vuex'
import { Postback } from '@/store/types'
export default defineComponent({
  name: 'GlobalPostbacks',
  components: { Postbacks, PostbackLogs },
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  unmounted(): void {
    this.reset()
  },
  data: function() {
    return {
      tab: 'settings',
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['reset']),
    async savePostbacks() {
      const postbacks = this.campaign.postbacks.filter(
        (postback: Postback) => postback.url,
      )
      await axios.post('/api/postbacks', postbacks)
    },
  },
})
