import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_Postbacks = _resolveComponent("Postbacks")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_PostbackLogs = _resolveComponent("PostbackLogs")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "inline-label": "",
        breakpoint: 0,
        align: "justify",
        class: "bg-dark q-mt-lg q-mb-xs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: "settings",
            label: _ctx.$t('pages.postbacks.tabSetting')
          }, null, 8, ["label"]),
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: "logs",
            label: _ctx.$t('pages.postbacks.tabLog')
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "settings" }, {
            default: _withCtx(() => [
              _createVNode(_component_Postbacks, { isGlobal: true }),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                class: "q-my-xl",
                label: _ctx.$t('btn.save'),
                color: "primary",
                onClick: _ctx.savePostbacks
              }, null, 8, ["label", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "logs" }, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('pages.postbacks.title')) + " 🔎", 1),
              _createVNode(_component_PostbackLogs)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}