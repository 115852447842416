import { Geo, Offer, OfferCategory, Reward } from '@/store/types'
import { Currency } from '@/enums/Currency'
import { Vertical } from '@/enums/Vertical'
import { RewardType } from '@/enums/RewardType'

function getCurrencies(rewards: Reward[]) {
  if (!rewards.length) {
    return
  }
  if (rewards.length === 1) {
    if (rewards[0].percent) {
      return rewards[0].percent + '%'
    }
    return rewards[0].amount + ' ' + Currency[rewards[0].currency]
  }

  const amounts = rewards.map(reward => reward.amount).filter(a => a !== null)
  const min = Math.min(...(amounts as number[]))
  const max = Math.max(...(amounts as number[]))
  if (min === max || min === 0) {
    if (max === 0 && rewards[0].percent) {
      return rewards[0].percent + '%'
    }
    return max + ' ' + Currency[rewards[0].currency]
  }
  return min + '-' + max + ' ' + Currency[rewards[0].currency]
}

export function getAmountsByRewards(rewards: Reward[]): string {
  const result: string[] = []
  const usdRewards = rewards.filter(r => r.currency === Currency.USD)
  const revShareReward = rewards.find(
    r => r.percent !== null && r.percent !== undefined,
  )
  const revSharePercent = revShareReward ? revShareReward.percent + '%' : ''
  if (!usdRewards.length) {
    return rewards[0]
      ? rewards[0]?.amount +
          ' ' +
          Currency[rewards[0]?.currency] +
          (revSharePercent ? ', ' + revSharePercent : '')
      : '—'
  }
  const range = getCurrencies(usdRewards)
  if (range) {
    result.push(range)
  }
  return result.join(', ')
}

export function getVerticalByOffer(offer: Offer) {
  const categories = offer.categories
    .map((c: OfferCategory) => c.name)
    .join(', ')
  return Vertical[offer.vertical] + (categories ? ', ' + categories : '')
}

export function getActualRewards(rewards: Reward[]) {
  return rewards
    .map(reward => {
      if (reward.type === RewardType.RevShare) {
        return (
          reward.geos.map(geo => geo.name) +
          ' - ' +
          reward.percent +
          ` % (${RewardType[reward.type]})`
        )
      }
      return (
        reward.geos.map(geo => geo.name) +
        ' - ' +
        reward.amount +
        ' ' +
        Currency[reward.currency] +
        ` (${RewardType[reward.type]})`
      )
    })
    .join(',\n')
}

export function getTypesForRewards(rewards: Reward[]) {
  const rewardTypes = [...new Set(rewards.map(reward => reward.type))]
  return rewardTypes.map(type => {
    let className = 'default-option'
    switch (type) {
      case RewardType.CPA:
        className = 'first-option'
        break
      case RewardType.RevShare:
        className = 'second-option'
        break
      case RewardType.CPL:
        className = 'third-option'
        break
    }
    return `<div class='option ${className}'>${RewardType[type]}</div>`
  })
}

export function getGeosForRewards(rewards: Reward[], firstOnly: boolean) {
  const geos = rewards.map(reward => reward.geos).flat()
  const geosUniqueByName = [
    ...new Map(geos.map(item => [item['name'], item])).values(),
  ]
  if (firstOnly) {
    if (geosUniqueByName.length >= 1 && geosUniqueByName.length <= 3) {
      return geosUniqueByName
    }
    return [geosUniqueByName[0], geosUniqueByName[1]]
  }
  return geosUniqueByName.sort((a: Geo, b: Geo) => a.name.localeCompare(b.name))
}
