
import axios from 'axios'
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'

export default defineComponent({
  name: 'UrlParams',
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  async created() {
    if (this.campaign.trafficSourceId) {
      const source = (
        await axios.get(
          '/api/traffic-sources/params?id=' + this.campaign.trafficSourceId,
        )
      ).data
      if (source.queryParams) {
        const keys = [...this.campaign.keys]
        const values = [...this.campaign.values]
        for (const [k, v] of Object.entries(source.queryParams)) {
          if (keys.includes(k)) {
            continue
          }
          keys.push(k as never)
          values.push(v as never)
        }
        this.update({ keys, values })
      }
    }
  },
  data: function() {
    return {
      keys: [],
      values: [],
      errors: {},
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    addKey() {
      let keys = [...this.campaign.keys, '']
      this.update({ keys })
    },
    updateKeys(val: string, index: number) {
      let keys = [...this.campaign.keys]
      keys[index] = val
      this.update({ keys })
    },
    updateValues(val: string, index: number) {
      let values = [...this.campaign.values]
      values[index] = val
      this.update({ values })
    },
  },
})
