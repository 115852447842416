import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row payment-blocks" }
const _hoisted_2 = { class: "row justify-end feather-block-wrapper" }
const _hoisted_3 = { class: "flex column justify-center items-center payment-block-f" }
const _hoisted_4 = { class: "row justify-center items-center" }
const _hoisted_5 = { class: "q-mb-md q-mt-none" }
const _hoisted_6 = { class: "flex justify-center items-center currency-block" }
const _hoisted_7 = { class: "flex justify-center items-center account-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_AddPaymentSystemDialog = _resolveComponent("AddPaymentSystemDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_btn, {
      "no-caps": "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddPaymentSystemDialog = !_ctx.showAddPaymentSystemDialog)),
      class: "q-mb-lg",
      color: "primary",
      label: _ctx.$t('pages.payments.action'),
      style: {"font-weight":"bold"},
      "text-color": "dark",
      icon: "add"
    }, null, 8, ["label"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentAccounts, (account, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-md-3 col-12 payment-block",
          key: index
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_icon, {
              class: "cursor-pointer",
              onClick: ($event: any) => (_ctx.deleteRow(account.id)),
              name: "delete",
              size: "18px"
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, [
                _createElementVNode("b", null, _toDisplayString(_ctx.paymentSystems[account.paymentSystem]), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.currencies[account.currency]), 1)
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(account.account), 1)
        ]))
      }), 128))
    ]),
    _createVNode(_component_AddPaymentSystemDialog, {
      open: _ctx.showAddPaymentSystemDialog,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddPaymentSystemDialog = false)),
      onRefresh: _ctx.onRequest
    }, null, 8, ["open", "onRefresh"])
  ], 64))
}