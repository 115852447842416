import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog__wrapper" }
const _hoisted_2 = { class: "btn__close" }
const _hoisted_3 = { class: "dialog__title" }
const _hoisted_4 = { class: "dialog__subTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _withKeys(_ctx.onClose, ["esc"]),
    class: "landing-dialog"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_btn, {
            "no-caps": "",
            icon: "close",
            style: {"color":"#999CA1"},
            flat: "",
            round: "",
            onClick: _ctx.onClose
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('dialogs.signUp.title')), 1),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('dialogs.signUp.subTitle')), 1),
        _createVNode(_component_q_form, { onSubmit: _ctx.submit }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              outlined: "",
              "label-color": "white",
              onFocus: _ctx.resetErrors,
              label: _ctx.$t('input.labels.email'),
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              type: "email",
              error: _ctx.signUpError
            }, null, 8, ["onFocus", "label", "modelValue", "error"]),
            _createVNode(_component_q_input, {
              outlined: "",
              label: _ctx.$t('input.labels.password'),
              onFocus: _ctx.resetErrors,
              error: _ctx.signUpError,
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              type: "password",
              "label-color": "white",
              "error-message": _ctx.$t('dialogs.signUp.error')
            }, null, 8, ["label", "onFocus", "error", "modelValue", "error-message"]),
            _createVNode(_component_q_input, {
              class: "q-mb-lg",
              "label-color": "white",
              outlined: "",
              label: "Telegram",
              modelValue: _ctx.telegram,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.telegram) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_Button, {
              centered: "",
              class: "btn__submit",
              type: "submit",
              loading: _ctx.loading,
              text: _ctx.$t('btn.register')
            }, null, 8, ["loading", "text"])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onKeyup"]))
}