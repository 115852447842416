
import { defineComponent } from 'vue'
import navList from '../navigation/NavigationList'

export default defineComponent({
  name: 'TitleDivider',
  props: ['title'],
  computed: {
    isVisible(): boolean {
      if (this.title) {
        return true
      }
      const pathes = navList(false).filter(el => el.path !== '/dashboard')
      return pathes.some(el => el.path === this.$route.path)
    },
    getTitle() {
      if (this.title) {
        return this.title
      }
      const list = navList(false)
      return list.find(el => el.path === this.$route.path)?.label
    },
  },
})
