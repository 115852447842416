
import { defineComponent } from 'vue'
import { mapState, mapMutations } from 'vuex'
import { lookupRedirectDomains, lookupTrafficSources } from '@/utils/lookups'
import { lookupGroups } from '@/utils/lookups'
import { CampaignDepartment } from '@/enums/CampaignDepartment'
import { CampaignStatus } from '@/enums/CampaignStatus'
import { enumToArray, getOptions, showSuccessSnackBar } from '@/utils'
import AddCustomGroupDialog from '@/components/dialogs/AddCustomGroupDialog.vue'
import { RedirectLinkType } from '@/enums/RedirectLinkType'
import { campaignRoutes } from '@/utils'

export default defineComponent({
  name: 'BuyingGeneralInfo',
  components: { AddCustomGroupDialog },
  props: ['defaultOptions'],
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  async mounted() {
    const redirectDomains = await getOptions(
      this.redirectDomains as [],
      '',
      '/api/redirect-domains',
    )
    this.redirectDomains = redirectDomains
    if (!this.campaign.redirectDomainId) {
      this.campaign.redirectDomainId = redirectDomains[0]?.id
    }
  },
  watch: {
    defaultOptions() {
      this.trafficSources = this.defaultOptions.trafficSources
      this.redirectDomains = this.defaultOptions.redirectDomains
      this.groups = this.defaultOptions.groups
    },
  },
  data: function() {
    return {
      urlRoutes: campaignRoutes,
      showAddGroupDialog: false,
      departments: enumToArray(CampaignDepartment),
      statuses: enumToArray(CampaignStatus),
      trafficSources: [...(this.defaultOptions.trafficSources || [])],
      redirectDomains: [...(this.defaultOptions.redirectDomains || [])],
      groups: [...(this.defaultOptions.groups || [])],
      redirectLinkTypeOptions: [
        {
          label: this.$t('pages.campaighn.steps.buyingGenInfo.lab1'),
          value: RedirectLinkType.Subdomain,
        },
        {
          label: this.$t('pages.campaighn.steps.buyingGenInfo.lab2'),
          value: RedirectLinkType.Query,
        },
        {
          label: this.$t('pages.campaighn.steps.buyingGenInfo.lab3'),
          value: RedirectLinkType.Domain,
        },
      ],
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSources(this, value, update)
    },
    async getCampaignsGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'campaign')
    },
    async getRedirectDomains(value: string, update: any) {
      await lookupRedirectDomains(this, value, update)
    },
    copy(text: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar(this.$t('pages.campaighn.steps.buyingGenInfo.copy'))
    },
  },
})
