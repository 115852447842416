import '../styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

export default {
  plugins: { Notify, Dialog },
  config: {
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
  },
  extras: ['material-icons', 'fontawesome-v5', 'ionicons-v4'],
}
