import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex row items-center justify-center no-wrap" }
const _hoisted_4 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_CampaignUrlsBuilder = _resolveComponent("CampaignUrlsBuilder")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_EditNoteDialog = _resolveComponent("EditNoteDialog")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "dashboard__page profile" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_toolbar, {
          class: "text-white campaign__toolbar",
          style: {"overflow":"auto !important"}
        }, {
          default: _withCtx(() => [
            (_ctx.isMB)
              ? (_openBlock(), _createBlock(_component_q_tabs, {
                  key: 0,
                  "no-caps": "",
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
                  stretch: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tab, {
                      name: "all",
                      label: _ctx.$t('pages.campaighn.tabs.all')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "fb",
                      label: _ctx.$t('pages.campaighn.tabs.fb')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "uac",
                      label: _ctx.$t('pages.campaighn.tabs.uac')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "tt",
                      label: _ctx.$t('pages.campaighn.tabs.tiktok')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "ppc",
                      label: _ctx.$t('pages.campaighn.tabs.ppc')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "ppc",
                      label: _ctx.$t('pages.campaighn.tabs.aso')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "organic",
                      label: _ctx.$t('pages.campaighn.tabs.organic')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "other",
                      label: _ctx.$t('pages.campaighn.tabs.other')
                    }, null, 8, ["label"]),
                    _createVNode(_component_q_tab, {
                      name: "deleted",
                      label: _ctx.$t('pages.campaighn.tabs.deleted')
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.isMB)
              ? (_openBlock(), _createBlock(_component_q_space, { key: 1 }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_btn, {
              "no-caps": "",
              rounded: "",
              outline: "",
              color: "primary",
              class: "campaign__toolbar_item",
              icon: "add",
              label: _ctx.$t('btn.create'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/campaigns/new')))
            }, null, 8, ["label"]),
            _createVNode(_component_q_btn, {
              "no-caps": "",
              rounded: "",
              outline: "",
              color: "primary",
              class: "campaign__toolbar_item",
              disable: _ctx.selected.length !== 1,
              icon: "content_copy",
              label: _ctx.$t('btn.clone'),
              onClick: _ctx.clone
            }, null, 8, ["disable", "label", "onClick"]),
            _createVNode(_component_q_btn, {
              "no-caps": "",
              rounded: "",
              outline: "",
              color: "primary",
              class: "campaign__toolbar_item",
              disable: _ctx.selected.length !== 1,
              icon: "note",
              label: _ctx.$t('btn.note'),
              onClick: _ctx.note
            }, null, 8, ["disable", "label", "onClick"]),
            (!_ctx.isMB)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 2,
                  "no-caps": "",
                  rounded: "",
                  outline: "",
                  color: "primary",
                  class: "campaign__toolbar_item",
                  disable: _ctx.selected.length !== 1,
                  icon: "delete",
                  label: _ctx.$t('btn.delete'),
                  onClick: _ctx.deleteRow
                }, null, 8, ["disable", "label", "onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_Filters, {
        onReset: _ctx.resetFilters,
        onChange: _ctx.onChangeFilters,
        onChangeAll: _ctx.changeAll,
        "current-filters": _ctx.filters
      }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
      _createElementVNode("div", null, [
        _createVNode(_component_q_table, {
          dark: "",
          dense: "",
          selected: _ctx.selected,
          "onUpdate:selected": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selected) = $event)),
          selection: "multiple",
          rows: _ctx.rows,
          columns: _ctx.getColumns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          "binary-state-sort": "",
          "column-sort-order": "da"
        }, _createSlots({
          loading: _withCtx(() => [
            _createVNode(_component_q_inner_loading, {
              showing: "",
              color: "white",
              style: {"background":"#4c3d3d45"}
            })
          ]),
          "body-cell-name": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(
                props.row.status === _ctx.campaignStatuses.Active
                  ? 'cursor-pointer'
                  : 'text-negative cursor-pointer'
              ),
                  onClick: ($event: any) => (_ctx.$router.push(`/campaigns/${props.row.id}`))
                }, _toDisplayString(props.row.name), 11, _hoisted_2),
                _createVNode(_component_q_icon, {
                  class: "q-ml-sm cursor-pointer",
                  size: "24px",
                  color: 
                props.row.status === _ctx.campaignStatuses.Active
                  ? 'positive'
                  : 'negative'
              ,
                  name: "link",
                  onClick: ($event: any) => (_ctx.copyUrl(props.row.url))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('btn.copyLink')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["color", "onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-status": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_toggle, {
                  onClick: ($event: any) => (_ctx.changeStatus(props.row)),
                  color: 
                props.row.status === _ctx.campaignStatuses.Active ? 'green' : 'red'
              ,
                  "model-value": !props.row.status
                }, null, 8, ["onClick", "color", "model-value"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-trafficSource": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(props.row.trafficSource.name), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-geo": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.geos, (geo, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("img", {
                        class: "q-mr-sm",
                        height: "10",
                        width: "15",
                        alt: props.row.geo,
                        src: 
                    `http://purecatamphetamine.github.io/country-flag-icons/3x2/${geo.isoCode}.svg`
                  
                      }, null, 8, _hoisted_4),
                      _createTextVNode(" " + _toDisplayString(geo.isoCode), 1)
                    ])
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-actions": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  round: "",
                  icon: "query_stats",
                  onClick: ($event: any) => (
                _ctx.$router.push(
                  `/statistics?campaignId=${props.row.id}&groupBy=geoId&groupBy2=offerId&groupBy3=landingId`,
                )
              )
                }, null, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  flat: "",
                  round: "",
                  icon: "edit",
                  onClick: ($event: any) => (_ctx.$router.push(`/campaigns/${props.row.id}`))
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 2
        }, [
          (!_ctx.isMB)
            ? {
                name: "body-cell-url",
                fn: _withCtx((props) => [
                  _createVNode(_component_q_td, { props: props }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        outline: "",
                        "no-caps": "",
                        color: "positive",
                        icon: "add_link",
                        class: "link underlined",
                        onClick: ($event: any) => (
                props.row.showCampaignUrlsBuilder = !props.row
                  .showCampaignUrlsBuilder
              )
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('btn.receive')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      (props.row.showCampaignUrlsBuilder)
                        ? (_openBlock(), _createBlock(_component_CampaignUrlsBuilder, {
                            key: 0,
                            open: props.row.showCampaignUrlsBuilder,
                            onClose: ($event: any) => (props.row.showCampaignUrlsBuilder = false),
                            campaignId: props.row.id
                          }, null, 8, ["open", "onClose", "campaignId"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["selected", "rows", "columns", "pagination", "loading", "onRequest"])
      ]),
      (_ctx.showEditNoteDialog)
        ? (_openBlock(), _createBlock(_component_EditNoteDialog, {
            key: 0,
            open: _ctx.showEditNoteDialog,
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showEditNoteDialog = false)),
            id: _ctx.id,
            onRefresh: _ctx.onRequest,
            note: _ctx.comment,
            entity: "campaign"
          }, null, 8, ["open", "id", "onRefresh", "note"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}