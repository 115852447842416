
import axios from 'axios'
import getColumns from './config/getColumns'
import { defineComponent } from 'vue'
import { Reward } from '@/store/types'
import { Currency } from '@/enums/Currency'
import Filters from '@/components/filters/Filters.vue'
import {
  FiltersType,
  getDefaultFilters,
  getTableProperties,
  request,
  Row,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { AffiliateAccessStatus } from '@/enums/AffiliateAccessStatus'
import {
  getActualRewards,
  getAmountsByRewards,
  getGeosForRewards,
  getTypesForRewards,
} from '@/utils/rewards'
import { mapState } from 'vuex'
import store from '@/store'

export default defineComponent({
  name: 'OffersList',
  components: { Filters },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('authModule', ['isMB']),
    createColumns() {
      return getColumns(store.state.authModule.isMB)
    },
  },
  data() {
    return {
      ...getTableProperties('id', 25),
      storageUrl: process.env.CDN_STORAGE_URL,
      filters: getDefaultFilters(),
      currencies: Currency,
      currentRowId: null,
    }
  },
  watch: {
    async filters() {
      this.pagination.page = 1
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    async getAccess(offerId: number) {
      await axios.get('/api/offers/access/' + offerId)
      await this.onRequest({ pagination: this.pagination })
    },
    hasAccess(status: AffiliateAccessStatus) {
      return status === AffiliateAccessStatus.Opened
    },
    onRowClick(evt: string, row: Row) {
      this.$router.push('/offers/' + row.id)
    },
    getRewardAmounts(rewards: Reward[]): string {
      return getAmountsByRewards(rewards)
    },
    getRewards(rewards: Reward[]) {
      return getActualRewards(rewards)
    },
    getGeos(row: any, firstOnly = false) {
      return getGeosForRewards(
        [...row.commonRewards, ...row.affiliateRewards],
        firstOnly,
      )
    },
    getRewardTypes(row: any) {
      return getTypesForRewards([...row.commonRewards, ...row.affiliateRewards])
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/offers/')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters()
    },
    getGeoImgSrc(code) {
      return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`
    },
  },
})
