
import { defineComponent } from 'vue'
import LanguageSwitcher from '../../components/sharable/LanguageSwitcher.vue'
import MainNavigation from './MainNavigation.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'MainHeader',
  components: {
    LanguageSwitcher,
    MainNavigation,
  },
  data() {
    return {
      lang: localStorage.getItem('lang') || 'RU',
      menuVisible: false,
    }
  },
  props: ['onLogin', 'onReg'],
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible
    },
    changeLang(lang: string) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
    },
  },
  setup() {
    const { locale, availableLocales } = useI18n({ useScope: 'global' })

    return {
      localeState: locale,
      localeOptions: availableLocales,
    }
  },
})
