
import { defineComponent } from 'vue'
import RedirectEvents from './RedirectEvents.vue'
import AffiliateLogs from './AffiliateLogs.vue'
export default defineComponent({
  name: 'Logs',
  components: { RedirectEvents, AffiliateLogs },
  data: function() {
    return {
      tab: 'clicks',
    }
  },
  methods: {
    backToStats() {
      const params = JSON.parse(this.$route.params.filters as any)
      this.$router.push({
        name: 'statistics',
        params: {
          filters: this.$route.params.filters,
          ...('vertical' in params ? { vertical: params.vertical } : {}),
        },
      })
    },
  },
})
