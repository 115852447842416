
import { defineComponent } from 'vue'

import Footer from '../components/footer/Footer.vue'
import Header from '../components/main-header/MainHeader.vue'
import LoginDialog from '../components/dialogs/LoginDialog.vue'
import SignUpDialog from '../components/dialogs/SignUpDialog.vue'
import RequestModal from '../components/dialogs/MediaBuyingRequest.vue'
import HeroSection from './media-buying-parts/Hero.vue'
import TeamSection from './media-buying-parts/Team.vue'
import SourceSection from './media-buying-parts/Source.vue'
import Ticker from '@/components/sharable/Ticker.vue'
import GeoItem from '@/components/sharable/GeoItem.vue'
import Advantages from './components/Advantages.vue'
import ContactForm from './components/ContactForm.vue'
import { addMetaToThePage } from '@/utils/meta'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'MediaBuying',
  components: {
    Footer,
    Header,
    LoginDialog,
    SignUpDialog,
    RequestModal,
    HeroSection,
    TeamSection,
    SourceSection,
    Ticker,
    GeoItem,
    Advantages,
    ContactForm,
  },
  data() {
    return {
      locale: 'ru',
      isLeaveRequestModalOpen: false,
      showLoginDialog: false,
      showRegDialog: false,

      blog: [
        {
          id: 1,
          img: 'images/blog/team_1.jpg',
          link:
            'https://diasp.pro/znakomstvo-s-komandami-intervyu-s-masons-traffic/',
        },
        {
          id: 2,
          img: 'images/blog/team_2.jpg',
          link: 'https://www.youtube.com/watch?v=ohVk5HsTxRc',
        },
        {
          id: 3,
          img: 'images/blog/team_3.jpg',
          link: 'https://www.youtube.com/watch?app=desktop&v=uNh0geFQ9eE',
        },
        {
          id: 4,
          img: 'images/blog/team_4.jpg',
          link: 'https://www.youtube.com/watch?v=-kuHUtiGzMc',
        },
        {
          id: 5,
          img: 'images/blog/team_5.jpg',
          link: 'https://www.youtube.com/watch?v=gT2vL96H4g0',
        },
        {
          id: 6,
          img: 'images/blog/team_6.jpg',
          link:
            'https://affhub.media/ua/masons-traffic-vid-top-timi-na-rinku-snd-do-partnerskoji-merezhi/',
        },
      ],
    }
  },
  methods: {
    onClickLogin() {
      this.showLoginDialog = !this.showLoginDialog
    },
    onClickReg() {
      this.showRegDialog = !this.showRegDialog
    },
    onClickRequest() {
      this.isLeaveRequestModalOpen = !this.isLeaveRequestModalOpen
    },
  },
  mounted() {
    const { locale } = useI18n()
    this.locale = locale

    addMetaToThePage(
      this.$t('meta.mediaBuying.title'),
      this.$t('meta.mediaBuying.description'),
    )
  },
  watch: {
    locale() {
      addMetaToThePage(
        this.$t('meta.mediaBuying.title'),
        this.$t('meta.mediaBuying.description'),
      )
    },
  },
})
