import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.authRequired) {
    return next('/')
  }

  if (
    store.state.authModule.user &&
    !(store.state.authModule.user as any)?.data?.isApproved &&
    to.path !== '/waiting' &&
    to.path !== '/'
  ) {
    return next('/waiting')
  }

  next()
})

export default router
