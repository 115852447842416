
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { Promo, TrafficSource } from '@/store/types'
import { MarkupType } from '@/enums/MarkupType'
import { downloadPromo } from '@/utils'
import { omit } from 'lodash'
import { showErrorMessageSnackBar } from '@/utils'

export default defineComponent({
  name: 'Promos',
  props: ['defaultOptions'],
  computed: {
    ...mapState('campaignsModule', ['campaign', 'errors']),
    landings: function(): Promo[] {
      return (
        this.defaultOptions.promos?.filter(
          (promo: Promo) =>
            promo.geos.find(g => this.campaign.geoIds?.includes(g.id)) &&
            (promo.trafficSources?.length
              ? promo.trafficSources.find(
                  (s: TrafficSource) => s.id === this.campaign.trafficSourceId,
                )
              : true),
        ) || []
      )
    },
    preLandings(): Promo[] {
      return (
        this.defaultOptions.promos?.filter(
          (promo: Promo) =>
            promo.geos.find(g => g.id == this.campaign.geoId) &&
            (promo.trafficSources?.length
              ? promo.trafficSources.find(
                  (s: TrafficSource) => s.id === this.campaign.trafficSourceId,
                )
              : true),
        ) || []
      )
    },
  },
  data: function() {
    return {
      markupTypes: MarkupType,
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    updatePromos(targetPromo: any) {
      this.defaultOptions.promos?.forEach((p: Promo) => {
        if (p.id !== targetPromo.id && !this.campaign.splitTest) {
          p.isActive = false
        }
      })
      const activeIds = this.defaultOptions.promos
        ?.filter((p: Promo) => p.isActive)
        .map((p: Promo) => p.id)
      const notActiveIds = this.defaultOptions.promos
        ?.filter((p: Promo) => !p.isActive)
        .map((p: Promo) => p.id)

      if (this.campaign.splitTest) {
        const promoPercents =
          omit(this.campaign.promoPercents, notActiveIds) || {}
        this.update({
          promoPercents: {
            ...promoPercents,
            [targetPromo.id]: targetPromo.percent,
          },
        })
      }

      this.campaign.targetPromoIds = activeIds
      this.update({
        targetPromoIds: activeIds,
      })
    },
    landingRedirect(url: string) {
      window.open(url)
    },
    async download(id: string) {
      await downloadPromo(id)
    },
    equalize() {
      const targetPromoIds = this.campaign.targetPromoIds
      if (!targetPromoIds.length) {
        showErrorMessageSnackBar(
          this.$t('pages.campaighn.steps.landings.choose'),
        )
        return
      }
      const percent = String(Math.round(100 / targetPromoIds.length))
      const promoPercents: any = {}
      for (const id of targetPromoIds) {
        promoPercents[id] = percent
      }
      this.update({
        promoPercents,
      })
    },
  },
})
