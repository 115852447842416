import { getOptions } from '@/utils'
import { Geo, Promo } from '@/store/types'
import axios from 'axios'
import { SemanticType } from '@/enums/SemanticType'

export async function lookupOffers(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
) {
  const offers = await getOptions(
    ref.offers as [],
    value,
    '/api/offers/list/',
    ids,
  )
  const mb = JSON.parse(localStorage.getItem('user') as any)?.data
    ?.isMediaBuying
  if (mb) {
    update(() => {
      ref.offers = offers.map((r: any) => ({
        ...r,
        name: `#${r.id} ${r.name}`,
      }))
    })
    return
  }
  update(() => {
    ref.offers = offers
  })
}

export async function lookupRotations(ref: any, value: string, update: any) {
  const campaigns = await getOptions(
    ref.rotations as [],
    value,
    `/api/rotations`,
  )
  update(() => {
    ref.rotations = campaigns.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`,
    }))
  })
}

export async function lookupOfferPromos(
  ref: any,
  value: string,
  update: any,
  offerId: number,
) {
  const path = `/api/promos/offer?offerId=${offerId}`
  const options = await getOptions(ref.promos, value, path)
  update(() => {
    ref.promos = options
  })
}

export async function lookupSids(
  ref: any,
  value: string,
  update: Function,
  sidField: string,
) {
  const sids = await getOptions(ref[sidField] as [], value, '/api/sids', [], {
    sid: sidField,
  })
  update(() => {
    ref[sidField] = sids
  })
}

export async function lookupTrafficProperties(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
) {
  const sids = await getOptions(
    ref.trafficProperties as [],
    value,
    '/api/traffic-properties',
    ids,
  )
  update(() => {
    ref.trafficProperties = sids
  })
}

export async function lookupGroups(
  ref: any,
  value: string,
  update: any,
  field: string,
) {
  const options = await getOptions(ref.groups, value, '/api/groups/' + field)
  update(() => {
    ref.groups = options
  })
}

export async function lookupGeos(
  ref: any,
  value: string,
  update: Function,
  filtering = false,
  isMediaBuying = false,
) {
  let geos = [] as unknown
  if (filtering) {
    geos = ref.defaultOptions.geos.filter((g: Geo) =>
      g.name.toLowerCase().includes(value.toLowerCase()),
    )
  } else if (ref.$route.path.includes('offers')) {
    geos = await getOptions(ref.geos as [], value, '/api/geos', [], {
      byRewards: true,
    })
  } else if (ref.$route.path.includes('campaigns') && isMediaBuying) {
    geos = await getOptions(ref.geos as [], value, '/api/geos', [], {
      byStreams: true,
    })
  } else {
    geos = await getOptions(ref.geos as [], value, '/api/geos')
  }
  update(() => {
    ref.geos = geos
  })
}

export async function lookupTrafficSources(
  ref: any,
  value: string,
  update: Function,
) {
  const params: { offerId: number; search?: string } = {
    offerId: ref.campaign.offerId,
  }
  if (value) {
    params.search = value
  }

  const trafficSources = (await axios.get('/api/traffic-sources', { params }))
    .data

  update(() => {
    ref.trafficSources = trafficSources
  })
}

export async function lookupRedirectDomains(
  ref: any,
  value: string,
  update: Function,
) {
  const redirectDomains = await getOptions(
    ref.redirectDomains as [],
    value,
    '/api/redirect-domains',
    [ref.campaign.redirectDomainId],
  )
  update(() => {
    ref.redirectDomains = redirectDomains
    if (!ref.campaign.redirectDomainId) {
      ref.campaign.redirectDomainId = redirectDomains[0]?.id
    }
  })
}

export async function lookupCampaigns(
  ref: any,
  value: string,
  update: Function,
) {
  const campaigns = await getOptions(
    ref.campaigns as [],
    value,
    '/api/campaigns/list',
  )
  const mb = JSON.parse(localStorage.getItem('user') as any)?.data
    ?.isMediaBuying
  if (mb) {
    update(() => {
      ref.campaigns = campaigns.map((r: any) => ({
        ...r,
        name: `#${r.id} ${r.name}`,
      }))
    })
    return
  }
  update(() => {
    ref.campaigns = campaigns
  })
}

export async function lookupOfferCategories(
  ref: any,
  value: string,
  update: Function,
) {
  const categories = await getOptions(
    ref.offerCategories as [],
    value,
    '/api/offer-categories',
    [],
    { vertical: ref.filters.vertical },
  )
  update(() => {
    ref.offerCategories = categories
  })
}

export async function lookupLandingsForBuying(
  ref: any,
  value: string,
  update: Function,
) {
  const landings = await getOptions(
    ref.landings as [],
    value,
    '/api/promos/buying',
  )
  update(() => {
    ref.landings = landings
  })
}

export async function lookupLandings(
  ref: any,
  value: string,
  update: Function,
  isLandingFilter: boolean,
) {
  const promos = await getOptions(ref.geos as [], value, '/api/promos')
  if (!isLandingFilter) {
    update(() => {
      ref.preLandings = promos.filter(
        (promo: Promo) => promo.semanticType === SemanticType.Prelanding,
      )
    })
    return
  }
  update(() => {
    ref.landings = promos.filter(
      (promo: Promo) => promo.semanticType === SemanticType.Landing,
    )
  })
}
