import { formatValue, isDefined, RowValue, formatDate } from '@/utils'
import { Row } from '@/utils/request'
import { Currency } from '@/enums/Currency'
import { LeadStatus } from '@/enums/LeadStatus'
import { useI18n } from 'vue-i18n'

export function getGeneralColumns(isMediaBuying = false) {
  const { t } = useI18n()

  const arr: any = [
    {
      name: 'groupByField',
      label: t('tables.stat.date'),
      fullLabel: t('tables.stat.date'),
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: t('tables.stat.click'),
      fullLabel: t('tables.stat.click'),
      field: 'clicks',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'uniqueClicks',
      label: t('tables.stat.unick'),
      fullLabel: t('tables.stat.unick'),
      field: 'uniqueClicks',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'registrationCount',
      label: t('tables.stat.reg'),
      fullLabel: t('tables.stat.reg'),
      field: 'registrationCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositCount',
      label: t('tables.stat.dep'),
      fullLabel: t('tables.stat.fullDep'),
      field: 'firstDepositCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositSum',
      label: t('tables.stat.sum'),
      fullLabel: t('tables.stat.sumFull'),
      field: 'firstDepositSum',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'approvedSum',
      label: t('tables.stat.accept'),
      fullLabel: t('tables.stat.acceptFull'),
      field: 'approvedSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
  ]

  if (!isMediaBuying) {
    arr.push({
      name: 'holdSum',
      label: t('tables.stat.hold'),
      fullLabel: t('tables.stat.holdFull'),
      field: 'holdSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    })
  }

  arr.push(
    {
      name: 'rejectedSum',
      label: t('tables.stat.cancel'),
      fullLabel: t('tables.stat.cancelFull'),
      field: 'rejectedSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'depositCount',
      label: t('tables.stat.amount'),
      fullLabel: t('tables.stat.amountFull'),
      field: 'depositCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'depositSum',
      label: t('tables.stat.summa'),
      fullLabel: t('tables.stat.summaFull'),
      field: 'depositSum',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'uniqueDepositCount',
      label: t('tables.stat.unik'),
      fullLabel: t('tables.stat.unikFull'),
      field: 'uniqueDepositCount',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'approvedLeads',
      label: t('tables.stat.conv'),
      fullLabel: t('tables.stat.convFull'),
      field: 'approvedLeads',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
  )

  if (!isMediaBuying) {
    arr.push({
      name: 'holdLeads',
      label: t('tables.stat.convhold'),
      fullLabel: t('tables.stat.convholdFull'),
      field: 'holdLeads',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    })
  }

  arr.push(
    {
      name: 'rejectedLeads',
      label: t('tables.stat.convCancel'),
      fullLabel: t('tables.stat.convCancelFull'),
      field: 'rejectedLeads',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'epc',
      label: t('tables.stat.epc'),
      fullLabel: t('tables.stat.epc'),
      field: 'epc',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'cr',
      label: t('tables.stat.unikDep'),
      fullLabel: t('tables.stat.unikDep'),
      field: 'cr',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'crUniqueReg',
      label: t('tables.stat.unikReg'),
      fullLabel: t('tables.stat.unikReg'),
      field: 'crUniqueReg',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'crFtdReg',
      label: t('tables.stat.regDep'),
      fullLabel: t('tables.stat.regDep'),
      field: 'crFtdReg',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    },
  )

  if (!isMediaBuying) {
    arr.push({
      name: 'approvedPercent',
      label: t('tables.stat.approve'),
      fullLabel: t('tables.stat.approveFull'),
      field: 'approvedPercent',
      align: 'right',
      sortable: true,
      format: (val: RowValue) => formatValue(val),
    })
  }

  arr.push({
    name: 'revShareRevenueSum',
    label: t('tables.stat.dohod'),
    fullLabel: t('tables.stat.dohodFull'),
    field: 'revShareRevenueSum',
    align: 'right',
    format: (val: RowValue) => formatValue(val),
  })

  return arr
}

export function getConversionsColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'datetime',
      label: t('tables.conversion.date'),
      field: 'datetime',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'offerName',
      label: t('tables.conversion.offer'),
      field: 'offerName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'advertiserName',
      label: t('tables.conversion.recl'),
      field: 'advertiserName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'geo',
      label: t('tables.conversion.city'),
      field: 'geo',
      align: 'center',
      sortable: true,
    },
    {
      name: 'status',
      label: t('tables.conversion.status'),
      field: 'status',
      align: 'center',
      sortable: true,
      format: (val: number, row: Row) => {
        if (row.attributes?.advertiserStatus) {
          return LeadStatus[val] + ` ( ${row.attributes?.advertiserStatus} ) `
        }
        return LeadStatus[val]
      },
    },
    {
      name: 'rewardType',
      label: t('tables.conversion.type'),
      field: 'rewardType',
      align: 'center',
      format: (val: RowValue, row: Row) => {
        if (row.status === LeadStatus.Pending) {
          return 'Registration'
        }
        return isDefined(val)
      },
    },
    {
      name: 'amount',
      label: t('tables.conversion.payment'),
      field: 'amount',
      align: 'center',
      format: (val: RowValue, row: any) => {
        return formatValue(val, 3) + ' ' + (Currency[row.currency] ?? '')
      },
    },
    {
      name: 'affiliateEmail',
      label: t('tables.conversion.aff'),
      field: 'affiliateEmail',
      align: 'center',
    },
    {
      name: 'markupType',
      label: t('tables.conversion.trafficType'),
      field: 'markupType',
      align: 'center',
    },
    {
      name: 'actions',
      label: t('tables.conversion.action'),
      field: 'actions',
      align: 'center',
    },
    {
      name: 'redirectEventId',
      label: t('tables.conversion.id'),
      field: 'redirectEventId',
      align: 'center',
    },
  ]
}
