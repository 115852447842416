import { CampaignState } from '@/store/types'
import { CampaignStatus } from '@/enums/CampaignStatus'

function getDefaultData(): CampaignState {
  return {
    campaign: {
      name: null,
      groupId: null,
      queryParams: {},
      bindUser: true,
      splitTest: false,
      comment: null,
      urlRoute: 'sp',
      promoPercents: {},
      identifier: null,
      department: null,
      streams: [],
      affiliateId: null,
      offerId: null,
      offer: null,
      trafficSourceId: null,
      redirectDomainId: null,
      targetPromoIds: [],
      geoId: null,
      metrics: [],
      trafficProperties: [],
      status: CampaignStatus.Active,
      postbacks: [],
      keys: [],
      values: [],
    },
    errors: {},
  }
}

export default getDefaultData
