
import { mapState, mapMutations } from 'vuex'
import { defineComponent } from 'vue'
import NavigationIcons from './NavigationIcons.vue'
import { IconName } from './NavigationIcons.vue'
import navList from './NavigationList'
import { useI18n } from 'vue-i18n'

interface Link {
  path: string
  label: string
  icon: IconName
  isVisible: boolean
  shouldHideOnDesktop?: boolean
  value?: string
}

interface ComponentData {
  links: Link[]
}
export default defineComponent({
  name: 'Navigation',
  props: ['changeMiniState'],
  components: { NavigationIcons },
  computed: {
    ...mapState('authModule', ['isMB', 'user']),
  },

  methods: {
    ...mapMutations('authModule', ['logout']),
    getNavLinks() {
      return navList(Boolean(this.isMB)).filter(el => el.isVisible) as Link[]
    },
    async onNavClick(link: Link) {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'auto'
      }
      if (link.value === 'logOut') {
        await this.logout()
      }
    },
    handleLocaleClick(lang: 'ua' | 'ru' | 'en') {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      document.querySelector('html')?.setAttribute('lang', lang)
    },
  },
  setup() {
    const { locale, availableLocales } = useI18n({ useScope: 'global' })

    return {
      localeState: locale,
      localeOptions: availableLocales,
    }
  },
})
