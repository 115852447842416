import { isDefined, RowValue, formatDate } from '@/utils'
import { useI18n } from 'vue-i18n'

export function getRedirectEventsColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'createdAt',
      label: t('tables.clicks.date'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'id',
      label: t('tables.clicks.click'),
      field: 'id',
      align: 'left',
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'center',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'geoName',
      label: t('tables.clicks.geo'),
      field: 'geoName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: t('tables.clicks.offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: t('tables.clicks.campaighn'),
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'os',
      label: t('tables.clicks.system'),
      field: 'os',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'device',
      label: t('tables.clicks.app'),
      field: 'device',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'browser',
      label: t('tables.clicks.browser'),
      field: 'browser',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'campaignUrl',
      label: t('tables.clicks.link'),
      field: 'campaignUrl',
      align: 'left',
    },
    {
      name: 'targetUrl',
      label: t('tables.clicks.redir'),
      field: 'targetUrl',
      align: 'left',
    },
    {
      name: 'request',
      label: t('tables.clicks.request'),
      field: 'request',
      align: 'left',
      format: (val: RowValue) => JSON.stringify(val),
    },
  ]
}

export function getAffiliatePostbackColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'createdAt',
      label: t('tables.clicks.date'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'url',
      label: t('tables.clicks.linkOne'),
      field: 'url',
      align: 'left',
    },
    {
      name: 'eventType',
      label: t('tables.clicks.action'),
      field: 'eventType',
      align: 'center',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: t('tables.clicks.campaighn'),
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: t('tables.clicks.offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'status',
      label: t('tables.clicks.status'),
      field: 'status',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
  ]
}

export function getAdvertiserPostbackColumns() {
  const { t } = useI18n()

  return [
    {
      name: 'createdAt',
      label: t('tables.clicks.date'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'url',
      label: t('tables.clicks.linkOne'),
      field: 'url',
      align: 'left',
    },
    {
      name: 'eventType',
      label: t('tables.clicks.action'),
      field: 'eventType',
      align: 'center',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: t('tables.clicks.campaighn'),
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: t('tables.clicks.offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
  ]
}
