
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Button from '../../components/sharable/Button.vue'

export default defineComponent({
  name: 'SignUpDialog',
  props: ['open', 'close'],
  components: { Button },
  created: function() {
    this.resetErrors()
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
    ...mapState('authModule', ['signUpError']),
  },
  data: function() {
    return {
      email: null,
      password: null,
      telegram: null,
      loading: false,
    }
  },
  methods: {
    ...mapActions('authModule', ['signUp', 'login']),
    ...mapMutations('authModule', ['resetErrors']),
    async submit() {
      this.loading = true
      try {
        const urlParams = new URLSearchParams(location.search)
        await this.signUp({
          email: this.email,
          password: this.password,
          telegram: this.telegram,
          ref: urlParams.get('ref'),
        })
        await this.login({
          email: this.email,
          password: this.password,
        })
      } finally {
        this.loading = false
      }
    },
    onClose() {
      this.email = null
      this.password = null
      this.telegram = null
      this.loading = false
      this.resetErrors()
      this.$emit('close')
    },
  },
})
