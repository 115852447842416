
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import { getDefaultFilters, getTableProperties } from '@/utils/request'
import { getRedirectEventsColumns } from './columns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { FiltersType } from '@/utils/request'
import { RewardType } from '@/enums/RewardType'
import useQuasar from 'quasar/src/composables/use-quasar.js';
export default defineComponent({
  name: 'RedirectEvents',
  components: { Filters },
  created() {
    if (Object.keys(this.$route.params).length) {
      const paramsFilters = this.$route.params
      for (const [key, value] of Object.entries(paramsFilters)) {
        if (
          RewardType[(value as unknown) as number] &&
          (value === 'rewardType' || key === 'rewardType')
        ) {
          alert(this.$t('pages.logs.redirectAlert'))
          this.$router.push({
            name: 'statistics',
            params: {
              filters: this.$route.params.filters,
              vertical: (this.$route.params.filters as any).vertical,
            },
          })
        }
        if (Number(value)) {
          paramsFilters[key] = (Number(value) as unknown) as string
        }
      }
      this.filters = { ...this.filters, ...paramsFilters }
    } else {
      this.onRequest({ pagination: this.pagination })
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 25),
      filters: getDefaultFilters(),
    }
  },
  setup() {
    const $q = useQuasar()
    return {
      showErrorSnackbar(err: any) {
        const error = new Error(err)
        $q.notify({
          type: 'negative',
          message: `Internal Server Error: ${error.message}`,
          position: 'top-right',
          timeout: 4000,
        })
      },
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      try {
        const options = { pagination: this.pagination, ...props }
        await request(options, this as Table, '/api/logs/clicks')
      } catch (err) {
        this.showErrorSnackbar(err)
      }
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters(true)
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
  },
  computed: {
    createColumns() {
      return getRedirectEventsColumns()
    },
  },
})
