import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_GeneralTable = _resolveComponent("GeneralTable")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "dashboard__page" }, {
    default: _withCtx(() => [
      _createVNode(_component_Filters, {
        onReset: _ctx.resetFilters,
        onExport: _ctx.onExport,
        onChange: _ctx.onChangeFilters,
        onChangeAll: _ctx.changeAll,
        "current-filters": _ctx.complexFilters
      }, null, 8, ["onReset", "onExport", "onChange", "onChangeAll", "current-filters"]),
      _createElementVNode("div", null, [
        _createVNode(_component_GeneralTable, {
          filters: _ctx.filters,
          exportCsv: _ctx.exportCsv,
          onChangePagination: _ctx.changePagination,
          currentPagination: _ctx.pagination
        }, null, 8, ["filters", "exportCsv", "onChangePagination", "currentPagination"])
      ])
    ]),
    _: 1
  }))
}