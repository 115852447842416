
import { defineComponent } from 'vue'
import { FormatMoney } from 'format-money-js'

export default defineComponent({
  name: 'Statistic',
  props: ['conv'],
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      moneyFormatter,
    }
  },
})
