import { useI18n } from 'vue-i18n'
const navList = (isMB: boolean) => {
  const { t } = useI18n()
  return [
    {
      path: '/dashboard',
      label: t('nav.0.label'),
      icon: 'dashboard',
      isVisible: true,
    },
    {
      path: '/offers',
      label: t('nav.1.label'),
      icon: 'offers',
      isVisible: true,
    },
    {
      path: '/campaigns',
      label: t('nav.2.label'),
      icon: 'company',
      isVisible: true,
    },
    {
      path: '/statistics',
      label: t('nav.3.label'),
      icon: 'statistic',
      isVisible: true,
    },
    {
      path: '/conversions',
      label: t('nav.4.label'),
      icon: 'conversion',
      isVisible: true,
    },
    {
      path: '/payments',
      label: t('nav.5.label'),
      icon: 'payment',
      isVisible: !isMB,
    },
    {
      path: '/redirect-domains',
      label: t('nav.6.label'),
      icon: 'domains',
      isVisible: true,
    },
    {
      path: '/profile',
      label: t('nav.7.label'),
      icon: 'profile',
      isVisible: true,
    },
    {
      path: '/clicklog',
      label: t('nav.8.label'),
      icon: 'logs',
      isVisible: true,
    },
    {
      path: '/support',
      label: t('nav.9.label'),
      icon: 'support',
      isVisible: !isMB,
    },
    {
      path: '/',
      label: t('nav.10.label'),
      value: 'logOut',
      icon: 'log-out',
      isVisible: true,
      shouldHideOnDesktop: true,
    },
  ]
}

export default navList
