
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default defineComponent({
  name: 'FAQ',
  computed: mapState('authModule', ['passwordResetError']),
  created: function() {
    this.resetErrors()
  },
  beforeUnmount() {
    this.resetErrors()
  },
  data: function() {
    return {
      email: null,
      loading: false,
      emailSent: false,
    }
  },
  methods: {
    ...mapActions('authModule', ['startPasswordReset']),
    ...mapMutations('authModule', ['resetErrors']),
    async submit() {
      this.loading = true
      try {
        await this.startPasswordReset({
          email: this.email,
        })
        this.emailSent = true
      } finally {
        this.loading = false
      }
    },
  },
})
