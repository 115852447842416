import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-mb-md" }
const _hoisted_2 = { class: "q-mr-sm" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "flex col-6" }
const _hoisted_5 = { class: "flex col-6" }
const _hoisted_6 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_btn, {
      class: "q-mb-lg",
      label: _ctx.$t('pages.campaighn.steps.urlParam.add'),
      onClick: _ctx.addKey,
      color: "secondary",
      icon: "add",
      size: "sm"
    }, null, 8, ["label", "onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("b", _hoisted_2, _toDisplayString(_ctx.$t('pages.campaighn.steps.urlParam.free')) + ": ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.urlParam.example')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('pages.campaighn.steps.urlParam.parameter')), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('pages.campaighn.steps.urlParam.value')), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaign.keys, (key, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_q_input, {
            class: "col-6",
            outlined: "",
            dense: "",
            placeholder: "sub1",
            "model-value": _ctx.campaign.keys[i],
            "onUpdate:modelValue": val => _ctx.updateKeys(val, i)
          }, null, 8, ["model-value", "onUpdate:modelValue"]),
          _createVNode(_component_q_input, {
            outlined: "",
            dense: "",
            class: "col-6",
            placeholder: "{sub1}",
            "model-value": _ctx.campaign.values[i],
            "onUpdate:modelValue": val => _ctx.updateValues(val, i)
          }, null, 8, ["model-value", "onUpdate:modelValue"])
        ])
      ]))
    }), 128))
  ]))
}