import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_BuyingCampaignEdit = _resolveComponent("BuyingCampaignEdit")!
  const _component_AffiliateCampaignEdit = _resolveComponent("AffiliateCampaignEdit")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "dashboard__page" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_btn, {
          "no-caps": "",
          icon: "chevron_left",
          flat: "",
          label: _ctx.$t('btn.back'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/campaigns'))),
          style: {"margin-left":"-18px"}
        }, null, 8, ["label"])
      ]),
      (_ctx.isMB)
        ? (_openBlock(), _createBlock(_component_BuyingCampaignEdit, {
            key: 0,
            isNew: _ctx.isNew,
            id: _ctx.id
          }, null, 8, ["isNew", "id"]))
        : (_openBlock(), _createBlock(_component_AffiliateCampaignEdit, {
            key: 1,
            isNew: _ctx.isNew,
            id: _ctx.id
          }, null, 8, ["isNew", "id"]))
    ]),
    _: 1
  }))
}