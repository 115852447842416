import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = {
  key: 0,
  class: "row justify-start items-center"
}
const _hoisted_3 = { class: "q-mb-md q-mt-md q-mr-md" }
const _hoisted_4 = { class: "col-md-5 col-9 q-pb-none q-mr-sm" }
const _hoisted_5 = {
  key: 0,
  class: "col-md-4 col-2 q-pb-none"
}
const _hoisted_6 = { class: "flex col-md-1 col-1 self-center justify-center" }
const _hoisted_7 = {
  key: 1,
  class: "flex justify-center col-md-1 col-2 items-center self-center max-height-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.landings.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("b", _hoisted_3, _toDisplayString(_ctx.$t('pages.campaighn.steps.landings.title')), 1),
          _createVNode(_component_q_btn, {
            color: "positive",
            unelevated: "",
            "no-caps": "",
            size: "sm",
            icon: "equalizer",
            label: "Уравнять",
            onClick: _ctx.equalize
          }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.landings, (promo, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row q-mb-sm items-center",
        key: index
      }, [
        _createVNode(_component_q_checkbox, {
          class: "col-2 col-md-1 q-mt-sm",
          modelValue: promo.isActive,
          "onUpdate:modelValue": ($event: any) => ((promo.isActive) = $event),
          onClick: ($event: any) => (_ctx.updatePromos(promo))
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.landings.name')), 1),
          _createVNode(_component_q_input, {
            dense: "",
            readonly: "",
            outlined: "",
            modelValue: promo.name,
            "onUpdate:modelValue": ($event: any) => ((promo.name) = $event),
            "error-message": _ctx.errors?.targetPromoIds || _ctx.errors?.message,
            error: !!_ctx.errors?.targetPromoIds || !!_ctx.errors?.message
          }, null, 8, ["modelValue", "onUpdate:modelValue", "error-message", "error"])
        ]),
        (_ctx.campaign.splitTest)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("b", null, _toDisplayString(_ctx.$t('pages.campaighn.steps.landings.weight')), 1),
              _createVNode(_component_q_input, {
                dense: "",
                outlined: "",
                max: "100",
                min: "0",
                type: "number",
                label: _ctx.$t('pages.campaighn.steps.landings.placeholder'),
                "model-value": _ctx.campaign.promoPercents?.[promo.id],
                "onUpdate:modelValue": percent => _ctx.updatePromos({ ...promo, percent }),
                "error-message": _ctx.errors?.targetPromoIds || _ctx.errors?.message,
                error: !!_ctx.errors?.targetPromoIds || !!_ctx.errors?.message
              }, null, 8, ["label", "model-value", "onUpdate:modelValue", "error-message", "error"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_q_btn, {
            round: "",
            color: "dark",
            size: "sm",
            icon: "find_in_page",
            class: "q-mt-sm",
            onClick: ($event: any) => (_ctx.landingRedirect(promo.url))
          }, null, 8, ["onClick"])
        ]),
        (promo.archive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_q_btn, {
                onClick: ($event: any) => (_ctx.download(promo.id)),
                round: "",
                flat: "",
                color: "secondary",
                icon: "download"
              }, null, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}