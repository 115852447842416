
import { defineComponent } from 'vue'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import getColumns from './getColumns'
import axios from 'axios'
import { Currency } from '@/enums/Currency'
export default defineComponent({
  name: 'Referrals',
  async created() {
    await this.onRequest({ pagination: this.pagination })
    const key: string = (await axios.get('/api/referrals/link')).data
    this.referralLink = `https://${location.host}/sign-up?ref=${key}`
  },
  data() {
    return {
      ...getTableProperties('id'),
      referralLink: '',
      currencies: Currency,
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/referrals/')
    },
  },
  computed: {
    createColumns() {
      return getColumns()
    },
  },
})
