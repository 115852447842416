
import { defineComponent } from 'vue'
import Button from '../../components/sharable/Button.vue'

export default defineComponent({
  name: 'Expertise',
  props: ['onClickReg'],
  components: {
    Button,
  },
})
