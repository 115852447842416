import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_TitleDivider = _resolveComponent("TitleDivider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hHh LpR lFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_page_container, { class: "page-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_Header),
          (_ctx.$q.screen.width >= 1280)
            ? (_openBlock(), _createBlock(_component_Navigation, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_TitleDivider),
          _createVNode(_component_router_view),
          _createVNode(_component_Footer, { style: {"background-color":"#151a27"} })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}