
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import ConversionsTable from './ConversionsTable.vue'
import { FiltersType, getDefaultFilters } from '@/utils/request'

export default defineComponent({
  name: 'Conversions',
  components: { Filters, ConversionsTable },
  data: function() {
    return {
      exportCsv: false,
      statsTab: 'general',
      filters: { ...getDefaultFilters() },
    }
  },
  watch: {
    statsTab() {
      this.resetFilters()
    },
  },
  methods: {
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = { ...getDefaultFilters(true) }
    },
    onExport() {
      this.exportCsv = !this.exportCsv
    },
  },
})
