
import { defineComponent } from 'vue'
import Payouts from './tabs/Payouts.vue'
import Transactions from './tabs/Transactions.vue'
import RevShare from './tabs/RevShare.vue'

export default defineComponent({
  name: 'Payments',
  components: { Payouts, Transactions, RevShare },
  data: function() {
    return {
      paymentsTab: 'payouts',
    }
  },
})
