
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomBtn',
  props: {
    text: {
      type: String,
      default: '',
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    classNames: {
      type: String,
      default: '',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    style: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value: string) => ['button', 'submit'].includes(value),
    },
  },
  methods: {
    handleClick(event: Event) {
      if (this.type === 'submit') {
        const form = this.$el.closest('form')
        if (form) {
          form.dispatchEvent(new Event('submit', { cancelable: true }))
        }
      } else {
        this.$emit('click', event)
      }
    },
  },
})
