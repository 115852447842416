import { enumToArray } from '@/utils'
import { MarkupType } from '@/enums/MarkupType'
import { TrafficPropertyType } from '@/enums/TrafficPropertyType'
import { RewardType } from '@/enums/RewardType'
import { PostbackEventType } from '@/enums/PostbackEventType'
import { Vertical } from '@/enums/Vertical'
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import axios from 'axios'
import { SemanticType } from '@/enums/SemanticType'
import { Promo } from '@/store/types'
import { AffiliateStatus } from '@/enums/AffiliateStatus'

export function getFiltersData() {
  return {
    landings: [],
    preLandings: [],
    offers: [],
    groups: [],
    campaigns: [],
    geos: [],
    offerCategories: [],
    search: null,
    trafficProperties: [],
    sids1: [],
    sids2: [],
    sids3: [],
    sids4: [],
    sids5: [],
    sids6: [],
    sids7: [],
    sids8: [],
    sids9: [],
    sids10: [],
    sids11: [],
    sids12: [],
    sids13: [],
    sids14: [],
    sids15: [],
    affiliateStatus: enumToArray(AffiliateStatus),
    markupTypes: enumToArray(MarkupType),
    trafficPropertyTypes: enumToArray(TrafficPropertyType),
    rewardTypes: enumToArray(RewardType),
    postbackEventTypes: enumToArray(PostbackEventType),
    verticals: enumToArray(Vertical),
    groupByOptions,
  }
}

export async function initFilterOptions(ref: any) {
  ref.campaigns = (await axios.get('/api/campaigns')).data
  ref.groups = (await axios.get('/api/groups/campaign')).data
  ref.offers = (await axios.get('/api/offers/list')).data
  ref.trafficProperties = (await axios.get('/api/traffic-properties')).data
  ref.geos = (await axios.get('/api/geos')).data
  ref.offerCategories = (await axios.get('/api/offer-categories')).data
  const promos = (await axios.get('/api/promos')).data
  ref.landings = promos.filter(
    (p: Promo) => p.semanticType === SemanticType.Landing,
  )
  ref.preLandings = promos.filter(
    (p: Promo) => p.semanticType === SemanticType.Prelanding,
  )
}
