import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    dark: "",
    dense: "",
    rows: _ctx.rows,
    columns: _ctx.createColumns,
    "row-key": "id",
    separator: "cell",
    pagination: _ctx.pagination,
    "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
    loading: _ctx.loading,
    onRequest: _ctx.onRequest,
    "binary-state-sort": "",
    class: "table-with-min-height-row"
  }, {
    "body-cell-transactionAmount": _withCtx((props) => [
      _createVNode(_component_q_td, {
        class: _normalizeClass(
          props.row.reason === _ctx.transactionReasons.LeadApprove
            ? 'text-positive'
            : props.row.reason === _ctx.transactionReasons.LeadReject
            ? 'text-negative'
            : ''
        ),
        props: props
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.reason === _ctx.transactionReasons.LeadHold
            ? '—'
            : `${_ctx.getFormattedValue(props.row.transactionAmount)} ${
                _ctx.currencies[props.row.transactionCurrency]
              }`), 1)
        ]),
        _: 2
      }, 1032, ["class", "props"])
    ]),
    _: 1
  }, 8, ["rows", "columns", "pagination", "loading", "onRequest"]))
}