import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    dark: "",
    range: "",
    required: "",
    presetRanges: _ctx.createCustomShortCuts,
    placeholder: 
      _ctx.isDepositDate ? _ctx.$t('calendar.dateDepoz') : _ctx.$t('calendar.date')
    ,
    modelValue: _ctx.dateValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateValue) = $event)),
    format: "yyyy/MM/dd",
    enableTimePicker: false,
    clearable: false,
    selectText: _ctx.$t('calendar.choice'),
    cancelText: _ctx.$t('calendar.cancel'),
    maxDate: new Date(),
    minDate: "2022/04/01",
    class: "dp__theme_dark"
  }, null, 8, ["presetRanges", "placeholder", "modelValue", "selectText", "cancelText", "maxDate"]))
}