
import { defineComponent } from 'vue'
import Button from '@/components/sharable/Button.vue'

export default defineComponent({
  name: 'MediaSourceSection',
  props: ['onRequest'],
  components: {
    Button,
  },
})
